<template>
  <div>
    <div>
      <button v-on:click="deleteUser()" v-if="!isLoading" class="mt-4 btn">
        Delete Account
      </button>
      <span v-if="isLoading" class="mt-4 btn">deleting account...</span>
      <div v-if="error">
        An error occurred, please try again or contact support.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserDelete',
  computed: {
    ...mapGetters({
      isLoading: 'user/delete/isLoading',
      error: 'user/delete/error',
    }),
  },
  methods: {
    deleteUser: function() {
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        loader: false, // set to true if you want the dialog to show a loader after click on "proceed"
        reverse: false, // switch the button positions (left to right, and vise versa)
        okText: 'Yes',
        cancelText: 'No',
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        type: 'hard', // coming soon: 'soft', 'hard'
        verification: 'very much so', // for hard confirm, user will be prompted to type this to enable the proceed button
        verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
        clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
        backdropClose: false, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
        customClass: '', // Custom class to be injected into the parent node for the current dialog instance
      };

      let that = this;
      this.$dialog
        .confirm('Are you sure you would like to remove your account?', options)
        .then(function() {
          that.$store.dispatch('user/delete/deleteUser');
        })
        .then(function() {});
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';
</style>
