<template>
  <div
    class="attendee-record"
    @mouseover="mouseOver()"
    @mouseout="mouseOut()"
    v-on:click="openKillercodaInfoPage()"
  >
    <div class="row">
      <div class="col-12">
        <h2 class="course-name">CKS|CKA|CKAD|LFCS Training Scenarios</h2>
        <br />
      </div>
    </div>
    <br />
    <div class="pull-left">
      <small
        >Access training scenarios on Killercoda even before starting the
        simulator</small
      >
    </div>
    <div class="pull-right light-text">
      {{ activateBeforeText() }}
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import router from '@/router/router';

export default {
  name: 'AttendeeListKillercodaItem',
  data: function() {
    return {
      hover: false,
    };
  },
  methods: {
    mouseOver: function() {
      this.hover = true;
    },
    mouseOut: function() {
      this.hover = false;
    },
    activateBeforeText: function() {
      return '';
    },
    infoText: function() {
      return 'info text...';
    },
    openKillercodaInfoPage: function() {
      router.push({ name: 'KillercodaAccess' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
.attendee-record {
  .course-name {
    margin: 0;
  }
  min-height: 100px;
  margin: 10px 0 0 0;
  cursor: pointer;
  border: 1px solid var(--secondary-color);
  background-color: #f5f4ff;
  padding: 10px;
  .light-text {
    color: #9d9d9d;
  }
}
</style>
