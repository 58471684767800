<template>
  <span></span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Poll',
  data: function() {
    return {
      intervalRefreshAttendee: null,
    };
  },
  mounted: function() {
    setTimeout(() => {
      this.refreshAttendee();
    }, 1000);
    this.intervalRefreshAttendee = setInterval(
      function() {
        this.refreshAttendee();
        this.getFeatures();
      }.bind(this),
      10000
    );
  },
  props: {
    attendee: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      attendeeGetIsLoading: 'attendee/get/isLoading',
    }),
  },
  watch: {
    attendeeGetIsLoading(newValue) {
      if (!newValue) {
        this.attendeeDataDidLoadAtLeastOnce = true;
      }
    },
  },
  methods: {
    ...mapActions({
      getFeatures: 'feature/list/getFeatures',
    }),
    refreshAttendee: function() {
      this.$store.dispatch('attendee/get/getAttendee', [
        this.attendee.uuid,
        true,
      ]);
    },
  },
  beforeDestroy: function() {
    clearInterval(this.intervalRefreshAttendee);
  },
};
</script>

<style lang="scss" scoped></style>
