import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

const getAttendees = ({ commit, state, rootState }, markUsed = false) => {
  commit(types.TOGGLE_LOADING);

  let options = {};
  if (markUsed) {
    Object.assign(options, { headers: new Headers() });
    options.headers.set('killer-shell-mark-attendee-clusters-used', 'true');
  }

  fetch('attendees', options)
    .then(response => response.json())
    .then(data => {
      commit(types.SET_DATA, data['hydra:member']);
      commit(types.SET_ERROR, null);
      commit(types.SET_ERROR_COUNTER, 0);
      commit(types.TOGGLE_LOADING);
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
      if (rootState.status.maintenance.active === false) {
        commit(types.SET_ERROR_COUNTER, state.errorCounter + 1);
      }
    });
};

export { getAttendees };
