import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

const get = ({ commit }, order) => {
  commit(types.SET_ERROR, '');
  commit(types.TOGGLE_LOADING);
  let options = {
    method: 'POST',
    body: JSON.stringify({
      order: order,
    }),
  };
  fetch('order/token-generate', options)
    .then(response => {
      return response.json();
    })
    .then(data => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_DATA, data);
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

const reset = ({ commit }) => {
  commit(types.RESET);
};

export { get, reset };
