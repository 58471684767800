<template>
  <div></div>
</template>

<script>
export default {
  props: {
    attendee: {
      required: true,
    },
  },
  created: function() {
    this.clipboardListenStart();
  },
  methods: {
    clipboardListenStart: function() {
      const source = document.querySelector('body');
      source.addEventListener('copy', this.clipboardListenHandler);
    },
    clipboardListenHandler: function(event) {
      if (
        this.attendee &&
        this.attendee.terminal &&
        this.attendee.terminal.clusterTerminalProxyClipboardAddress
      ) {
        const selection = document.getSelection();
        let str = selection.toString();
        if (str === '' && event && event.target && event.target.value) {
          str = event.target.value;
        }
        if (str !== '') {
          this.$store.dispatch('attendee/clipboard/copy', [
            this.attendee.terminal.clusterTerminalProxyClipboardAddress,
            str,
          ]);
        }
      }
    },
    clipboardListenStop: function() {
      const source = document.querySelector('body');
      source.removeEventListener('copy', this.clipboardListenHandler);
    },
  },
  beforeDestroy: function() {
    this.clipboardListenStop();
  },
};
</script>

<style lang="scss" scoped></style>
