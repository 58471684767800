import get from './get/index';
import create from './create/index';
import selection from './selection/index';
import addDiscountCode from './addDiscountCode/index';
import getToken from './getToken/index';

export default {
  namespaced: true,
  modules: {
    get,
    create,
    selection,
    addDiscountCode,
    getToken,
  },
};
