<template>
  <div>
    <div>
      <b-row class="row">
        <b-col cols="9"
          ><b-form-select
            type="text"
            v-model="ticket.category"
            :options="categories"
            @change="categorySwitched()"
        /></b-col>
      </b-row>

      <div class="mt-5"></div>

      <span v-if="ticket.category === 'general'">
        Most general questions are answered in our
        <router-link :to="{ name: 'Faq' }" target="_blank">
          <strong>FAQ</strong> </router-link
        >.
        <br />
        <div class="mt-5"></div>
        If you still need help please
        <span class="create-ticket-link" @click="userChoiceShowFormToggle()"
          >Create a Ticket</span
        >
      </span>

      <span v-if="ticket.category === 'billing'">
        Most billing questions are answered in our
        <router-link :to="{ name: 'Faq' }" target="_blank">
          <strong>FAQ</strong> </router-link
        >.

        <div class="mt-2"></div>

        <br />
        <h2>Will I get other questions if I purchase a second time?</h2>
        <p>
          No, every simulator session of a type (CKS|CKA|CKAD|LFCS|LFCT) has the
          very same questions.
        </p>

        <hr />
        <h2>Cheaper rebuy rate?</h2>
        <p>
          If you decide you need more than two sessions, then a cheaper rebuy
          rate of 9.99$ per single session will be applied if you already
          purchased (or got Linux Foundation access for) the same simulator
          during the last 12 months. But we suggest to come prepared, then two
          sessions are enough.
        </p>

        <hr />
        <h2>How much does this cost?</h2>
        <p>29.99$ for two CKS sessions.</p>
        <p>29.99$ for two CKA sessions.</p>
        <p>29.99$ for two CKAD sessions.</p>
        <p>29.99$ for two LFCS sessions.</p>
        <p>29.99$ for two LFCT sessions.</p>

        <hr />
        <h2>How can I pay?</h2>
        <p>You can pay via Credit Card.</p>

        <hr />
        <h2>Can I pay in another currency than USD?</h2>
        <p>
          We only offer payments in USD. But the bank of your credit card will
          perform automatic currency conversion. Please contact your bank for
          info about additional costs.
        </p>

        <hr />
        <h2 class="font-weight-bold h5">
          Do you offer bulk or voucher purchases?
        </h2>
        <p>
          Yes, we offer companies to purchase vouchers in bulk. Any user using a
          voucher will gain simulator access without payment necessary. Please
          contact us on
          <router-link :to="{ name: 'Support' }">support</router-link>.<br />
        </p>

        <hr />
        <h2>Do you offer refunds or a testing period?</h2>
        <p>
          We don't offer this. If you had issues with your simulator session
          just contact support and we figure out a way to solve it.
        </p>
        <hr />

        <div class="mt-5"></div>
        If you still need help please
        <span class="create-ticket-link" @click="userChoiceShowFormToggle()"
          >Create a Ticket</span
        >
      </span>

      <span v-if="ticket.category === 'content'">
        <div class="mt-2"></div>
        <br />
        <hr />
        <h2>Why do both simulator sessions have identical questions?</h2>
        <p>
          In the beginning our idea was to just provide one session for you. But
          because our simulators are harder than the real exam, more questions
          for example, you might need two tries.
        </p>

        <hr />
        <h2>Will I get other questions if I purchase a second time?</h2>
        <p>
          No, every simulator session of a type (CKS|CKA|CKAD|LFCS|LFCT) has the
          very same questions.
        </p>

        <hr />
        <h2>How many questions are there?</h2>
        <p>
          CKS: 22<br />
          CKA: 25<br />
          CKAD: 22<br />
          LFCS: 20<br />
          LFCT: 20<br />
        </p>
        <hr />

        <div class="mt-5"></div>

        If you have questions to simulator scenarios or your solution please ask
        these in our<br />
        <a v-if="showSlackLink" href="https://killer.sh/slack" target="_blank"
          >Slack Community</a
        >
        <span v-else>Slack Community</span>.
      </span>

      <span v-if="ticket.category === 'score'">
        Most score questions are answered in our
        <router-link :to="{ name: 'Faq' }" target="_blank">
          <strong>FAQ</strong> </router-link
        >.
        <div class="mt-2"></div>

        <br />
        <hr />
        <h2>Why does the score not work for my solution?</h2>
        <p>
          Please apply our provided solution step-by-step. If it still doesn't
          work please restart your environment ("Exam Controls -> Restart
          Session") and try again. Compare our solution closely to yours, you
          can also post your solution in our
          <a v-if="showSlackLink" href="https://killer.sh/slack" target="_blank"
            >Slack Community</a
          >
          <span v-else>Slack Community</span>.
        </p>
        <hr />
        <h2>How is the difficulty compared to the real certification?</h2>
        <p>
          This simulator is more difficult than the real certification. We think
          this gives you a greater learning effect and also confidence to score
          in the real exam. Most of the simulator questions require good amount
          of work and can be considered "hard". In the real exam you will also
          face these "hard" questions, just less often. There are probably also
          fewer questions in the real exam.
        </p>

        <hr />
        <h2>Will you calculate a score?</h2>
        <p>
          Yes. But we suggest to also compare your solutions to ours. We think
          this has a great learning effect because we also provide additional
          tips and information which you might otherwise skip if you just look
          at the score.<br />
        </p>

        <hr />
        <h2>Why don't you show percentages on the score?</h2>
        <p>
          Because we don't want to give the impression that if you get a certain
          percentage in our simulator you would also get that percentage in the
          real exam.
        </p>

        <hr />
        <h2>Will there be partial scoring in the real exam?</h2>
        <p>
          Yes. The Linux Foundation will calculate a score based on successful
          subtasks. Also considering if some subtasks are harder than others.
        </p>
        <hr />

        <div class="mt-5"></div>
        If you still need help please
        <span class="create-ticket-link" @click="userChoiceShowFormToggle()"
          >Create a Ticket</span
        >
      </span>

      <span v-if="ticket.category === 'linux_foundation'">
        Most questions are answered in our
        <router-link :to="{ name: 'Faq' }" target="_blank">
          <strong>FAQ</strong> </router-link
        >.
        <div class="mt-2"></div>

        <br />

        <h2>
          Do I get free simulator sessions for my Linux Foundation exam?
        </h2>
        <p>
          Yes. If you purchased the CKS|CKA|CKAD|LFCS|LFCT exam, two simulator
          sessions will be already included. You'll find these in your Linux
          Foundation Exam Preparation Checklist. For more information you can
          check
          <a
            href="https://training.linuxfoundation.org/announcements/linux-foundation-kubernetes-certifications-now-include-exam-simulator"
            target="_blank"
            >The Simulator Announcement</a
          >
          ,
          <a
            href="https://docs.linuxfoundation.org/tc-docs/certification/faq-cka-ckad-cks#is-there-training-to-prepare-for-the-certification-exam"
            target="_blank"
            >The CKA CKAD CKS Faq</a
          >
          and
          <a
            href="https://docs.linuxfoundation.org/tc-docs/certification/frequently-asked-questions-lfct#where-can-i-find-practice-questions-for-lfct"
            target="_blank"
            >The LFCT Faq</a
          >. For questions you can also contact the
          <a href="https://trainingsupport.linuxfoundation.org" target="_blank">
            Linux Foundation Support</a
          >.
        </p>

        <hr />
        <h2>
          How can I access my Linux Foundation simulator sessions?
        </h2>
        <p>
          You'll need to click on the link in your Linux Foundation Exam
          Preparation Checklist as shown below. If you don't have the link
          please contact the
          <a href="https://trainingsupport.linuxfoundation.org" target="_blank">
            Linux Foundation Support </a
          >.
        </p>

        <b-img
          src="@/assets/lf_exam_prep_list_sisimulators.png"
          width="400px"
          fluid
          style="border: 1px solid lightgrey"
        ></b-img>

        <hr />
        <h2>
          Are the simulator sessions accessible through the Linux Foundation the
          very same as if purchased directly on killer.sh?
        </h2>
        <p>
          Yes.
        </p>
        <hr />

        <div class="mt-5"></div>
        If you still need help please
        <span class="create-ticket-link" @click="userChoiceShowFormToggle()"
          >Create a Ticket</span
        >
      </span>

      <span v-if="ticket.category === 'session_error'">
        Most session runtime questions are answered in our
        <router-link :to="{ name: 'FaqSessionRuntime' }" target="_blank">
          <strong>FAQ Session Runtime</strong> </router-link
        >.

        <div class="mt-2"></div>

        <br />
        <h2>How long can I access my simulator session?</h2>
        <p>
          Once activated, a simulator session is accessible during the next
          following 36 hours.<br />
          The 36 hours are time based and not usage based.<br />
          The 36 hours cannot be spread over multiple days or weeks and have to
          be used at once.<br />
          After the 36 hours have passed, you’ll still have access to the
          questions and our solutions, but not the interactive environment.<br />
        </p>

        <hr />
        <h2>My session should still be active but I can't access it?</h2>
        <p>
          Make sure you select the correct one from your dashboard. Sessions are
          accessible for the next 36 hours after activation, which is 1.5 days.
          The 36 hours are time based and not usage based.
        </p>

        <hr />
        <h2>Can I close or reload the browser without losing my solutions?</h2>
        <p>
          Yes. For the 36 hours your session will be kept running in the
          background. You can close the window or even use a different browser.
        </p>

        <hr />
        <h2>SSH connection issue, do I need a password?</h2>
        <p>
          No password is required. Ensure to run "exit" to get back to your main
          terminal because nested ssh is not possible. You could also open a new
          Terminal Emulator application, if your exam uses the Remote Desktop
          (CKS|CKA|CKAD|LFCS), which should start at your default instance and
          default user.
        </p>

        <hr />
        <h2>Slow or interrupted connection?</h2>
        <p>
          The most common reason for connection issues is the usage of a
          VPN/Proxy, this can also be your company's remote-work software.
        </p>

        <p>
          If you experience any kind of connection issues please make sure all
          points here are complied with:
        </p>
        <ul>
          <li>Don't use a VPN/Proxy</li>
          <li>Don't activate your company remote-work software</li>
          <li>
            Local development environments like Docker can interrupt ongoing
            connections, close these
          </li>
          <li>
            Switch Browser: use only latest Chrome or latest Firefox. Switch to
            the other if one is already used
          </li>
          <li>
            Disable ALL browser extensions/plugins and run in private mode
          </li>
          <li>Use a stable internet connection, with low usage by others</li>
          <li>
            Use the Remote Terminal instead of the Remote Desktop, select from
            top menu once simulator is fully loaded
          </li>
        </ul>

        <p>
          Other than that we can only suggest to restart your session using top
          nav "Restart Session". The new simulator environment will always be
          created in your closest location available.
        </p>

        <hr />
        <h2>I have keyboard issues, some keys don't work in the terminal</h2>
        <p>
          For OSX it could be your keyboard layout. Please go to
          Preferences->Keyboard->Input Sources. There search for English and
          then select "ABC" (or maybe called "Default"). Switching the layout
          should solve the input issue.
        </p>
        <p>
          For Windows try to change your keyboard layout to a different English
          one.
        </p>
        <p>
          For Ubuntu+Chrome for example users report keyboard issues, switch to
          Firefox or Chromium. Also open killer.sh in private browser mode.
        </p>

        <hr />
        <h2>
          My environment is misconfigured and not reachable/working properly
        </h2>
        <p>
          You can restart your environment via "Exam Controls -> Restart
          Session".
        </p>

        <hr />
        <h2>Where can I restart my environment?</h2>
        <p>
          While in the 36 hours access window you can select to your active
          terminal. You find the action under "Exam Controls -> Restart
          Session".
        </p>
        <hr />

        <div class="mt-5"></div>
        If you still need help please
        <span class="create-ticket-link" @click="userChoiceShowFormToggle()"
          >Create a Ticket</span
        >
      </span>

      <span v-if="ticket.category === 'account_error'">
        Most account questions are answered in our
        <router-link :to="{ name: 'Faq' }" target="_blank">
          <strong>FAQ</strong> </router-link
        >.

        <div class="mt-2"></div>
        <br />
        <hr />
        <h2>How can I log in?</h2>
        <p>
          Currently you can login using the external services
        </p>
        <ul>
          <li>Linux Foundation</li>
          <li>Google</li>
          <li>Github</li>
          <li>Gitlab</li>
          <li>Microsoft</li>
          <li>Linkedin</li>
        </ul>
        <p>
          You can also login using Email where we send a login link to you
          without the need of defining a password.
        </p>

        <hr />
        <h2>Can I close or reload the browser without losing my solutions?</h2>
        <p>
          Yes. For the 36 hours your session will be kept running in the
          background. You can close the window or even use a different browser.
        </p>

        <hr />
        <h2>Where can I see my email address used to log in?</h2>
        <p>
          You can see it in your
          <router-link :to="{ name: 'UserProfile' }">
            Account Section
          </router-link>
        </p>
        <hr />

        <div class="mt-5"></div>
        If you still need help please
        <span class="create-ticket-link" @click="userChoiceShowFormToggle()"
          >Create a Ticket</span
        >
      </span>

      <div v-if="userChoiceShowForm" class="mt-5">
        <div class="alert alert-info">
          If you would like to attach files or pictures, please create the
          request at first without. Then attach those to the confirmation email
          you'll receive.
        </div>

        <div v-if="this.validationError" class="alert alert-danger">
          Please fill out all fields.
        </div>

        <b-row class="row">
          <b-col cols="3">Name: </b-col>
          <b-col><b-form-input type="text" v-model="ticket.name"/></b-col>
        </b-row>

        <b-row class="row">
          <b-col cols="3">Email: </b-col>
          <b-col><b-form-input type="text" v-model="ticket.email"/></b-col>
        </b-row>

        <b-row class="row">
          <b-col cols="3">Subject: </b-col>
          <b-col><b-form-input type="text" v-model="ticket.subject"/></b-col>
        </b-row>

        <b-row class="row">
          <b-col cols="3">Text: </b-col>
          <b-col><b-form-textarea v-model="ticket.text" rows="6"/></b-col>
        </b-row>

        <div>
          <spinner v-if="isLoading"></spinner>
          <div v-else-if="this.error">
            <div class="alert alert-danger">
              An error occurred. Please fill out all fields or try again later.
            </div>
          </div>
          <div v-else-if="this.sent">
            <div class="alert alert-success">
              We received your request and will usually get back to you within
              two days.
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-12">
                <button
                  v-on:click="create()"
                  class="mt-4 btn card-cta-primary pull-right"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Spinner from '@/components/util/Spinner';

export default {
  name: 'TicketCreate',
  components: { Spinner },
  data: function() {
    return {
      user: null,
      ticket: {
        name: '',
        email: '',
        subject: '',
        text: '',
        category: null,
      },
      categories: [
        { value: null, text: 'Please select' },
        { value: 'linux_foundation', text: 'Linux Foundation access' },
        { value: 'general', text: 'General question' },
        { value: 'billing', text: 'Billing question' },
        { value: 'content', text: 'Simulator content question' },
        { value: 'score', text: 'Scoring question' },
        { value: 'session_error', text: 'Session error or access issue' },
        { value: 'account_error', text: 'Account error or access issue' },
      ],
      sent: false,
      validationError: false,
      userChoiceShowForm: false,
    };
  },
  props: {
    userPassed: {
      required: false,
      type: Object,
    },
    showSlackLink: {
      required: true,
      type: Boolean,
    },
  },
  computed: mapGetters({
    isLoading: 'support/create/isLoading',
    error: 'support/create/error',
  }),
  created() {
    this.clearError();
    if (this.userPassed) {
      this.ticket.name = this.userPassed.firstName;
      this.ticket.email = this.userPassed.email;
    }
  },
  methods: {
    ...mapActions({
      createTicket: 'support/create/create',
      clearError: 'support/create/clearError',
    }),
    categorySwitched: function() {
      this.userChoiceShowForm = false;
    },
    userChoiceShowFormToggle: function() {
      this.userChoiceShowForm = !this.userChoiceShowForm;
    },
    create: function() {
      if (
        this.ticket.name &&
        this.ticket.email &&
        this.ticket.subject &&
        this.ticket.text &&
        this.ticket.category
      ) {
        this.createTicket(this.ticket);
        this.sent = true;
        this.validationError = false;
      } else {
        this.validationError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';
.row {
  margin-bottom: 14px;
}
.error {
  border: 1px solid #da6d78;
}
.create-ticket-link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
