import Vuex from 'vuex';
import authentication from './store/modules/authentication';
import user from './store/modules/user/index';
import attendee from './store/modules/attendee';
import course from './store/modules/course';
import app from './store/modules/app';
import product from './store/modules/product';
import order from './store/modules/order';
import feature from './store/modules/feature';
import consent from './store/modules/consent';
import error from './store/modules/error';
import accessRequest from './store/modules/accessRequest';
import status from './store/modules/status';
import support from './store/modules/support';
import Vue from 'vue';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    authentication,
    user,
    attendee,
    course,
    app,
    product,
    order,
    feature,
    consent,
    error,
    accessRequest,
    status,
    support,
  },
});

export default store;
