import * as types from './mutation_types';
import VueCookies from 'vue-cookies';
import * as definitions from './definitions';

export default {
  [types.RESET](state) {
    Object.assign(state, {
      isAuthenticated: false,
      jwtToken: null,
      jwtRefreshToken: null,
    });
  },

  [types.SET_IS_AUTHENTICATED](state, isAuthenticated) {
    Object.assign(state, { isAuthenticated });
  },

  [types.SET_IS_AUTHENTICATING](state, isAuthenticating) {
    Object.assign(state, { isAuthenticating });
  },

  [types.SET_JWT_TOKEN](state, jwtToken) {
    Object.assign(state, { jwtToken });
  },

  [types.SET_JWT_REFRESH_TOKEN](state, jwtRefreshToken) {
    Object.assign(state, { jwtRefreshToken });
    if (jwtRefreshToken === null) {
      VueCookies.remove(definitions.JWT_REFRESH_TOKEN_COOKIE_NAME);
    } else {
      VueCookies.set(
        definitions.JWT_REFRESH_TOKEN_COOKIE_NAME,
        jwtRefreshToken,
        '10y',
        null,
        null,
        null,
        'Strict'
      );
    }
  },
};
