<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WebsocketLatencyTest',
  data: function() {
    return {
      url: null,
      socket: null,
      latency: {
        max: null,
        min: null,
        avg: 0,
      },
      timespanCheck: 10000,
      timespanSend: 60000,
      mainConnection: null,
      urlRegion: null,
      ignoreSendNext: true,
    };
  },
  props: {
    urlPassed: {
      required: true,
    },
    attendeeTerminalAddress: {
      required: true,
    },
    attendeePassed: {
      required: true,
    },
  },
  created() {
    this.mainConnection = this.urlPassed === this.attendeeTerminalAddress;
    this.url = this.convertUrl(this.urlPassed);
    this.fetchUrlRegion();
    // console.log(this.url);
    this.socket = new WebSocket(this.url, ['tty']);
    let that = this;
    this.socket.onopen = function() {
      let textEncoder = new TextEncoder();
      that.socket.send(textEncoder.encode('{"AuthToken":""}'));
      setTimeout(() => {
        that.websocketLatencyCheck();
      }, this.timespanCheck);
      setTimeout(() => {
        that.websocketLatencySend();
      }, this.timespanSend);
    };
  },
  methods: {
    ...mapActions({
      sendLatency: 'attendee/latency/send',
    }),
    removeLastDirectoryPartOf: function(url) {
      let arr = url.split('/');
      let arrNew = [arr[0], arr[2]];
      let arrJoin = arrNew.join('/');
      arrJoin = arrJoin.replace('https:/', 'https://');
      arrJoin = arrJoin.replace('http:/', 'http://');
      return arrJoin;
    },
    fetchUrlRegion: function() {
      if (
        this.urlPassed.includes('terminal.') ||
        this.urlPassed.includes('terminal-')
      ) {
        let urlBase = this.removeLastDirectoryPartOf(this.urlPassed);
        fetch(urlBase + '/internal/region').then(async response => {
          const data = await response.text();
          if (response.ok) {
            this.urlRegion = data;
          }
        });
      }
    },
    convertUrl: function(url) {
      url = url.replace('https://', 'wss://');
      url = url.replace('/desktop', '');
      url = url.replace('/index.html', '');
      if (url[url.length - 1] === '/') {
        url += 'ws';
      } else {
        url += '/ws';
      }
      return url;
    },
    websocketLatencyCheck: function() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        let v1 = performance.now();
        let textEncoder = new TextEncoder();
        this.socket.send(textEncoder.encode('0ping'));
        let that = this;
        this.socket.onmessage = function() {
          let latency = performance.now() - v1;
          if (that.latency.max === null || latency > that.latency.max) {
            that.latency.max = latency;
          }
          if (that.latency.min === null || latency < that.latency.min) {
            that.latency.min = latency;
          }
          if (that.latency.avg === null) {
            that.latency.avg = latency;
          } else {
            that.latency.avg = (that.latency.avg + latency) / 2;
          }
          // console.log('min: ' + that.latency.min);
          // console.log('max: ' + that.latency.max);
          // console.log('avg: ' + that.latency.avg);
        };
        setTimeout(() => {
          that.websocketLatencyCheck();
        }, this.timespanCheck);
      }
    },
    websocketLatencySend: function() {
      if (
        this.latency.min !== null &&
        this.latency.max !== null &&
        this.latency.avg !== null
      ) {
        let data = {
          attendee: this.attendeePassed['@id'],
          min: this.latency.min,
          max: this.latency.max,
          avg: this.latency.avg,
          url: this.url,
          mainConnection: this.mainConnection,
          urlRegion: this.urlRegion,
        };
        this.resetLatencyData();
        if (this.ignoreSendNext) {
          // we won't send the first collection of data because it's often disturbed
          this.ignoreSendNext = false;
        } else {
          this.sendLatency(data);
        }
      }
      let that = this;
      setTimeout(() => {
        that.websocketLatencySend();
      }, this.timespanSend);
    },
    resetLatencyData: function() {
      this.latency.max = null;
      this.latency.min = null;
      this.latency.avg = null;
    },
  },
  beforeDestroy: function() {
    this.socket.close();
  },
};
</script>

<style lang="scss" scoped></style>
