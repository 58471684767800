export const error = state => state.error;
export const data = state => state.data;
export const attendee = state => {
  if (state.data) {
    return state.data['attendee'];
  }
};
export const course = state => {
  if (state.data) {
    return state.data['course'];
  }
};
export const isLoading = state => state.isLoading;
