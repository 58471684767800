export const error = state => state.error;
export const isLoading = state => state.isLoading;
export const data = state => state.data;
export const courseByUuid = state => uuid => {
  if (state.data) {
    for (let i = 0; i < state.data.length; i++) {
      let item = state.data[i];
      if (item.uuid === uuid) {
        return item;
      }
    }
  }
};
export const courseByType = state => type => {
  if (state.data) {
    for (let i = 0; i < state.data.length; i++) {
      let item = state.data[i];
      if (item.type === type) {
        return item;
      }
    }
  }
};
