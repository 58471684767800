<template>
  <div class="wrapper">
    <h1>FAQ - Linux Foundation</h1>

    <br />

    <h2>
      Do I get free simulator sessions for my Linux Foundation exam?
    </h2>
    <p>
      Yes. If you purchased the CKS|CKA|CKAD|LFCS|LFCT exam, two simulator
      sessions will be already included. You'll find these in your Linux
      Foundation Exam Preparation Checklist. For more information you can check
      <a
        href="https://training.linuxfoundation.org/announcements/linux-foundation-kubernetes-certifications-now-include-exam-simulator"
        target="_blank"
        >The Simulator Announcement</a
      >
      ,
      <a
        href="https://docs.linuxfoundation.org/tc-docs/certification/faq-cka-ckad-cks#is-there-training-to-prepare-for-the-certification-exam"
        target="_blank"
        >The CKA CKAD CKS Faq</a
      >
      and
      <a
        href="https://docs.linuxfoundation.org/tc-docs/certification/frequently-asked-questions-lfct#where-can-i-find-practice-questions-for-lfct"
        target="_blank"
        >The LFCT Faq</a
      >. For questions you can also contact the
      <a href="https://trainingsupport.linuxfoundation.org" target="_blank">
        Linux Foundation Support</a
      >.
    </p>

    <hr />
    <h2>
      How can I access my Linux Foundation simulator sessions?
    </h2>
    <p>
      You'll need to click on the link in your Linux Foundation Exam Preparation
      Checklist as shown below. If you don't have the link please contact the
      <a href="https://trainingsupport.linuxfoundation.org" target="_blank">
        Linux Foundation Support </a
      >.
    </p>

    <b-img
      src="@/assets/lf_exam_prep_list_sisimulators.png"
      width="400px"
      fluid
      style="border: 1px solid lightgrey"
    ></b-img>

    <hr />
    <h2>
      Are the simulator sessions accessible through the Linux Foundation the
      very same as if purchased directly on killer.sh?
    </h2>
    <p>
      Yes.
    </p>

    <br />
    <br />

    <h1>FAQ - Remote Desktop PSI Bridge</h1>

    <p>
      Since 2022 the CKS|CKA|CKAD|LFCS Exam UI is using a Remote Desktop (XFCE)
      instead of a Remote Terminal and the Linux Foundation released an
      <a
        href="https://training.linuxfoundation.org/blog/update-on-certification-exam-proctoring-migration/?utm_source=lftraining&utm_medium=twitter&utm_campaign=blog"
        target="_blank"
        >update</a
      >
      about this. Coming from OSX/Windows there will be changes in copy&paste
      for example.
    </p>

    <br />

    <h2>Do the simulators have the Remote Desktop from 2022?</h2>
    <p>
      The CKS|CKA|CKAD|LFCS simulators use a very similar Remote Desktop
      interface like the real Exam UI. But there are still differences, see
      below.
    </p>

    <hr />
    <h2>
      What are differences between the real exam Remote Desktop and the
      simulator implementation of it?
    </h2>
    <ul>
      <li>
        You'll need to use the PSI Secure Browser to access the real exams
        whereas you can use Chrome/Firefox to access the simulators<br />
      </li>
      <li>
        You can only access certain allowed URLs in the Remote Desktop's Firefox
        of the real exams whereas there are no such restrictions in the
        simulators
      </li>
      <li>
        In the simulators you can switch between the Remote Desktop and the
        Remote Terminal, this won't be possible in the real exams
      </li>
    </ul>

    <hr />
    <h2>How can I copy & paste?</h2>
    <p>
      What always works: copy+paste using right mouse context menu<br />

      What works in Terminal: Ctrl+Shift+c and Ctrl+Shift+v<br />

      What works in other apps like Firefox: Ctrl+c and Ctrl+v<br />
    </p>

    <hr />
    <h2>The desktop is slow and lagging</h2>
    <p>
      This is possibly because of the remote VNC connection. Moving windows or
      scrolling through browser pages will be slower than locally.
    </p>

    <p>
      The most common reason for connection issues is the usage of a VPN/Proxy,
      this can also be your company's remote-work software.
    </p>

    <p>
      If you experience any kind of connection issues please make sure all
      points here are complied with:
    </p>
    <ul>
      <li>Don't use a VPN/Proxy</li>
      <li>Don't activate your company remote-work software</li>
      <li>
        Local development environments like Docker can interrupt ongoing
        connections, close these
      </li>
      <li>
        Switch Browser: use only latest Chrome or latest Firefox. Switch to the
        other if one is already used
      </li>
      <li>Disable ALL browser extensions/plugins and run in private mode</li>
      <li>Use a stable internet connection, with low usage by others</li>
    </ul>

    <p>
      Other than that we can only suggest to restart your session using top nav
      "Restart Session". The new simulator environment will always be created in
      your closest location available.
    </p>

    <hr />
    <h2>Can I view the K8s Docs in my local browser in the real exam?</h2>
    <p>
      No. You need to use the Remote Desktop's Firefox browser.
    </p>

    <hr />
    <h2>I resized the browser and now the desktop has a wrong resolution</h2>
    <p>
      Just slightly decrease or increase the left side (where questions are
      shown) by dragging the separator. This will cause the desktop's resolution
      to adjust again.
    </p>

    <hr />
    <h2>Can I access the Solutions and Score inside the Remote Desktop?</h2>
    <p>
      Yes, there are icons for this on the desktop of the Remote Desktop.
    </p>

    <hr />
    <h2>The Remote Desktop is not usable for me / there are errors</h2>
    <p>
      You can switch to a Remote Terminal using the top menu "Exam Interface",
      this is not possible in the real exams.
    </p>

    <br />
    <br />

    <h1>FAQ - Before purchase</h1>

    <br />
    <h2>I’m in prep for the CKS|CKA|CKAD|LFCS|LFCT, is this for me?</h2>
    <p>
      Yes.
    </p>

    <hr />
    <h2>Which Kubernetes versions are used in the simulators?</h2>
    <table>
      <tr>
        <td style="width: 60px">CKS:</td>
        <td style="min-width: 50px">
          1.31
        </td>
        <td>
          <small
            >(updated for
            <a
              href="https://training.linuxfoundation.org/cks-program-changes"
              target="_blank"
              >2024 changes</a
            >)</small
          >
        </td>
      </tr>
      <tr>
        <td>CKA:</td>
        <td>1.31</td>
      </tr>
      <tr>
        <td>CKAD:</td>
        <td>1.31</td>
      </tr>
    </table>

    <hr />
    <h2>How are the simulators intended to be used?</h2>
    <p>
      You should see our simulators like the real exam, prepare yourself before
      you go in:
    </p>

    <ul>
      <li>Study all exams topics somewhere else</li>
      <li>Start your first simulator session, learn from our solutions</li>
      <li>Study somewhere else somewhat more</li>
      <li>Start your second simulator session</li>
      <li>Rock the real exam</li>
    </ul>

    <p>
      Between starting your first and second test session could be days or even
      weeks.
    </p>

    <hr />
    <h2>How will the simulator help me in preparation?</h2>
    <p>
      You'll get used to the mindset of having to solve questions under
      pressure. Try to solve as many questions by yourself in the shortest
      amount of time without checking our solutions.<br />
      Our questions are of the same type like in the real exam and our solutions
      are detailed and often show general ways of approaching specific issues.
    </p>

    <hr />
    <h2>Do I have to solve the questions under pressure/countdown?</h2>
    <p>
      The countdown is just an indication for yourself. When the countdown
      reaches 0 you’ll be able to view the solutions and your score. You’ll
      still have access to your environment, 36 hours in total. You’ll always
      have access to the questions and solutions.
    </p>

    <hr />
    <h2>I'm not doing the CKS, CKA or CKAD, is this for me?</h2>
    <p>
      If you like to test your Kubernetes knowledge, work, solve and investigate
      complex k8s questions, then maybe. But our simulators are for direct K8s
      exam preparation.
    </p>

    <hr />
    <h2>How many questions are there?</h2>
    <p>
      CKS: 22<br />
      CKA: 25<br />
      CKAD: 22<br />
      LFCS: 20<br />
      LFCT: 20<br />
    </p>

    <hr />
    <h2>Will I get other questions if I purchase a second time?</h2>
    <p>
      No, every simulator session of a type (CKS|CKA|CKAD|LFCS|LFCT) has the
      very same questions.
    </p>

    <hr />
    <h2>Cheaper rebuy rate?</h2>
    <p>
      If you decide you need more than two sessions, then a cheaper rebuy rate
      of 9.99$ per single session will be applied if you already purchased (or
      got Linux Foundation access for) the same simulator during the last 12
      months. But we suggest to come prepared, then two sessions are enough.
    </p>

    <hr />
    <h2>Can I get a preview?</h2>
    <p>
      Once you register, before payment, you can preview 3 questions for the
      CKS|CKA|CKAD. These preview questions are in addition to the ones of the
      full course. The preview questions are also part of the environments
      you'll be provided with, so you can solve the preview questions when
      purchasing the full course.
    </p>

    <hr />
    <h2>How much does this cost?</h2>
    <p>29.99$ for two CKS sessions.</p>
    <p>29.99$ for two CKA sessions.</p>
    <p>29.99$ for two CKAD sessions.</p>
    <p>29.99$ for two LFCS sessions.</p>
    <p>29.99$ for two LFCT sessions.</p>

    <hr />
    <h2>How can I pay?</h2>
    <p>You can pay via Credit Card.</p>

    <hr />
    <h2>Can I pay in another currency than USD?</h2>
    <p>
      We only offer payments in USD. But the bank of your credit card will
      perform automatic currency conversion. Please contact your bank for info
      about additional costs.
    </p>

    <hr />
    <h2 class="font-weight-bold h5">
      Do you offer bulk or voucher purchases?
    </h2>
    <p>
      Yes, we offer companies to purchase vouchers in bulk. Any user using a
      voucher will gain simulator access without payment necessary. Please
      contact us on
      <router-link :to="{ name: 'Support' }">support</router-link>.<br />
    </p>

    <hr />
    <h2>Do you offer refunds or a testing period?</h2>
    <p>
      We don't offer this. If you had issues with your simulator session just
      contact support and we figure out a way to solve it.
    </p>

    <hr />
    <h2>Why don't you provide unlimited access?</h2>
    <p>
      We would like to provide unlimited access. But the environments that we
      create cost good amount of cloud resources. See it like this: if a
      simulator has 20 questions, then these 20 questions are always running.
      Even if you spend one hour just on question 1, we have to provide the
      resources for all questions all the time.<br />
      We decided to implement it this way to simulate the real CKS|CKA|CKAD|LFCS
      environments where this is also the case.<br />
      For unlimited environments check out our partner project
      <a href="https://killercoda.com">Killercoda</a>.<br />
      We offer cheaper re-buys of single simulator sessions, see above for more.
    </p>

    <hr />
    <h2>Will you calculate a score?</h2>
    <p>
      Yes. But we suggest to also compare your solutions to ours. We think this
      has a great learning effect because we also provide additional tips and
      information which you might otherwise skip if you just look at the
      score.<br />
    </p>

    <hr />
    <h2>Why don't you show percentages on the score?</h2>
    <p>
      Because we don't want to give the impression that if you get a certain
      percentage in our simulator you would also get that percentage in the real
      exam.
    </p>

    <hr />
    <h2>Will there be partial scoring in the real exam?</h2>
    <p>
      Yes. The Linux Foundation will calculate a score based on successful
      subtasks. Also considering if some subtasks are harder than others.
    </p>

    <hr />
    <h2>What’s the expiration?</h2>
    <p>
      Once you make the purchase you can activate your sessions independently
      over the time of one year.
    </p>

    <hr />
    <h2>Why do both simulator sessions have identical questions?</h2>
    <p>
      In the beginning our idea was to just provide one session for you. But
      because our simulators are harder than the real exam, more questions for
      example, you might need two tries.
    </p>

    <hr />
    <h2>What happens once the simulator countdown reaches 0?</h2>
    <p>
      The countdown is just an indication for yourself. When the countdown
      reaches 0 you’ll be able to view the solutions and your score. You’ll
      still have access to your environment, 36 hours in total. You’ll always
      have access to the questions and solutions.
    </p>

    <hr />
    <h2>Can I stop the countdown early to directly access the solutions?</h2>
    <p>
      Yes, but we suggest you try to solve all questions yourself before
      checking our solutions.
    </p>

    <hr />
    <h2>Can I restart an environment?</h2>
    <p>
      Yes, while in the 36 hour window. This will completely reset the
      environment and you start from scratch. The time it takes for the new
      environment to boot up will be added to the expiration date, so you won't
      lose any time.
    </p>

    <hr />
    <h2>Is this course for me if I have zero knowledge of Kubernetes?</h2>
    <p>
      No, best is you already have some knowledge about Kubernetes and are
      somewhat comfortable with kubectl. This course is mainly for testing your
      existing knowledge if you’re chasing the CKS, CKA or CKAD.
    </p>

    <hr />
    <h2>How can I log in?</h2>
    <p>
      Currently you can login using the external services
    </p>
    <ul>
      <li>Linux Foundation</li>
      <li>Google</li>
      <li>Github</li>
      <li>Gitlab</li>
      <li>Microsoft</li>
      <li>Linkedin</li>
    </ul>
    <p>
      You can also login using Email where we send a login link to you without
      the need of defining a password.
    </p>

    <hr />
    <h2>Where can I see my email address used to log in?</h2>
    <p>
      You can see it in your
      <router-link :to="{ name: 'UserProfile' }">
        Account Section
      </router-link>
      .
    </p>

    <hr />
    <h2>
      Can I stream or create videos with killer.sh content?
    </h2>
    <p>
      Please request permission via
      <router-link :to="{ name: 'Support' }">Support</router-link>.
    </p>

    <hr />
    <h2>What if I still have questions?</h2>
    <p>
      You can contact us at
      <router-link :to="{ name: 'Support' }">Support</router-link>
    </p>

    <hr />
    <h2>Who is behind this?</h2>
    <p>
      We are a group of Kubernetes and hands-on exam enthusiast. What we have in
      common is that we are amazed by modern software infrastructure and various
      technologies. The idea of this platform was created and developed by
      <a href="https://wuestkamp.com" target="_blank">Kim Wüstkamp</a>.
    </p>

    <br /><br />

    <h1 id="session-runtime">FAQ - Session Runtime</h1>

    <br />
    <h2>How long can I access my simulator session?</h2>
    <p>
      Once activated, a simulator session is accessible during the next
      following 36 hours.<br />
      The 36 hours are time based and not usage based.<br />
      The 36 hours cannot be spread over multiple days or weeks and have to be
      used at once.<br />
      After the 36 hours have passed, you’ll still have access to the questions
      and our solutions, but not the interactive environment.<br />
    </p>

    <hr />
    <h2>Can I close or reload the browser without losing my solutions?</h2>
    <p>
      Yes. For the 36 hours your session will be kept running in the background.
      You can close the window or even use a different browser.
    </p>

    <hr />
    <h2>My session should still be active but I can't access it?</h2>
    <p>
      Make sure you select the correct one from your dashboard. Sessions are
      accessible for the next 36 hours after activation, which is 1.5 days. The
      36 hours are time based and not usage based.
    </p>

    <hr />
    <h2>SSH connection issue, do I need a password?</h2>
    <p>
      No password is required. Ensure to run "exit" to get back to your main
      terminal because nested ssh is not possible. You could also open a new
      Terminal Emulator application, if your exam uses the Remote Desktop
      (CKS|CKA|CKAD|LFCS), which should start at your default instance and
      default user.
    </p>

    <hr />
    <h2>Slow or interrupted connection?</h2>
    <p>
      The most common reason for connection issues is the usage of a VPN/Proxy,
      this can also be your company's remote-work software.
    </p>

    <p>
      If you experience any kind of connection issues please make sure all
      points here are complied with:
    </p>
    <ul>
      <li>Don't use a VPN/Proxy</li>
      <li>Don't activate your company remote-work software</li>
      <li>
        Local development environments like Docker can interrupt ongoing
        connections, close these
      </li>
      <li>
        Switch Browser: use only latest Chrome or latest Firefox. Switch to the
        other if one is already used
      </li>
      <li>Disable ALL browser extensions/plugins and run in private mode</li>
      <li>Use a stable internet connection, with low usage by others</li>
      <li>
        Use the Remote Terminal instead of the Remote Desktop, select from top
        menu once simulator is fully loaded
      </li>
    </ul>

    <p>
      Other than that we can only suggest to restart your session using top nav
      "Restart Session". The new simulator environment will always be created in
      your closest location available.
    </p>

    <hr />
    <h2>I have keyboard issues, some keys don't work in the terminal</h2>
    <p>
      For OSX it could be your keyboard layout. Please go to
      Preferences->Keyboard->Input Sources. There search for English and then
      select "ABC" (or maybe called "Default"). Switching the layout should
      solve the input issue.
    </p>
    <p>
      For Windows try to change your keyboard layout to a different English one.
    </p>
    <p>
      For Ubuntu+Chrome for example users report keyboard issues, switch to
      Firefox or Chromium. Also open killer.sh in private browser mode.
    </p>

    <hr />
    <h2>My environment is misconfigured and not reachable/working properly</h2>
    <p>
      You can restart your environment via "Exam Controls -> Restart Session".
    </p>

    <hr />
    <h2>Where can I restart my environment?</h2>
    <p>
      While in the 36 hours access window you can select to your active
      terminal. You find the action under "Exam Controls -> Restart Session".
    </p>

    <hr />
    <h2>I have a question about a question</h2>
    <p>
      Please ask all content questions in our
      <span v-if="isAuthenticated && isFullMember()">
        <a href="https://killer.sh/slack" target="_blank">Slack</a>
      </span>
      <span v-else>
        Slack
      </span>
      community.
    </p>

    <hr />
    <h2>Why does the score not work for my solution?</h2>
    <p>
      Please apply our provided solution step-by-step. If it still doesn't work
      please restart your environment ("Exam Controls -> Restart Session") and
      try again. Compare our solution closely to yours, you can also post your
      solution in our
      <span v-if="isAuthenticated && isFullMember()">
        <a href="https://killer.sh/slack" target="_blank">Slack</a>
      </span>
      <span v-else>
        Slack
      </span>
      community.
    </p>

    <hr />
    <h2>Where can I see the solutions?</h2>
    <p>
      Head to your active terminal. On the "Exam Controls" you find a menu with
      item "Solutions".
    </p>

    <hr />
    <h2>Where is the course/simulator content?</h2>
    <p>
      The full course content (questions+solutions+tips) will be available once
      the countdown of an activated session reached 0. Check one below to see
      how to access the content earlier.
      <br />Head to your active terminal. On the "Exam Controls" you find a menu
      with item "Solutions". This link will contain the complete course content.
    </p>

    <hr />
    <h2>
      Can I access the course/simulator content before activating a session?
    </h2>
    <p>
      We strongly believe that you get the best learning effect if you try to
      solve the questions by yourself without access to the solutions. If you
      rather disagree with this approach you can access the solutions by adding
      /content to the url of the Activate Simulator Session screen. Like
      this:<br />
      https://killer.sh/attendee/{YOUR_SESSION_UUID}/content
    </p>

    <hr />
    <h2>
      More Questions?
    </h2>
    <p>
      <span v-if="isAuthenticated && isFullMember()">
        <a href="https://killer.sh/slack" target="_blank">Slack</a> for scenario
        and content discussions
      </span>
      <span v-else>
        Slack for scenario and content discussions
      </span>
      <br />
      <router-link :to="{ name: 'Support' }">Support</router-link> for
      session/account issues
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: mapGetters({
    isAuthenticated: 'authentication/status/isAuthenticated',
    user: 'user/list/data',
    isLoading: 'user/list/isLoading',
  }),
  methods: {
    userHasGoodProfile: function() {
      return this.user.providedAllDataToMakePurchase === true;
    },
    isFullMember: function() {
      return this.user && this.user.fullMember;
    },
  },
};
</script>
