import fetch from '../../../../utils/fetch';
import * as mutationTypes from './mutation_types';
import store from '@/store';
import router from '@/router/router';
import VueCookies from 'vue-cookies';
import * as definitions from '@/store/modules/authentication/status/definitions';

const getToken = ({ commit }, page) => {
  commit(mutationTypes.TOGGLE_LOADING);

  fetch(page)
    .then(response => response.json())
    .then(data => {
      commit(mutationTypes.TOGGLE_LOADING);
      store.dispatch('authentication/status/setJwtTokens', data, {
        root: true,
      });

      let redirectUrl = VueCookies.get(
        definitions.COOKIE_NAME_AUTH_REDIRECT_URL
      );
      if (redirectUrl) {
        VueCookies.remove(definitions.COOKIE_NAME_AUTH_REDIRECT_URL);
        router.push(redirectUrl);
      } else {
        router.push({ name: 'Dashboard' });
      }
    })
    .catch(() => {
      commit(mutationTypes.TOGGLE_LOADING);
      commit(mutationTypes.SET_ERROR, true);
    });
};

const getMethods = ({ commit }) => {
  commit(mutationTypes.SET_ERROR, false);
  commit(mutationTypes.TOGGLE_LOADING);

  fetch('/auth/oauth/methods')
    .then(response => response.json())
    .then(data => {
      commit(mutationTypes.TOGGLE_LOADING);
      commit(mutationTypes.SET_DATA, data);
    })
    .catch(() => {
      commit(mutationTypes.TOGGLE_LOADING);
      commit(mutationTypes.SET_ERROR, true);
    });
};

export { getToken, getMethods };
