import * as types from './mutation_types';

const setProduct = ({ commit }, productId) => {
  commit(types.SET_DATA, productId);
};

const reset = ({ commit }) => {
  commit(types.SET_DATA, null);
};

export { setProduct, reset };
