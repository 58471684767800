import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

const getCourses = ({ commit }) => {
  commit(types.TOGGLE_LOADING);

  fetch('courses')
    .then(response => response.json())
    .then(data => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_DATA, data['hydra:member']);
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

export { getCourses };
