<template>
  <span>
    <WebsocketLatencyTest
      v-bind:urlPassed="attendeeTerminalAddressPassed"
      v-bind:attendeeTerminalAddress="attendeeTerminalAddressPassed"
      v-bind:attendeePassed="attendee"
    ></WebsocketLatencyTest>
    <iframe :src="attendeeTerminalAddressPassed"></iframe>
  </span>
</template>

<script>
import WebsocketLatencyTest from '@/components/websocket/LatencyTest';

export default {
  name: 'Terminal',
  components: {
    WebsocketLatencyTest,
  },
  props: {
    attendee: {
      required: true,
    },
    attendeeTerminalAddressPassed: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';

iframe {
  border: 0;
  overflow-x: scroll;
  width: 100%;
  height: 100%;
  background-color: #2b2b2b;
  background: url(../../../assets/spinner.gif) center center no-repeat;
  background-size: 40px 40px;
}
</style>

<style lang="css" scoped>
.xterm .xterm-viewport {
  overflow-y: auto !important;
}
</style>
