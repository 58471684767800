<template>
  <div ref="draggableContainer" class="draggable-container">
    <span class="resize-icon">
      <b-img src="@/assets/resize.svg"></b-img>
    </span>
    <div
      class="draggable-header"
      @mousedown="dragMouseDown"
      @mouseup="moveStopped"
      @mouseout="moveStopped"
      :class="{ moving: moving }"
    >
      <slot name="header"></slot>
    </div>
    <div class="draggable-main">
      <slot name="main"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DraggableDiv',
  data: function() {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      moving: false,
    };
  },
  props: {
    draggable: {
      required: false,
      default: true,
    },
  },
  methods: {
    moveStopped: function() {
      this.moving = false;
    },
    dragMouseDown: function(event) {
      if (!this.draggable) {
        return;
      }
      this.moving = true;
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function(event) {
      if (!this.draggable) {
        return;
      }
      event.preventDefault();

      let eventX = event.clientX;
      let eventY = event.clientY;

      let topBorder = 40;

      if (
        eventX > window.innerWidth ||
        eventX < 0 ||
        eventY > window.innerHeight ||
        eventY < topBorder
      ) {
        return;
      }

      this.positions.movementX = this.positions.clientX - eventX;
      this.positions.movementY = this.positions.clientY - eventY;
      this.positions.clientX = eventX;
      this.positions.clientY = eventY;

      let newTop =
        this.$refs.draggableContainer.offsetTop - this.positions.movementY;
      let newLeft =
        this.$refs.draggableContainer.offsetLeft - this.positions.movementX;

      if (newTop <= topBorder) {
        newTop = topBorder;
      }
      if (newTop >= window.innerHeight) {
        newTop = window.innerHeight;
      }
      if (newLeft <= 0) {
        newLeft = 0;
      }
      if (newLeft >= window.innerWidth) {
        newLeft = window.innerWidth;
      }

      // set the element's new position:
      this.$refs.draggableContainer.style.top = newTop + 'px';
      this.$refs.draggableContainer.style.left = newLeft + 'px';
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
};
</script>

<style>
.resize-icon {
  position: absolute;
  bottom: 6px;
  right: 0;
  width: 16px;
  height: 16px;
}
.draggable-container {
  position: absolute;
  z-index: 9999;
  resize: both;
  min-height: 10vh;
  min-width: 10vh;
  max-height: 50vh;
  max-width: 50vh;
  height: 30vh;
  width: 30vh;
  overflow: hidden;
}
.draggable-header {
  z-index: 100000;
  background-color: #1c7396;
  color: #f9fefe;
  cursor: grab;
  padding: 5px 15px;
  border-radius: 0;
  height: 35px;
}
.moving {
  cursor: grabbing;
}
.draggable-main {
  height: calc(100% - 35px);
}
</style>
