import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';
import store from '@/store';

const add = ({ commit }, code) => {
  commit(types.TOGGLE_LOADING);
  commit(types.SET_ERROR, false);
  commit(types.SET_SUCCESS, null);
  let data = {
    code: code,
  };
  let options = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  fetch('order/add-discount-code', options)
    .then(response => {
      return response.json();
    })
    .then(data => {
      store.dispatch('order/get/getOrders', null, {
        root: true,
      });
      if (data.success !== true) {
        if (data.message) {
          commit(types.SET_ERROR, data.message);
        } else {
          commit(types.SET_ERROR, 'Code not accepted');
        }
      } else {
        commit(types.SET_SUCCESS, 'Code accepted');
      }
      commit(types.TOGGLE_LOADING);
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

const reset = ({ commit }) => {
  commit(types.RESET, null);
};

export { add, reset };
