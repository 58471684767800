<template>
  <div class="terminal-loading">
    <div class="vertical-center">
      <span v-if="currentQuestionState === 'submitted-restarting'">
        <p>
          <strong>You successfully submitted your solution</strong><br /><br />
          You can go ahead and select the next question.
        </p>
      </span>
      <span v-if="currentQuestionState === 'discarded-restarting'">
        <p>
          <strong>You discarded your solution</strong>
          <br /><br />
          You can go ahead and select the next question.
        </p>
      </span>
      <br />
      <p class="small">
        The environment is being restarted in case you like to submit a new
        solution. This can take a minute.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TerminalRestart',
  props: {
    attendee: {
      required: true,
    },
    currentQuestionState: {
      required: true,
    },
  },
  mounted() {
    this.getState();
  },
  computed: {
    ...mapGetters({
      stateIsLoading: 'attendee/lfctOperator/isLoading',
    }),
  },
  methods: {
    getState: function() {
      this.$store.dispatch('attendee/lfctOperator/getState', [
        this.attendee.terminal.clusterTerminalProxyLfctOperatorAddress,
      ]);
    },
  },
  watch: {
    stateIsLoading(newValue) {
      if (!newValue) {
        setTimeout(() => {
          this.getState();
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';

.action {
  text-transform: capitalize;
}

.terminal-loading {
  position: relative;
  height: 100%;
  text-align: center;
}

.vertical-center {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

p {
  max-width: 80%;
  margin: 0 auto;
}
</style>
