var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(
      _vm.currentQuestionState === 'open' || _vm.currentQuestionState === 'submitted'
    )?_c('TerminalEntry',{attrs:{"attendee":_vm.attendee,"attendeeTerminalAddressPassed":_vm.attendeeTerminalAddressPassed,"questionIndex":_vm.attendeeQuestionIndex,"questionState":_vm.currentQuestionState}}):(_vm.currentQuestionState === 'active')?_c('TerminalActive',{attrs:{"attendee":_vm.attendee,"attendeeTerminalAddressPassed":_vm.attendeeTerminalAddressPassed}}):(
      _vm.currentQuestionState === 'submitting' ||
        _vm.currentQuestionState === 'discarding' ||
        _vm.currentQuestionState === 'loading'
    )?_c('TerminalLoading',{attrs:{"attendee":_vm.attendee}}):(
      _vm.currentQuestionState === 'restarting' ||
        _vm.currentQuestionState === 'submitted-restarting' ||
        _vm.currentQuestionState === 'discarded-restarting'
    )?_c('TerminalRestart',{attrs:{"attendee":_vm.attendee,"currentQuestionState":_vm.currentQuestionState}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }