<template>
  <div>
    <span v-if="course && course.questions">
      <div class="question-navigation">
        <div class="row">
          <div class="col-2 text-center">
            <button
              style="margin-left: 10px"
              type="button"
              class="btn btn-light"
              v-on:click="prevQuestion()"
              :disabled="!questionSelectionEnabled"
            >
              &lt;
            </button>
          </div>
          <div class="col-8 text-center">
            <b-form-select
              v-model="question"
              :options="getQuestionOptions()"
              :disabled="!questionSelectionEnabled"
              size="sm"
              html-field="text"
            >
            </b-form-select>
          </div>
          <div class="col-2 text-center">
            <button
              style="margin-right: 10px"
              type="button"
              class="btn btn-light"
              v-on:click="nextQuestion()"
              :disabled="!questionSelectionEnabled"
            >
              &gt;
            </button>
          </div>
        </div>
      </div>

      <span>
        <div class="question">
          <markdown-it-vue class="md-body" :content="getQuestionContent()" />
        </div>
      </span>
    </span>
  </div>
</template>

<script>
import MarkdownItVue from 'markdown-it-vue';
import 'markdown-it-vue/dist/markdown-it-vue.css';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CourseQuestions',
  components: {
    MarkdownItVue,
  },
  props: {
    courseUuid: String,
    courseVersion: String,
    showPreview: Boolean,
  },
  mounted: function() {
    this.getCourses();
    this.setQuestionIndex(1);
  },
  computed: {
    ...mapGetters({
      question: 'attendee/run/questionIndex',
      questionSelectionEnabled: 'attendee/run/questionSelectionEnabled',
    }),
    question: {
      get() {
        return this.$store.state.attendee.run.questionIndex;
      },
      set(newValue) {
        this.setQuestionIndex(parseInt(newValue));
      },
    },
    course() {
      let func = this.$store.getters['course/list/courseByUuid'];
      return func(this.courseUuid);
    },
  },
  methods: {
    ...mapActions({
      getCourses: 'course/list/getCourses',
    }),
    setQuestionIndex: function(index) {
      this.$store.dispatch(
        'attendee/lfctOperator/setQuestionStateLoading',
        index
      );
      this.$store.dispatch('attendee/run/setQuestionIndex', index);
    },
    prevQuestion: function() {
      if (this.question > 1) {
        this.setQuestionIndex(parseInt(this.question) - 1);
      }
    },
    nextQuestion: function() {
      if (this.question < this.getQuestionOptions().length) {
        this.setQuestionIndex(parseInt(this.question) + 1);
      }
    },
    getQuestionContent: function() {
      return this.course.questions[this.courseVersion][this.question];
    },
    showCompletedIcon: function(questionIndex) {
      let state = this.$store.getters[
        'attendee/lfctOperator/questionStateForQuestion'
      ](questionIndex);
      return state === 'submitted';
    },
    showLoadingIcon: function(questionIndex) {
      let state = this.$store.getters[
        'attendee/lfctOperator/questionStateForQuestion'
      ](questionIndex);
      return (
        state === 'submitted-restarting' || state === 'discarded-restarting'
      );
    },
    getQuestionOptions: function() {
      let questions = [];
      let previewQuestionCounter = 1;
      let extraQuestionCounter = 1;
      for (const [key, value] of Object.entries(
        this.course.questions[this.courseVersion]
      )) {
        if (key === '0') {
          continue;
        }
        let name = '';
        if (value.includes('Preview Question')) {
          if (this.showPreview) {
            name = 'Preview Question ' + previewQuestionCounter;
            previewQuestionCounter++;
            questions.push({ text: name, value: key });
          }
        } else if (value.includes('Extra Question')) {
          if (this.showPreview) {
            name = 'Extra Question ' + extraQuestionCounter;
            extraQuestionCounter++;
            questions.push({ text: name, value: key });
          }
        } else {
          name = 'Question ' + key;
          if (this.showCompletedIcon(key)) {
            name = name + ' &#x2713;';
          } else if (this.showLoadingIcon(key)) {
            name = name + ' &#x21bb;';
          }
          questions.push({ text: name, value: key });
        }
      }
      return questions;
    },
  },
};
</script>

<style lang="scss">
.tippy-box {
  transform: scale(0.8);
}
.question {
  .markdown-body {
    color: #171717;

    pre.hljs {
      span {
        color: unset;
      }
    }
    blockquote {
      border-left: unset;
      background-color: rgba(236, 236, 255, 0.62);
      padding: 10px 15px;
      margin: 10px 10px;
      border-radius: 5px;
    }
  }
}
</style>

<style lang="scss" scoped>
.question {
  margin-bottom: 50px;
}
.question-navigation {
  margin: 30px 0;
  button,
  select {
    border: 1px solid hsl(213deg 22% 74%);
    border-radius: 0;
    outline: none;
  }
  button:focus,
  select:focus {
    outline: none;
  }
  button,
  select {
    height: 40px;
  }
  div {
    padding: 0 !important;
  }
}
</style>
