import oauth from './oauth';
import status from './status';
import authEmail from './authEmail';

export default {
  namespaced: true,
  modules: {
    oauth,
    status,
    authEmail,
  },
};
