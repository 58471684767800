<template>
  <div class="wrapper">
    <h1>Authentication</h1>
    <span v-if="isLoading">Authentication in progress... please wait</span>
    <p v-if="errorState" class="alert alert-warning">
      An Error Occurred
    </p>
    <div v-if="errorEmailAuthState">
      <p class="alert alert-warning">
        <strong>The email login link was not generated on this device !</strong
        ><br />
        Only continue the login if you generated the email login link
        yourself.<br />
        After login ensure your correct email is displayed in your account area.
      </p>
      <button class="btn btn-secondary" v-on:click="runGetToken">
        Perform Login
      </button>
    </div>
    <span v-if="error">
      <span v-if="isEmailAuth()">
        An error occurred. The email authentication link might not be valid, it
        can only be used once and is valid for 1 hour. You can generate a new
        one on the login page.
      </span>
      <span v-else>
        An error occurred, please try again or contact support.
        <strong
          >Make sure you did configure and verify a correct email address with
          the chosen login provider</strong
        >.
      </span>
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: mapGetters({
    error: 'authentication/oauth/error',
    isLoading: 'authentication/oauth/isLoading',
    oAuthStateLocal: 'authentication/oauth/stateLocal',
    emailStateLocal: 'authentication/authEmail/stateLocal',
  }),

  data: function() {
    return {
      url: null,
      errorState: false,
      errorEmailAuthState: false,
    };
  },

  created() {
    this.url = window.location.pathname + window.location.search;

    if (this.isEmailAuth()) {
      if (this.emailAuthShowWarning()) {
        this.errorEmailAuthState = true;
      } else {
        this.getToken(this.url);
      }
      return;
    }
    if (this.oAuthStateLocal) {
      for (let i = 0; i < this.oAuthStateLocal.length; i++) {
        if (this.url.includes(this.oAuthStateLocal[i])) {
          this.getToken(this.url);
          return;
        }
      }
    }
    this.errorState = true;
  },
  methods: {
    ...mapActions({
      getToken: 'authentication/oauth/getToken',
    }),
    runGetToken: function() {
      this.getToken(this.url);
    },
    emailAuthShowWarning: function() {
      let seconds = this.emailAuthSecondsTillGenerated();
      return !seconds || seconds > 60 * 60;
    },
    emailAuthSecondsTillGenerated: function() {
      if (this.emailStateLocal) {
        let currentDate = new Date();
        let previousDate = new Date(this.emailStateLocal * 1);
        return (currentDate.getTime() - previousDate.getTime()) / 1000;
      }
    },
    isEmailAuth: function() {
      return this.url && this.url.startsWith('/auth/email');
    },
  },
};
</script>
