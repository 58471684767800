export const error = state => state.error;
export const isLoading = state => state.isLoading;
export const data = state => state.data;
export const token = state => {
  if (state && state.data) {
    return state.data.token;
  }
};
export const publicToken = state => {
  if (state && state.data) {
    return state.data.publicToken;
  }
};
