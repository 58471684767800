export const error = state => state.error;
export const isLoading = state => state.isLoading;
export const questionState = state => state.questionState;
export const questionStateForQuestion = state => questionIndex => {
  if (state.questionState) {
    return state.questionState[questionIndex - 1];
  }
};
export const getQuestionIndexWithRunningOperation = state => {
  if (state.questionState) {
    for (let i = 0; i < state.questionState.length; i++) {
      if (
        state.questionState[i] === 'submitting' ||
        state.questionState[i] === 'loading'
      ) {
        return i;
      }
    }
    return null;
  }
};
