<template>
  <div class="lfct_actions">
    <div v-if="isLoading" style="margin-top: 5px">
      <Spinner color="white" style="margin-top: 5px"></Spinner>
    </div>
    <span v-else>
      <button
        class="btn btn-secondary submit"
        v-on:click="submitSolution()"
        style="margin-right: 18px"
      >
        <b-img
          src="@/assets/icons/double-arrow-right-icon.svg"
          style="max-width: 13px; margin-top: -3px"
        ></b-img>
        Submit
      </button>
      <button class="btn btn-secondary" v-on:click="discardSolution()">
        <b-img
          src="@/assets/icons/power-off-line-icon-mod.svg"
          style="max-width: 13px; margin-top: -2px"
        ></b-img>
        Reset
      </button>
    </span>
  </div>
</template>

<script>
import Spinner from '@/components/util/SpinnerSmall';
import { mapGetters } from 'vuex';

export default {
  name: 'TerminalActiveActions',
  components: {
    Spinner,
  },
  props: {
    attendeeTerminalLfctOperatorAddress: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      attendeeQuestionIndex: 'attendee/run/questionIndex',
      isLoading: 'attendee/lfctOperator/isLoading',
      error: 'attendee/lfctOperator/error',
    }),
  },
  methods: {
    submitSolution: function() {
      this.$store.dispatch('attendee/lfctOperator/submitResult', [
        this.attendeeTerminalLfctOperatorAddress,
        this.attendeeQuestionIndex,
      ]);
    },
    discardSolution: function() {
      this.$store.dispatch('attendee/lfctOperator/discardResult', [
        this.attendeeTerminalLfctOperatorAddress,
        this.attendeeQuestionIndex,
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';

.lfct_actions {
  background-color: #0f3366;
  height: 45px;
  padding-top: 6px;

  button {
    float: right;
    height: 30px;
    min-width: 150px;
    background-color: #1c7396;
    padding-top: 2px;
    border-radius: 0;
    border-color: transparent;
    margin-right: 5px;
  }
}
</style>
