<template>
  <div class="wrapper">
    <div v-if="!success">
      <b-img src="@/assets/udemy-logo.svg" fluid></b-img>
      <h1 style="margin-top: 80px">Request Udemy Access</h1>
      <div>
        <p>
          Please enter your personal code from the Udemy course.
        </p>
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>
        <div style="margin-top: 50px">
          <input type="text" placeholder="Code" class="code" v-model="code" />

          <div style="margin-top: 30px"></div>

          <div v-if="isLoading" style="width: 50px">
            <Spinner></Spinner>
          </div>

          <span v-else>
            <button
              class="btn card-cta"
              v-if="!isLoading"
              @click="accessRequest()"
            >
              Send
            </button>
          </span>
        </div>
      </div>
    </div>
    <div v-else>
      <h1 style="margin-top: 80px">Welcome to Killer Shell !</h1>

      <br /><br />

      <div class="row">
        <div class="col-md-4 col-sm-12">
          <p class="text-center">
            <font-awesome-icon
              :icon="['fab', 'twitter']"
              size="1x"
              class="mr-3"
            />
            <a href="https://twitter.com/_killer_shell" target="_blank"
              >Twitter</a
            >
            for updates
          </p>
        </div>
        <div class="col-md-4 col-sm-12">
          <p class="text-center">
            <font-awesome-icon
              :icon="['fab', 'slack']"
              size="1x"
              class="mr-3"
            />
            <a href="https://killer.sh/slack" target="_blank">Slack</a>
            for discussions
          </p>
        </div>
        <div class="col-md-4 col-sm-12">
          <p class="text-center">
            <font-awesome-icon icon="envelope" size="1x" class="mr-3" />
            <router-link :to="{ name: 'Support' }">
              Support
            </router-link>
            for help
          </p>
        </div>
      </div>

      <ul class="info-list" style="margin: 50px 0;">
        <li>
          $ You have access to two CKS simulator sessions
        </li>
        <li>
          $ There is not unlimited access, so plan your time wisely
        </li>
        <li>
          $ You can start your two sessions during the next 12 months
        </li>
        <li>
          $ Once a session is started, the environment will stay open during the
          following 36 hours
        </li>
        <li>
          $ Both sessions will have the same questions
        </li>
        <li>$ Treat the simulator like the real exam: come prepared</li>
        <li>
          $ Read more in our
          <span class="link-normal"
            ><a href="https://killer.sh/faq" target="_blank">FAQ</a></span
          >.
        </li>
      </ul>

      <button class="btn card-cta" @click="understood()">
        Understood
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '@/components/util/Spinner';
import router from '@/router/router';

export default {
  name: 'AccessRequestCreate',
  components: {
    Spinner,
  },
  data: function() {
    return {
      code: '',
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'accessRequest/create/isLoading',
      error: 'accessRequest/create/error',
      success: 'accessRequest/create/success',
    }),
  },
  methods: {
    accessRequest: function() {
      let data = {
        code: this.code,
        type: 'cks',
      };
      this.$store.dispatch('accessRequest/create/create', data);
    },
    understood: function() {
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        loader: false, // set to true if you want the dialog to show a loader after click on "proceed"
        reverse: false, // switch the button positions (left to right, and vise versa)
        okText: 'Yes',
        cancelText: 'No',
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        type: 'basic', // coming soon: 'soft', 'hard'
        verification: 'very much so', // for hard confirm, user will be prompted to type this to enable the proceed button
        verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
        clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
        backdropClose: false, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
        customClass: '', // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$dialog
        .confirm('Did you read the info text?', options)
        .then(function() {
          router.push({ name: 'Dashboard' });
        })
        .catch(function() {});
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';
.code {
  padding: 10px;
  height: 50px;
  width: 400px;
}
.info-list {
  font-size: 0.9rem;
  margin: 0;
  list-style: none;
  background-color: #f3f0ff;
  padding: 10px;
  color: #464646;
  li {
    margin-bottom: 5px;
  }
}
</style>
