<template>
  <div>
    <div>
      <div>
        <span v-if="!isFinished">
          <span v-if="error" class="alert alert-waring">
            An error occurred! Please try again in a few minutes.
          </span>
          <span v-else>
            <input
              type="email"
              id="email"
              v-model="email"
              placeholder="Your email address"
            />
            <span v-if="!emailValid">
              <p style="margin-top: 20px">Email not valid</p>
            </span>
            <div class="text-center">
              <span v-if="!isLoading">
                <button class="btn card-cta" @click="sendEmailButton">
                  Login
                </button>
              </span>
              <span v-else>
                <p style="margin-top: 20px">loading...</p>
              </span>
            </div>
          </span>
        </span>
        <span v-else>
          We sent you an email containing a login link. Make sure to check the
          spam as well.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AuthEmailSend',
  data: function() {
    return {
      email: null,
      emailValid: true,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'authentication/authEmail/isLoading',
      isFinished: 'authentication/authEmail/isFinished',
      error: 'authentication/authEmail/error',
    }),
  },
  methods: {
    ...mapActions({
      sendEmail: 'authentication/authEmail/sendEmail',
      reset: 'authentication/authEmail/resetAction',
    }),
    sendEmailButton: function() {
      if (this.validEmail(this.email)) {
        this.emailValid = true;
        this.sendEmail(this.email);
      } else {
        this.emailValid = false;
      }
    },
    validEmail: function(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
  created() {
    this.reset();
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';
input {
  margin: 0;
  padding: 10px;
  width: 100%;
}
button {
  width: 100%;
}
</style>
