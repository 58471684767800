<template>
  <div class="root">
    <b-container>
      <div class="cks-bg-img">
        <div class="card-inner">
          <b-row id="hero-section" style="margin: 0 15px">
            <b-col md="8" sm="12" class="hero-text">
              <small>About the</small><br />
              <span class="text-highlight">CKS</span> Simulator
              <h5 class="mt-3">
                Certified Kubernetes Security Specialist
                <small>Simulator</small>
              </h5>
            </b-col>
            <b-col md="4" sm="12" class="text-center"></b-col>
          </b-row>
        </div>
      </div>

      <b-card no-body class="home-card">
        <b-row no-gutters class="card-inner">
          <b-col md="6">
            <b-card-body>
              <b-card-text>
                Our CKS simulator will provide you with an environment
                containing 22 scenarios and their solutions. You'll have access
                to two identical test sessions (in the same simulator) which you
                can start independently the period of one year.<br /><br />
                The idea is that you solve as many scenarios as possible in the
                shortest amount of time, just as you need in the real exam.<br /><br />
                <strong
                  >Verify your K8s security skills and prepare for the CKS
                  exam!</strong
                >
                <div class="cta-preview mt-5">
                  <span v-if="isAuthenticated">
                    <router-link
                      :to="{ name: 'Dashboard' }"
                      class="btn hero-cta fit-content"
                    >
                      Preview Questions
                    </router-link>
                  </span>
                  <span v-else>
                    <router-link
                      :to="{ name: 'Dashboard' }"
                      class="btn hero-cta fit-content"
                    >
                      Login to preview scenarios
                    </router-link>
                  </span>
                </div>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6" class="card-two text-center">
            <div style="margin: 50px 0">
              <a
                href="https://training.linuxfoundation.org/certification/certified-kubernetes-security-specialist"
                target="_blank"
              >
                <b-img
                  src="@/assets/k8s_cks_logo_s.png"
                  fluid
                  width="230px"
                ></b-img>
              </a>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card dark">
        <div class="background"></div>
        <b-row no-gutters class="card-inner align-items-center">
          <b-col md="6" class="card-two text-center">
            <div class="big-text">?</div>
          </b-col>
          <b-col md="6">
            <b-card-body title="Is this for you?">
              <b-card-text>
                Are you in preparation for the CKS? Perfect, our simulator is
                made for you. Test your knowledge, get a feeling for what will
                be coming at you and learn more about Kubernetes Security
                Solutions.<br /><br />
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card content-listview">
        <b-row no-gutters class="card-inner">
          <b-col md="12">
            <b-card-body title="What you'll get">
              <b-card-text>
                <CksSimulatorContentList></CksSimulatorContentList>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card dark">
        <div class="background"></div>
        <b-row no-gutters class="card-inner align-items-center">
          <b-col md="6" class="card-two">
            <b-card-img
              src="@/assets/screenshots/cks_preview_question_1.png"
              class="rounded-0"
              style="padding: 50px"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="22 unique scenarios and detailed solutions">
              <b-card-text>
                We provide you with a set 22 unique questions and scenarios to
                solve in 120 minutes. You'll get access to your own clusters,
                loaded with various resources and security challenges configured
                in each.<br /><br />
                For some tasks you have to work with an insecure setup and
                advance it further. For others you need to verify if the
                existing security is strong enough. Just like in the real exam.
                <br /><br />
                You'll work for example with Apiserver-Config,
                AdmissionControllers, RBAC, ETCD-Secrets, Container-Level and
                Linux-Level security, OpenSource vulnerability scanners.
                <br /><br />
                There are detailed solutions to all our scenarios from which you
                can learn. If you have questions or suggestions about these you
                can also contact us.
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card">
        <b-row no-gutters class="card-inner align-items-center">
          <b-col md="6">
            <b-card-body title="Score">
              <b-card-text>
                You'll get a calculated score of all questions solved. In
                addition you get a detailed list of the solved/unsolved
                sub-tasks for each question. <br /><br />
                For some questions though there is still manual review required,
                so you'll have to compare your solutions to ours.
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6">
            <b-card-img
              src="@/assets/screenshots/cks_score.png"
              class="rounded-0"
              style="padding: 50px"
            ></b-card-img>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card dark">
        <div class="background"></div>
        <b-row no-gutters class="card-inner align-items-center">
          <b-col md="6">
            <b-card-img
              src="@/assets/screenshots/cks_preview_question_2.png"
              class="rounded-0"
              style="padding: 50px"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="Scenario Preview">
              <b-card-text>
                Once registered you can view 3 example preview questions and
                their solutions. These are in addition to the 22 of the full
                course. The preview questions are also implemented in our test
                environment and can be solved in there by you.
              </b-card-text>
              <span v-if="isAuthenticated">
                <router-link
                  :to="{ name: 'Dashboard' }"
                  class="btn card-cta fit-content"
                >
                  Preview Questions
                </router-link>
              </span>
              <span v-else>
                <router-link
                  :to="{ name: 'Dashboard' }"
                  class="btn card-cta fit-content"
                >
                  Login to preview scenarios
                </router-link>
              </span>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card">
        <b-row no-gutters class="card-inner align-items-center">
          <b-col md="6">
            <b-card-body title="36 hours access to the cluster">
              <b-card-text>
                The session is open for a total of 36 after you started it. The
                36 hours are time based and not usage based.<br /><br />
                When the session ends after 120 minutes you'll get access to the
                solutions. You'll still have 34 more hours (36 hours total)
                access to the cluster through the remote desktop.<br /><br />
                We decided to do this so you have enough time to play around
                with the environment and to study the scenarios/solutions
                properly.
                <br /><br /><br /><br />
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6" class="text-center">
            <div class="big-text" style="font-size: 5em;">
              36h
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card dark">
        <div class="background"></div>
        <b-row no-gutters class="card-inner">
          <b-col md="6">
            <b-card-img
              src="@/assets/screenshots/cks_terminal.png"
              class="rounded-0"
              style="padding: 50px;"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="The Remote Desktop">
              <b-card-text>
                Get used to the remote desktop and VNC connection before the
                real exam so you won't waste any precious time. Check for
                example how to copy&paste from Firefox into the Terminal.
                <br /><br />
                Time is rare during the exam and you have to be fast. There are
                some steps you can do in the beginning, like defining aliases or
                configuring vim. We have a section about that.<br /><br />
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card">
        <b-row no-gutters class="card-inner">
          <b-col md="6">
            <b-card-body title="Why taking the CKS?">
              <b-card-text>
                The CKS is a very important certification and the knowledge it
                brings is sought by many companies.<br /><br />

                The current time, where Kubernetes is the platform to run
                container based workloads and where the importance of secure
                infrastructure became top priority, led to the development of
                the CKS.<br /><br />

                The CKS can be considered hard and is based on existing CKA
                knowledge. You need to have a valid CKA certification if you
                want to attend the CKS.<br /><br />

                Like the other K8s exams this one is hands-on and practical,
                which means no memorization or multiple-choice questions. During
                the exam you can use the complete documentation.<br /><br />

                You’ll be provided with various scenarios for which you have 120
                minutes time to solve.

                <br /><br />
                For some tasks you have to work with an insecure setup and
                advance it further. For others you need to verify if the
                existing security is strong enough.
                <br /><br />

                You'll work for example with Apiserver-Config,
                AdmissionControllers, RBAC, ETCD-Secrets, Container-Level and
                Linux-Level security, OpenSource vulnerability scanners.
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6" style="padding-top:50px" class="text-center">
            <b-img
              src="@/assets/k8s_logo_dark.svg"
              fluid
              alt="hero-image"
              style="width:40%;margin-top:20px"
              class="image-center text-center"
            ></b-img>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card dark">
        <div class="background"></div>
        <b-row no-gutters class="card-inner">
          <b-col md="6" class="text-center">
            <p style="margin-top: 50px; text-align:left">
              <strong class="price-text">29.99</strong
              ><strong class="price-text">$</strong> <br />
            </p>
            <br /><br />
          </b-col>
          <b-col md="6">
            <b-card-body title="How much does this simulator cost?">
              <b-card-text class="text-center">
                <div>
                  <router-link
                    :to="{ name: 'Dashboard' }"
                    class="btn card-cta fit-content d-inline-block"
                    style="margin-top: 80px"
                  >
                    Enroll Now
                  </router-link>
                  <br /><br />
                </div>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card">
        <b-row no-gutters class="card-inner">
          <b-col md="6">
            <b-card-body title="More questions?">
              <b-card-text>
                Feel free to contact us at
                <router-link :to="{ name: 'Support' }">support</router-link>
                <br />
                <router-link
                  :to="{ name: 'Faq' }"
                  class="btn card-cta fit-content d-inline-block"
                  style="margin-top: 80px"
                >
                  FAQ
                </router-link>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6" class="text-center">
            <div class="big-text">?</div>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CksSimulatorContentList from '@/components/static/CksSimulatorContentList';

export default {
  name: 'CksSimulatorAbout',
  components: {
    CksSimulatorContentList,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'authentication/status/isAuthenticated',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';

.root {
  background-color: white;
  padding-bottom: 50px;
}

.content-listview {
  ul {
    list-style: none;

    li {
      padding-top: 20px;
    }
  }
}

.big-text {
  color: var(--secondary-color);
  font-size: 10rem;
  font-weight: bold;
  padding: 0;
}

.price-text {
  color: var(--secondary-color);
  font-size: 4rem;
  font-weight: bold;
  padding: 0;
}

.hero-text {
  font-size: 4rem;
}

.home-container {
  background-color: #fff;
  padding-bottom: 50px;
}

.text-highlight {
  color: var(--ckad-color-blue);
}

#hero-section {
  padding: 80px 0;
}

.cks-why {
  width: 70%;
}

.container {
  max-width: 100% !important;
  padding: 0 !important;
}

.cks-bg-img {
  background-image: url('~@/assets/ks_cks_bg.jpg');
  // background-position: 2% 65%;
  background-size: cover;
}

.card {
  border: 0 !important;
  padding: 100px 15px;
  background-color: transparent;
}

.card-inner {
  margin: 0 auto;
  max-width: 1140px;
  min-width: 1024px;
}

.home-card {
  margin: 100px 0;
  position: relative;

  .background {
    background-color: #adddff70;
    transform: skewY(-3deg);
    top: 50px;
    left: 0;
    right: 0;
    bottom: 50px;
    position: absolute;
  }

  &.dark {
    padding: 200px 0;
  }
}

@media screen and (max-width: 1024px) {
  .card-inner {
    min-width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .btn {
    width: 90%;
  }
  .cta-preview .btn {
    width: 100%;
  }
  .content-listview {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  .cks-why {
    width: 100%;
  }
  .card-inner {
    min-width: auto;
  }
}
</style>
