<template>
  <div class="wrapper">
    <h1>Status</h1>

    <br /><br />

    <span v-if="featureIsLoading">
      <Spinner></Spinner>
    </span>
    <span v-else>
      <p v-if="featureStatus()" v-html="featureStatus()"></p>
      <p v-else-if="areFeaturesDisabled()">
        Some actions across our platform are reduced atm, we're working on it.
      </p>
      <p v-else>
        There are no known issues to us atm, please contact
        <router-link :to="{ name: 'Support' }">
          support
        </router-link>
        if you need help.
      </p>
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/util/Spinner';

export default {
  components: {
    Spinner,
  },
  computed: {
    ...mapGetters({
      featureIsLoading: 'feature/list/isLoading',
      featureData: 'feature/list/data',
    }),
  },
  methods: {
    ...mapActions({
      getFeatures: 'feature/list/getFeatures',
    }),
    featureStatus() {
      let func = this.$store.getters['feature/list/featureByName'];
      if (func('status_message')) {
        if (func('status_message')['enabled']) {
          return func('status_message')['data'];
        }
      }
      return false;
    },
    areFeaturesDisabled() {
      if (this.featureData) {
        for (let i = 0; i < this.featureData.length; i++) {
          if (
            this.featureData[i]['name'] !== 'status_message' &&
            this.featureData[i]['enabled'] === false
          ) {
            return true;
          }
        }
        return false;
      }
    },
  },
  created() {
    this.getFeatures();
    this.areFeaturesDisabled();
  },
};
</script>
