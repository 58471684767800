<template>
  <!-- ################### also check ScorePublicViaClusterUuid.vue when making changes ################### -->
  <div>
    <div>
      <p v-if="error" class="alert alert-danger">
        An error occurred
      </p>
      <div v-else-if="isLoading" style="margin-top: 50px">
        <Spinner></Spinner>
      </div>
      <ScoreCheckmarks
        v-else-if="attendee"
        v-bind:attendee="this.attendee"
      ></ScoreCheckmarks>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ScoreCheckmarks from '@/components/attendee/score/ScoreCheckmarks';
import Spinner from '@/components/util/Spinner';

export default {
  components: {
    ScoreCheckmarks,
    Spinner,
  },
  data: function() {
    return {
      uuid: this.$route.params.uuid,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'attendee/list/isLoading',
      error: 'attendee/list/error',
      attendee: 'attendee/get/data',
    }),
  },
  methods: {
    getAttendee: function() {
      this.$store.dispatch('attendee/get/getAttendee', [this.uuid, true]);
    },
  },
  created() {
    this.getAttendee();
  },
};
</script>

<style scoped></style>
