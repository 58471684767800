<template>
  <div class="wrapper">
    <b-img
      src="@/assets/linux-foundation-logo.png"
      fluid
      style="width: 50%"
      center
    ></b-img>
    <h1 style="margin-top: 80px">Request Linux Foundation Access</h1>
    <div>
      <span v-if="this.error">
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>
        <br /><br />
      </span>
      <span v-else>
        <p>
          Please wait while we authenticate you
        </p>
        <br /><br />
        <Spinner></Spinner>
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/util/Spinner';
import VueCookies from 'vue-cookies';
import * as definitions from '@/store/modules/authentication/status/definitions';

export default {
  name: 'AccessRequestCreate',
  components: {
    Spinner,
  },
  data: function() {
    return {
      error: null,
    };
  },
  computed: mapGetters({
    methods: 'authentication/oauth/methods',
    isLoading: 'authentication/oauth/isLoading',
  }),
  methods: {
    ...mapActions({
      getMethods: 'authentication/oauth/getMethods',
    }),
  },
  created() {
    this.getMethods();
    let code = this.$route.query.code;
    let type = this.$route.query.type;
    let redirectUrl =
      '/linuxfoundation/access-request?code=' + code + '&type=' + type;
    VueCookies.set(
      definitions.COOKIE_NAME_AUTH_REDIRECT_URL,
      redirectUrl,
      '1d'
    );
  },
  watch: {
    methods(methods) {
      if (methods) {
        for (let i = 0; i < methods.length; i++) {
          if (methods[i]['provider'] === 'linuxfoundation') {
            let that = this;
            setTimeout(function() {
              let cookie = VueCookies.get(
                definitions.COOKIE_NAME_AUTH_REDIRECT_URL
              );
              if (!cookie) {
                that.error = 'Cookies need to be enabled.';
              } else {
                window.location = methods[i]['url'];
              }
            }, 1000);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
.code {
  padding: 10px;
  height: 50px;
  width: 400px;
}
.info-list {
  font-size: 0.9rem;
  margin: 0;
  list-style: none;
  background-color: #f3f0ff;
  padding: 10px;
  color: #464646;
  li {
    margin-bottom: 5px;
  }
}
</style>
