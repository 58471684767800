export const error = state => state.error;
export const isLoading = state => state.isLoading;
export const data = state => state.data;
export const featureByName = state => type => {
  if (state.error !== '' && type !== 'status_message') {
    return null;
  }
  if (state.data) {
    for (let i = 0; i < state.data.length; i++) {
      let item = state.data[i];
      if (item.name === type) {
        return item;
      }
    }
  }
};
