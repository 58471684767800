import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

const getConsents = ({ commit }) => {
  commit(types.TOGGLE_LOADING);

  fetch('consents')
    .then(response => response.json())
    .then(data => {
      var needsConsentSubmit = false;
      var needsTermsAndConditionsAccept = false;

      for (var i = 0; i < data['hydra:member'].length; i++) {
        var consent = data['hydra:member'][i];
        if (consent.choice === null) {
          needsConsentSubmit = true;
        }

        if (
          consent.type === 'type_terms_conditions' &&
          consent.choice !== true
        ) {
          needsTermsAndConditionsAccept = true;
        }
      }

      commit(types.SET_NEEDS_CONSENT_SUBMIT, needsConsentSubmit === true);
      commit(
        types.SET_NEEDS_TERMS_CONDITIONS_ACCEPT,
        needsTermsAndConditionsAccept === true
      );
      commit(types.TOGGLE_LOADING);
      commit(types.SET_DATA, data['hydra:member']);
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

export { getConsents };
