<template>
  <div>
    <div v-if="error" class="alert alert-danger">{{ error }}</div>

    <div v-if="user">
      <b-row class="row">
        <b-col>Email:</b-col>
        <b-col>{{ user.email }}</b-col>
      </b-row>

      <div>
        <b-row class="row">
          <b-col>Country:</b-col>
          <b-col>
            <country-select
              class="form-control"
              v-model="user.address.country"
              :country="user.address.country"
              v-bind:class="{ error: violations['address.country'] }"
            ></country-select>
          </b-col>
        </b-row>

        <b-row class="row" v-if="violations['address.country']">
          <b-col>
            <div class="alert alert-danger">
              {{ violations['address.country'] }}
            </div>
          </b-col>
        </b-row>

        <b-row class="row" v-if="user.address.country === 'US'">
          <b-col>Region:</b-col>
          <b-col>
            <region-select
              class="form-control"
              v-model="user.address.state"
              :country="user.address.country"
              :region="user.address.state"
              v-bind:class="{ error: violations['address.state'] }"
            />
          </b-col>
        </b-row>
        <b-row class="row" v-if="violations['address.state']">
          <b-col>
            <div class="alert alert-danger">
              {{ violations['address.state'] }}
            </div>
          </b-col>
        </b-row>

        <b-row class="row alert alert-light" style="background-color: #f6f6f6">
          <b-col>Generate Purchase Invoice:</b-col>
          <b-col
            ><b-form-checkbox
              v-model="user.generateInvoice"
              v-bind:class="{ error: violations['generateInvoice'] }"
          /></b-col>
        </b-row>

        <span v-if="user.generateInvoice">
          <b-row class="row">
            <b-col>First name: </b-col>
            <b-col
              ><b-form-input
                type="text"
                v-model="user.firstName"
                v-bind:class="{ error: violations['firstName'] }"
            /></b-col>
          </b-row>
          <b-row class="row" v-if="violations['firstName']">
            <b-col>
              <div class="alert alert-danger">
                {{ violations['firstName'] }}
              </div>
            </b-col>
          </b-row>

          <b-row class="row">
            <b-col>Last name:</b-col>
            <b-col
              ><b-form-input
                type="text"
                v-model="user.lastName"
                v-bind:class="{ error: violations['lastName'] }"
            /></b-col>
          </b-row>
          <b-row class="row" v-if="violations['lastName']">
            <b-col>
              <div class="alert alert-danger">
                {{ violations['lastName'] }}
              </div>
            </b-col>
          </b-row>

          <b-row class="row">
            <b-col>Address:</b-col>
            <b-col>
              <b-form-input
                v-model="user.address.address"
                v-bind:class="{ error: violations['address.address'] }"
            /></b-col>
          </b-row>
          <b-row class="row" v-if="violations['address.address']">
            <b-col>
              <div class="alert alert-danger">
                {{ violations['address.address'] }}
              </div>
            </b-col>
          </b-row>

          <b-row class="row">
            <b-col>City:</b-col>
            <b-col
              ><b-form-input
                v-model="user.address.city"
                v-bind:class="{ error: violations['address.city'] }"
            /></b-col>
          </b-row>
          <b-row class="row" v-if="violations['address.city']">
            <b-col>
              <div class="alert alert-danger">
                {{ violations['address.city'] }}
              </div>
            </b-col>
          </b-row>

          <b-row class="row">
            <b-col>Zip:</b-col>
            <b-col
              ><b-form-input
                v-model="user.address.zipcode"
                v-bind:class="{ error: violations['address.zipcode'] }"
            /></b-col>
          </b-row>
          <b-row class="row" v-if="violations['address.zipcode']">
            <b-col>
              <div class="alert alert-danger">
                {{ violations['address.zipcode'] }}
              </div>
            </b-col>
          </b-row>
        </span>

        <b-row
          class="row alert alert-light"
          v-if="user.generateInvoice"
          style="background-color: #f6f6f6"
        >
          <b-col>Company Invoice:</b-col>
          <b-col
            ><b-form-checkbox
              v-model="user.company"
              v-bind:class="{ error: violations['company'] }"
          /></b-col>
        </b-row>

        <span v-if="user.generateInvoice && user.company">
          <b-row class="row">
            <b-col>Company name:</b-col>
            <b-col
              ><b-form-input
                v-model="user.companyName"
                v-bind:class="{ error: violations['companyName'] }"
            /></b-col>
          </b-row>
          <b-row class="row" v-if="violations['companyName']">
            <b-col>
              <div class="alert alert-danger">
                {{ violations['companyName'] }}
              </div>
            </b-col>
          </b-row>

          <b-row class="row">
            <b-col>Company tax/vat ID:</b-col>
            <b-col
              ><b-form-input
                v-model="user.companyId"
                v-bind:class="{ error: violations['companyId'] }"
            /></b-col>
          </b-row>
          <b-row class="row" v-if="violations['companyId']">
            <b-col>
              <div class="alert alert-danger">
                {{ violations['companyId'] }}
              </div>
            </b-col>
          </b-row>
        </span>
      </div>

      <div class="action-links">
        <spinner v-if="isLoading"></spinner>
        <span v-else>
          <div class="row">
            <div class="col-4 text-center">
              <UserDelete v-if="showDelete"></UserDelete>
            </div>
            <div class="col-4 text-center">
              <router-link
                v-if="showConsents"
                :to="{ name: 'UserConsent' }"
                class="btn mt-4"
              >
                Consents
              </router-link>
            </div>
            <div class="col-4 text-center">
              <button
                v-on:click="save()"
                class="mt-4 btn card-cta-primary pull-right"
              >
                Save
              </button>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Spinner from '@/components/util/Spinner';
import UserDelete from '@/components/user/UserDelete';

export default {
  name: 'UserProfileEdit',
  components: { Spinner, UserDelete },
  data: function() {
    return {
      user: null,
    };
  },
  props: {
    userPassed: {
      required: true,
      type: Object,
    },
    showDelete: {
      required: false,
      type: Boolean,
      default: false,
    },
    showConsents: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      edit: 'user/edit/edit',
    }),
    save: function() {
      if (this.user.address.country !== 'US') {
        this.user.address.state = null;
      }
      if (!this.user.generateInvoice) {
        this.user.company = false;
        this.user.firstName = null;
        this.user.lastName = null;
        this.user.address.address = null;
        this.user.address.city = null;
        this.user.address.zipcode = null;
      }
      if (!this.user.company) {
        this.user.companyName = null;
        this.user.companyId = null;
      }
      this.edit(this.user);
    },
  },
  computed: mapGetters({
    error: 'user/edit/error',
    isLoading: 'user/edit/isLoading',
    violations: 'user/edit/violations',
  }),
  created: function() {
    this.$store.dispatch('user/edit/clearError');
    this.user = JSON.parse(JSON.stringify(this.userPassed));
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
.row {
  margin-bottom: 14px;
}
.error {
  border: 1px solid #da6d78;
}
</style>
