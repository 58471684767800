<template>
  <div>
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <div
          v-for="course in courses"
          v-bind:key="course.type"
          v-if="course.type === 'cks_simulator'"
          style="width: 150px; margin: 0 auto"
        >
          <router-link
            :to="{ name: 'CoursePreview', params: { uuid: course.uuid } }"
            class="btn card-cta fit-content"
          >
            <span v-if="course.type === 'cks_simulator'">
              CKS Preview
            </span>
          </router-link>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div
          v-for="course in courses"
          v-bind:key="course.type"
          v-if="course.type === 'cka_simulator'"
          style="width: 150px; margin: 0 auto"
        >
          <router-link
            :to="{ name: 'CoursePreview', params: { uuid: course.uuid } }"
            class="btn card-cta fit-content"
          >
            <span v-if="course.type === 'cka_simulator'">
              CKA Preview
            </span>
          </router-link>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div
          v-for="course in courses"
          v-bind:key="course.type"
          v-if="course.type === 'ckad_simulator'"
          style="width: 150px; margin: 0 auto"
        >
          <router-link
            :to="{ name: 'CoursePreview', params: { uuid: course.uuid } }"
            class="btn card-cta fit-content"
          >
            <span v-if="course.type === 'ckad_simulator'">
              CKAD Preview
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DashboardPreviewButtons',
  computed: {
    ...mapGetters({
      isLoading: 'course/list/isLoading',
      courses: 'course/list/data',
    }),
  },
  methods: {
    ...mapActions({
      getCourses: 'course/list/getCourses',
    }),
  },
  mounted: function() {
    this.getCourses();
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';
</style>
