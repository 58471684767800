import * as types from './mutation_types';

const reset = ({ commit }) => {
  commit(types.SET_INACTIVITY_LOG_ACTION, new Date().getTime());
  commit(types.SET_INACTIVITY_CONFIRMATION_VISIBLE, false);
  commit(types.SET_INACTIVITY_CONFIRMATION_VISIBLE_HIDE_IFRAME, false);
  commit(types.SET_INSTRUCTION_SHOW, false);
  commit(types.SET_NOTEPAD_SHOW, false);
  commit(types.SET_CHAT_SHOW, false);
};

const inactivityLogActionAction = ({ commit }) => {
  commit(types.SET_INACTIVITY_LOG_ACTION, new Date().getTime());
};

const inactivityConfirmationVisible = ({ commit }, data) => {
  commit(types.SET_INACTIVITY_CONFIRMATION_VISIBLE, data);
};

const inactivityConfirmationVisibleHideIframe = ({ commit }, data) => {
  commit(types.SET_INACTIVITY_CONFIRMATION_VISIBLE_HIDE_IFRAME, data);
};

const instructionShowToggle = ({ commit, state }) => {
  commit(types.SET_INSTRUCTION_SHOW, !state.instructionShow);
};

const notepadShowToggle = ({ commit, state }) => {
  commit(types.SET_NOTEPAD_SHOW, !state.notepadShow);
};

const chatShowToggle = ({ commit, state }) => {
  commit(types.SET_CHAT_SHOW, !state.chatShow);
};

const setAttendeeTerminalAddresses = ({ commit }, data) => {
  commit(types.SET_ATTENDEE_TERMINAL_ADDRESSES, data);
};

const setAttendeeTerminalAddressPos = ({ commit }, data) => {
  commit(types.SET_ATTENDEE_TERMINAL_ADDRESS_POS, data);
};

const setQuestionIndex = ({ commit }, data) => {
  commit(types.SET_QUESTION_INDEX, data);
};

const setQuestionSelectionEnabled = ({ commit }, data) => {
  commit(types.SET_QUESTION_SELECTION_ENABLED, data);
};

export {
  reset,
  inactivityLogActionAction,
  inactivityConfirmationVisible,
  inactivityConfirmationVisibleHideIframe,
  instructionShowToggle,
  notepadShowToggle,
  chatShowToggle,
  setAttendeeTerminalAddresses,
  setAttendeeTerminalAddressPos,
  setQuestionIndex,
  setQuestionSelectionEnabled,
};
