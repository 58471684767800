import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

const getUserObject = ({ commit }) => {
  commit(types.TOGGLE_LOADING);

  fetch('users')
    .then(response => response.json())
    .then(data => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_DATA, data['hydra:member'][0]);
      commit(types.SET_DID_LOAD_ONCE, true);
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
      commit(types.SET_DID_LOAD_ONCE, true);
    });
};

export { getUserObject };
