<template>
  <ul class="list-summarize" v-bind:class="className">
    <li>
      Simulated LFCT environment with access to your own environment
    </li>
    <li>
      <strong>Two LFCT test sessions with identical content/questions</strong>
      ( <router-link :to="{ name: 'Faq' }">why?</router-link> )
    </li>
    <li>Test interface that's really close to the original one</li>
    <li><strong>20 complex questions/scenarios</strong> to solve</li>
    <li>
      120 minutes countdown for testing your knowledge under pressure
    </li>
    <li>
      <strong>Environment is open for the next 36 hours</strong> after
      activation, time and not usage based (
      <router-link :to="{ name: 'Faq' }">more</router-link> )
    </li>
    <li>
      Each question will be solved in its own encapsulated virtual machine
    </li>
    <li>
      <strong>
        ~30 pages material in total (questions and solutions)
      </strong>
    </li>
    <li>
      Start your test sessions independently of each other during one year
    </li>
    <li>
      <strong>
        You'll get an automatically calculated score
      </strong>
    </li>
    <li>
      <font-awesome-icon :icon="['fab', 'slack']" size="1x" class="mr-3" />
      Access to private Slack community
    </li>
  </ul>
</template>

<script>
export default {
  name: 'LfctSimulatorContentList',
  props: {
    className: {
      type: String,
      default: 'px20',
    },
    courseUuid: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
.list-summarize {
  list-style: none;
}
.px15 {
  li {
    padding-top: 15px;
  }
}
.px20 {
  li {
    padding-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
</style>
