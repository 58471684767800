<template>
  <div
    class="container-fluid"
    id="page-container"
    v-bind:class="appTemplateName"
  >
    <flash-message class="flash-message"></flash-message>

    <div id="content-wrap">
      <nav>
        <b-navbar toggleable="md" type="dark" variant="info">
          <b-navbar-brand href="#">
            <router-link :to="{ name: 'Home' }">
              <img src="./assets/kllr_shll_logo.svg" alt="logo" height="40px" />
            </router-link>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse
            v-if="authJwtRefreshTokenExists || !isAuthenticating"
            id="nav-collapse"
            is-nav
          >
            <b-navbar-nav class="ml-auto">
              <template v-if="authJwtRefreshTokenExists">
                <b-nav-item
                  href="/slack"
                  style="padding-top: 5px"
                  v-if="isFullMember()"
                >
                  <font-awesome-icon
                    :icon="['fab', 'slack']"
                    size="1x"
                    class="mr-3 pull-left"
                  />
                </b-nav-item>

                <b-nav-item>
                  <router-link :to="{ name: 'Dashboard' }">
                    Dashboard
                  </router-link>
                </b-nav-item>

                <b-nav-item>
                  <router-link :to="{ name: 'UserProfile' }">
                    Account
                  </router-link>
                </b-nav-item>

                <b-nav-item>
                  <router-link :to="{ name: 'Support' }">
                    Support
                  </router-link>
                </b-nav-item>

                <b-nav-item>
                  <router-link :to="{ name: 'OrderSelectCreate' }">
                    Store
                  </router-link>
                </b-nav-item>

                <b-nav-item>
                  <router-link :to="{ name: 'Logout' }">
                    Logout
                  </router-link>
                </b-nav-item>
              </template>
              <template v-else>
                <b-nav-item>
                  <router-link :to="{ name: 'CksSimulatorAbout' }">
                    CKS
                  </router-link>
                </b-nav-item>

                <b-nav-item>
                  <router-link :to="{ name: 'CkaSimulatorAbout' }">
                    CKA
                  </router-link>
                </b-nav-item>

                <b-nav-item>
                  <router-link :to="{ name: 'CkadSimulatorAbout' }">
                    CKAD
                  </router-link>
                </b-nav-item>

                <b-nav-item>
                  <router-link :to="{ name: 'LfcsSimulatorAbout' }">
                    LFCS
                  </router-link>
                </b-nav-item>

                <b-nav-item>
                  <router-link :to="{ name: 'LfctSimulatorAbout' }">
                    LFCT
                  </router-link>
                </b-nav-item>

                <b-nav-item>
                  <router-link :to="{ name: 'Faq' }">
                    Faq
                  </router-link>
                </b-nav-item>

                <b-nav-item>
                  <router-link :to="{ name: 'Pricing' }">
                    Pricing
                  </router-link>
                </b-nav-item>

                <b-nav-item>
                  <router-link :to="{ name: 'Login' }">
                    Login
                  </router-link>
                </b-nav-item>
              </template>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </nav>

      <div v-if="showAppContentReachedTrue">
        <Maintenance v-if="statusMaintenanceShowMessage"></Maintenance>
        <router-view v-else></router-view>
      </div>
    </div>

    <footer id="footer" class="py-5" style="margin-top: 10rem !important;">
      <b-container class="mx-auto">
        <b-row class="d-flex justify-content-center">
          <b-col md="4" class="" sm="12">
            <router-link :to="{ name: 'Home' }">
              <img src="@/assets/kllr_shll_logo.svg" alt="logo" width="90px" />
            </router-link>

            <router-link :to="{ name: 'Home' }">
              <p class="mt-5 small">About</p>
            </router-link>

            <router-link :to="{ name: 'Faq' }">
              <p class="small">FAQ</p>
            </router-link>

            <router-link :to="{ name: 'Support' }">
              <p class="small">Support</p>
            </router-link>

            <router-link :to="{ name: 'OrderSelectCreate' }">
              <p class="small">Store</p>
            </router-link>

            <router-link :to="{ name: 'Pricing' }">
              <p class="small">Pricing</p>
            </router-link>

            <router-link :to="{ name: 'Legal' }">
              <p class="small">Legal / Privacy</p>
            </router-link>
          </b-col>

          <b-col md="4" class="" sm="12">
            <p class="mt-3">CONTENT</p>

            <router-link :to="{ name: 'CksSimulatorAbout' }">
              <p class="mt-5 small">CKS</p>
            </router-link>

            <router-link :to="{ name: 'CkaSimulatorAbout' }">
              <p class="small">CKA</p>
            </router-link>

            <router-link :to="{ name: 'CkadSimulatorAbout' }">
              <p class="small">CKAD</p>
            </router-link>

            <router-link :to="{ name: 'LfcsSimulatorAbout' }">
              <p class="small">LFCS</p>
            </router-link>

            <router-link :to="{ name: 'LfctSimulatorAbout' }">
              <p class="small">LFCT</p>
            </router-link>
          </b-col>

          <b-col md="4" class="" sm="12">
            <p class="mt-3">LINKS</p>

            <p class="mt-5">
              <a href="https://killercoda.com" target="_blank" class="small"
                >Killercoda</a
              >
            </p>

            <p>
              <a
                href="https://www.linkedin.com/in/kimwuestkamp"
                target="_blank"
                class="small"
                >Kim Wuestkamp</a
              >
            </p>

            <p>
              <a
                href="https://twitter.com/_killer_shell"
                target="_blank"
                class="small"
              >
                <font-awesome-icon
                  :icon="['fab', 'twitter']"
                  size="lg"
                  class="mr-3"
                />
              </a>
            </p>

            <p>
              <a
                href="https://www.linkedin.com/company/killer-shell"
                target="_blank"
                class="small"
              >
                <font-awesome-icon
                  :icon="['fab', 'linkedin']"
                  size="lg"
                  class="mr-3"
                />
              </a>
            </p>

            <p>
              <router-link :to="{ name: 'Slack' }" class="small">
                <font-awesome-icon
                  :icon="['fab', 'slack']"
                  size="lg"
                  class="mr-3"
                />
              </router-link>
            </p>
          </b-col>
        </b-row>
      </b-container>
    </footer>

    <vue-cookie-accept-decline
      :ref="'myPanel1'"
      :elementId="'myPanel1'"
      :debug="false"
      :position="'bottom-left'"
      :type="'floating'"
      :disableDecline="true"
      :transitionName="'slideFromBottom'"
      :showPostponeButton="false"
    >
      <!-- Optional -->
      <div slot="message">
        We use cookies to ensure you get the best experience on our website.
      </div>

      <!-- Optional -->
      <div slot="acceptContent">
        OKAY
      </div>
    </vue-cookie-accept-decline>
    <CodeCopy></CodeCopy>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Maintenance from '@/components/static/Maintenance';
import CodeCopy from '@/components/util/CodeCopy';

export default {
  components: {
    CodeCopy,
    Maintenance,
  },
  data: function() {
    return {
      showAppContentReachedTrue: false,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'authentication/status/isAuthenticated',
      isAuthenticating: 'authentication/status/isAuthenticating',
      authJwtRefreshTokenExists: 'authentication/status/jwtRefreshTokenExists',
      appTemplateName: 'app/template/name',
      userListDidLoadOnce: 'user/list/didLoadOnce',
      user: 'user/list/data',
      userIsLoading: 'user/list/isLoading',
      statusMaintenanceShowMessage: 'status/maintenance/showMessage',
    }),
    showAppContent: function() {
      if (this.userListDidLoadOnce) {
        return true;
      }

      if (this.isAuthenticating) {
        return false;
      }

      if (!this.isAuthenticated) {
        return true;
      }

      return false;
    },
  },
  mounted: function() {
    this.$store.dispatch('authentication/status/readRefreshJwtTokenFromCookie');
    this.$store.dispatch('authentication/status/refreshJwtToken');
  },
  watch: {
    showAppContent: function(newVal) {
      if (newVal === true) {
        this.showAppContentReachedTrue = true;
      }
    },
  },
  methods: {
    isFullMember: function() {
      return this.user && this.user.fullMember;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/_global.scss';

.template-no-header-no-footer {
  nav,
  footer {
    display: none !important;
  }
}

.template-attendee-run {
  nav,
  footer {
    display: none !important;
  }

  #content-wrap {
    padding-bottom: 0 !important;
    height: 99.5vh;

    & > div {
      height: inherit !important;

      & > div {
        height: inherit !important;

        & > div {
          height: inherit !important;

          & > div {
            height: inherit !important;

            & > div {
              height: inherit !important;

              & > div {
                height: inherit !important;

                & > div {
                  height: inherit !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

:root {
  --primary-color: #de6161;
  --secondary-color: #3f3d56;
  --gradient-color: #c68493;
  --body-color: #555;
  --first-accent: #eeecf4;
  --second-accent: #f6d3d3;
  --ckad-color-blue: #407af4;
}

html,
body {
  height: 100%;
  width: 100%;
  color: var(--body-color) !important;
  font-family: 'Open Sans', sans-serif !important;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

nav {
  background: linear-gradient(
    to right,
    var(--secondary-color),
    var(--secondary-color),
    var(--secondary-color)
  );
}

footer,
footer a,
nav a {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-dark .navbar-nav .nav-link {
  color: rgb(237 227 231) !important;
}

footer,
footer a,
nav a:hover {
  color: white !important;
  text-decoration: none !important;
}

nav a:hover {
  color: #fff;
  text-decoration: none;
}

.kubernetes-exam-simulator {
  color: #ffffff;
  font-family: 'Source Code Pro', sans-serif !important;
  font-size: larger;
  margin-left: 2rem;
}

footer span {
  display: inline-block;
  padding: 15px;
}

.imprint {
  text-align: right;
}

.container-fluid {
  padding: 0 !important;
}

.flash-message {
  position: absolute;
  right: 30px;
  bottom: 60px;
  z-index: 99999;
}

.flash__message.info {
  background-color: #3f3d56 !important;
  color: white !important;
  border-radius: 0;
}

.flash__message.error {
  border-radius: 0;
}

#content-wrap {
  padding-bottom: 3.5rem;
  min-height: 100vh;
}

.dg-container,
.dg-backdrop {
  z-index: 99999 !important;
}

#footer {
  background-color: #3f3d56;
  margin-top: auto;
}

#page-container {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

@media screen and (max-width: 700px) {
  .kubernetes-exam-simulator {
    display: none;
  }
  #content-wrap {
    padding-bottom: 18rem;
  }
  footer span {
    padding: 5px;
  }
}
</style>
