<template>
  <div class="wrapper">
    <h1>User Profile</h1>
    <div>
      <div style="margin-top: 30px"></div>
      <AccountEditForm
        v-if="user"
        v-bind:userPassed="user"
        v-bind:showDelete="true"
        v-bind:showConsents="true"
      ></AccountEditForm>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccountEditForm from '@/components/user/AccountEditForm';

export default {
  components: {
    AccountEditForm,
  },
  computed: mapGetters({
    user: 'user/list/data',
    isLoading: 'user/list/isLoading',
  }),
  created() {
    this.$store.dispatch('user/list/getUserObject');
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';
</style>
