<template>
  <div class="wrapper" style="padding-top: 0px">
    <div v-if="userIsLoading">
      <Spinner></Spinner>
    </div>
    <div v-else>
      <br />
      <br />

      <br />
      <br />

      <h1>Get Access</h1>

      <ul style="list-style-type: decimal">
        <li style="padding: 10px">
          Log into
          <a href="https://killercoda.com/login" target="_blank">Killercoda</a>
        </li>
        <li style="padding: 10px">
          Run any of the scenarios for
          <a href="https://killercoda.com/killer-shell-cks" target="_blank"
            >CKS</a
          >
          or
          <a href="https://killercoda.com/killer-shell-cka" target="_blank"
            >CKA</a
          >
          or
          <a href="https://killercoda.com/killer-shell-ckad" target="_blank"
            >CKAD</a
          >
          or
          <a href="https://killercoda.com/lfcs" target="_blank">LFCS</a>
        </li>
      </ul>

      <div style="clear: both"></div>

      <br />
      <br />

      <h1>Info</h1>
      <br />

      <h2 class="display-4 font-weight-bold text-dark">
        Killercoda vs Killer Shell?
      </h2>
      <p>
        The same team works behind both platforms, but both have different use
        cases.
      </p>

      <h2 class="mt-5">
        Killer Shell<small> - Simulate Exam Experience</small>
      </h2>

      <br />

      <ul class="mb-5">
        <li>
          Provides realistic simulators for the CKS, CKA, CKAD, LFCS and LFCT
          exams
        </li>
        <li>
          The simulators come included with every Linux Foundation exam purchase
        </li>
        <li>You have limited 36hrs access to a simulator environment</li>
        <li>You get an automated score at the end</li>
        <li>Very similar user interface to real exams</li>
        <li>
          Read more in the
          <router-link :to="{ name: 'Faq' }">FAQ</router-link>
        </li>
      </ul>

      <h2 class="mt-5">Killercoda<small> - Study Exam Topics</small></h2>

      <br />

      <ul class="mb-5">
        <li>
          Provides disposable interactive learning environments, aka scenarios
        </li>
        <li>
          Start and use scenarios as much as you want
        </li>
        <li>Study all exam topics just in your browser</li>
        <li>Optional subscription plan for more features</li>
        <li>No score, but solution provided</li>
        <li>
          Read more in the
          <a href="https://killercoda.com/faq" target="_blank">FAQ</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '@/components/util/Spinner';

export default {
  components: { Spinner },
  computed: {
    ...mapGetters({
      user: 'user/list/data',
      userIsLoading: 'user/list/isLoading',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
.spinner {
  margin: 0 auto;
  width: 60px;
}
h1 {
  background-color: var(--secondary-color);
  color: white !important;
  text-align: center;
  padding: 5px;
  font-size: 0.9rem !important;
}
.killercoda-section {
  position: relative;
  border: 3px solid #000;
  padding-bottom: 5px;
  background-color: black;
  color: white;
  cursor: pointer;
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: #343a40;
  }
  .killercoda-list {
    font-size: 0.9rem;
    margin: 0;
    padding: 10px 20px 20px 20px;
    li {
      margin-bottom: 5px;
    }

    li::marker {
      content: '';
    }
  }
}
</style>
