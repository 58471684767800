export const inactivityLastActionAt = state => state.inactivityLastActionAt;
export const inactivityConfirmationVisible = state =>
  state.inactivityConfirmationVisible;
export const inactivityConfirmationVisibleHideIframe = state =>
  state.inactivityConfirmationVisibleHideIframe;
export const instructionShow = state => state.instructionShow;
export const notepadShow = state => state.notepadShow;
export const chatShow = state => state.chatShow;
export const attendeeTerminalAddresses = state =>
  state.attendeeTerminalAddresses;
export const attendeeTerminalAddress = state =>
  state.attendeeTerminalAddresses[state.attendeeTerminalAddressPos];
export const questionIndex = state => state.questionIndex;
export const questionSelectionEnabled = state => state.questionSelectionEnabled;
