<template>
  <div>
    <DraggableDiv :draggable="false">
      <template slot="header">
        Chat
        <span class="pull-right close" v-on:click="close()">
          <font-awesome-icon icon="times-circle" size="1x" />
        </span>
      </template>
      <template slot="main">
        <div class="messages">
          <div v-for="(message, i) in messages" :key="i" class="message">
            <b-row v-if="message.user === 'Proctor'">
              <b-col cols="4">
                <strong>{{ message.user }}</strong>
              </b-col>
              <b-col>
                {{ message.msg }}
              </b-col>
            </b-row>
            <b-row v-if="message.user === 'Attendee'">
              <b-col>
                <span class="pull-right">
                  {{ message.msg }}
                </span>
              </b-col>
              <b-col cols="4" style="text-align: right">
                <strong>{{ message.user }}</strong>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="message-send">
          <b-row>
            <b-col cols="8">
              <input
                type="text"
                v-model="input"
                v-on:keyup.enter="attendeeSendMessage()"
              />
            </b-col>
            <b-col>
              <button v-on:click="attendeeSendMessage()">Send</button>
            </b-col>
          </b-row>
        </div>
      </template>
    </DraggableDiv>
  </div>
</template>

<script>
import DraggableDiv from '@/components/util/Draggable';

export default {
  name: 'Chat',
  components: {
    DraggableDiv,
  },
  data: function() {
    return {
      input: '',
      messages: [
        {
          user: 'Proctor',
          msg:
            'Hellou! I\'m your simulated proctor. People call me "not real", because apparently I\'m "not human".\nBut I hope to become one in the near future!',
        },
        {
          user: 'Proctor',
          msg:
            "Anyways, I'm a highly advanced AI and much more intelligent than anything that has ever been recorded.",
        },
        {
          user: 'Proctor',
          msg:
            'Still, all I can do is to tell you how much time is left or maybe a joke. But I do this very well!',
        },
        { user: 'Proctor', msg: 'Best luck with your simulated exam!' },
      ],
      wisdom: [
        "You can solve all problems using Kubernetes, that you didn't have before Kubernetes",
        "If you can't fix it with Kubernetes, then you're not using enough Kubernetes",
        "Which OS I'm running on my phone? Kubernetes. But I can't call anyone because I created no service",
        "There are only two types of people. The ones that love Kubernetes, and the ones I don't talk to",
        "Your computer doesn't boot? Have you tried Kubernetes?",
        "I run my simple blog on managed Kubernetes. Had to sell my car but hell it's worth it!",
        'Explain DevOps in one sentence? "Bla bla bla Kubernetes"',
        'One thing I would change about Kubernetes? More Kubernetes!',
        'My new favorite programming language is YAML.',
        "One time I explained Kubernetes to my friend. Afterwards we both didn't understand it",
        "I run my database on Kubernetes. Aaaaannndd it's gone",
        'Since Kubernetes, we always deploy on Friday afternoons. We also have to work on weekends though...',
        'I just installed Kubernetes. How can I deploy via FTP?',
        'I put my broken app into a pod, now everything magically works',
        "If scaling up your application didn't solve the issue, then you didn't scale up enough",
        'Spending 5 hours trying to fix some YAML can save you 3 minutes of reading the documentation',
        'Why have one easy monolithic api if you can split it up into 500 microservices running on Kubernetes',
        'You do sports? Yeah, I do runtimes every day',
      ],
    };
  },
  props: {
    attendee: {
      required: true,
    },
  },
  methods: {
    close: function() {
      this.$store.dispatch('attendee/run/chatShowToggle');
    },
    attendeeSendMessage: function() {
      if (this.input.trim() === '') {
        return;
      }
      this.createNewChatMessage('Attendee', this.input);
      this.proctorAnswerOnMessage(this.input);
      let that = this;
      setTimeout(function() {
        that.scrollChatToBottom();
      }, 50);
      this.input = '';
    },
    scrollChatToBottom: function() {
      let element = document.getElementsByClassName('messages')[0];
      element.scrollTop = element.scrollHeight;
    },
    proctorAnswerOnMessage: function(message) {
      let that = this;
      setTimeout(function() {
        if (
          message.toLowerCase().includes('k8s') ||
          message.toLowerCase().includes('kubernetes') ||
          message.toLowerCase().includes('joke')
        ) {
          that.createNewChatMessage('Proctor', that.getRandomProctorWisdom());
        } else if (
          message.toLowerCase().includes('time') ||
          message.toLowerCase().includes('minutes') ||
          message.toLowerCase().includes('countdown')
        ) {
          that.createNewChatMessage('Proctor', that.getProctorTimeMessage());
        } else if (
          message.toLowerCase().includes('hi') ||
          message.toLowerCase().includes('hello') ||
          message.toLowerCase().includes('hallo') ||
          message.toLowerCase().includes('hey')
        ) {
          that.createNewChatMessage('Proctor', 'Hellou!');
        } else {
          that.createNewChatMessage('Proctor', "Sorry, I didn't get that");
        }
        setTimeout(function() {
          that.scrollChatToBottom();
        }, 50);
      }, 1000);
    },
    createNewChatMessage: function(user, msg) {
      this.messages.push({
        user: user,
        msg: msg,
      });
    },
    getRandomProctorWisdom: function() {
      if (this.wisdom.length < 1) {
        return '--- brain shutdown. need to reboot Kubernetes ---';
      }
      let pos = Math.floor(Math.random() * this.wisdom.length);
      let wisdom = this.wisdom[pos];
      this.wisdom.splice(pos, 1);
      return wisdom;
    },
    getProctorTimeMessage: function() {
      let time = this.attendee.sessionMinutesLeft;
      if (time === null) {
        return 'Once the session is started I can tell you the time left';
      }
      return (
        'The time is displayed above the questions as a progress bar. You have ' +
        time +
        ' minutes left'
      );
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';

.message-send {
  position: absolute;
  bottom: 0;
  right: 20px;
  left: 20px;
  height: 50px;
  background-color: white;
  input,
  button {
    width: 100%;
    border: 1px solid #aebbcb;
    border-radius: 0;
    outline: none;
  }
}

.messages {
  padding: 10px 10px 50px 10px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  bottom: 50px;
  right: 0;
  left: 0;
  top: 35px;
  .message {
    margin-bottom: 30px;
    overflow-wrap: anywhere;
  }
}

::v-deep .resize-icon {
  display: none;
}

::v-deep .draggable-container {
  right: 10px;
  top: 50vh;
  bottom: 10px;
  height: revert;
  width: 400px;
  min-height: revert;
  min-width: revert;
  max-height: revert;
  max-width: revert;
  background-color: white;
  resize: none;
}

::v-deep .draggable-container .draggable-main {
  overflow: hidden;
}

::v-deep .draggable-container .draggable-header {
  border-top: 1px solid #1c7396;
  border-left: 1px solid #1c7396;
  border-right: 1px solid #1c7396;
  cursor: default;
}

::v-deep .draggable-container .draggable-header .close {
  cursor: pointer;
  margin-right: -10px;
}
</style>
