<template>
  <div
    class="attendee-run"
    v-on:mousemove="moveMovement"
    v-on:keydown="keyDown"
  >
    <Header v-bind:attendee="attendee"></Header>
    <Split :style="getWrapperStyle()">
      <SplitArea :size="30" :minSize="250">
        <Questions
          v-if="!shouldWeWaitForAttendeeData()"
          v-bind:attendee="attendee"
        ></Questions>
        <span v-else>
          <br /><br />
          <Spinner></Spinner>
        </span>
      </SplitArea>
      <SplitArea :size="70">
        <Terminal v-bind:attendee="attendee"></Terminal>
      </SplitArea>
    </Split>
    <Poll
      v-if="!inactivityConfirmationVisible"
      v-bind:attendee="attendee"
    ></Poll>
    <Inactive v-if="terminalActive" v-bind:attendee="attendee"></Inactive>
    <Instructions
      v-if="instructionShow"
      v-bind:attendee="attendee"
    ></Instructions>
    <Chat v-bind:class="chatClass()" v-bind:attendee="attendee"></Chat>
    <Notepad v-bind:class="notepadClass()" v-bind:attendee="attendee"></Notepad>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as appTemplateMutationTypes from '@/store/modules/app/template/mutation_types';
import Spinner from '@/components/util/Spinner';
import Poll from '@/components/attendee/run/Poll';
import Inactive from '@/components/attendee/run/Inactive';
import Questions from '@/components/attendee/run/Questions';
import Terminal from '@/components/attendee/run/Terminal';
import Header from '@/components/attendee/run/Header';
import Instructions from '@/components/attendee/run/Instructions';
import Notepad from '@/components/attendee/run/Notepad';
import Chat from '@/components/attendee/run/Chat';

export default {
  name: 'AttendeeRun',
  components: {
    Spinner,
    Poll,
    Inactive,
    Questions,
    Terminal,
    Header,
    Instructions,
    Notepad,
    Chat,
  },
  data: function() {
    return {
      attendeeDataDidLoadAtLeastOnce: false,
    };
  },
  mounted: function() {
    this.$store.commit(
      'app/template/' + appTemplateMutationTypes.SET_NAME,
      'template-attendee-run'
    );
    this.$store.dispatch('attendee/run/reset');
  },
  props: {
    attendee: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      attendeeGetIsLoading: 'attendee/get/isLoading',
      inactivityConfirmationVisible:
        'attendee/run/inactivityConfirmationVisible',
      instructionShow: 'attendee/run/instructionShow',
      chatShow: 'attendee/run/chatShow',
      notepadShow: 'attendee/run/notepadShow',
    }),
    terminalActive: function() {
      return this.attendee.terminal.status === 'active';
    },
    sessionStarted: function() {
      return this.attendee.sessionStarted === true;
    },
  },
  watch: {
    attendeeDataDidLoadAtLeastOnce(newValue) {
      if (newValue && !this.sessionStarted) {
        this.$store.dispatch('attendee/run/instructionShowToggle');
      }
    },
    attendeeGetIsLoading(newValue) {
      if (!newValue) {
        this.attendeeDataDidLoadAtLeastOnce = true;
      }
    },
  },
  methods: {
    ...mapActions({
      getFeatures: 'feature/list/getFeatures',
    }),
    notepadClass: function() {
      if (!this.notepadShow) {
        return 'd-none';
      } else {
        return '';
      }
    },
    chatClass: function() {
      if (!this.chatShow) {
        return 'd-none';
      } else {
        return '';
      }
    },
    shouldWeWaitForAttendeeData: function() {
      return !this.attendeeDataDidLoadAtLeastOnce;
    },
    moveMovement: function() {
      this.$store.dispatch('attendee/run/inactivityLogActionAction');
    },
    keyDown: function() {
      this.$store.dispatch('attendee/run/inactivityLogActionAction');
    },
    getWrapperStyle: function() {
      let height = window.innerHeight - 40;
      return 'height: ' + height + 'px' + ' !important';
    },
  },
  beforeDestroy: function() {
    this.$store.commit('app/template/' + appTemplateMutationTypes.SET_NAME, '');
  },
  created() {
    this.getFeatures();
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';

.attendee-run {
  background-color: white;

  .spring-spinner {
    margin: 0 auto;
  }

  .notepad-button {
    position: absolute;
    background-color: white;
    right: 0;
    top: 0;
    width: 35px;
    height: 35px;
    text-align: center;
    font-size: 1.3em;
    cursor: pointer;
    z-index: 8889;
  }
}
</style>
