<template>
  <div class="wrapper">
    <h1>User Consent</h1>
    <div>
      <p v-if="termsAcceptNecessary" class="alert alert-info">
        Please read and accept our terms&conditions before continuing
      </p>
      <div style="margin-top: 50px"></div>
      <UserConsentEdit
        v-if="!isLoading && consents"
        v-bind:consentsPassed="consents"
      ></UserConsentEdit>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserConsentEdit from '@/components/user/UserConsentEdit';

export default {
  components: {
    UserConsentEdit,
  },
  computed: mapGetters({
    consents: 'consent/list/data',
    isLoading: 'consent/list/isLoading',
  }),
  props: {
    termsAcceptNecessary: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  created() {
    this.$store.dispatch('consent/list/getConsents');
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';
</style>
