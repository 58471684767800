import VueCookies from 'vue-cookies';
import * as definitions from './definitions';

export const error = state => state.error;
export const isLoading = state => state.isLoading;
export const data = state => state.data;
export const methods = state => state.data['methods'];
export const registrationEnabled = state => state.data['registrationEnabled'];
export const stateLocal = () => {
  let val = VueCookies.get(definitions.COOKIE_NAME_AUTH_OAUTH_STATE);
  if (val) {
    return val;
  }
};
