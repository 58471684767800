<template>
  <div>
    <div>
      <div v-if="show">
        <div v-if="isLoading">
          <Spinner></Spinner>
        </div>
        <div v-else>
          <div v-if="error" class="alert alert-danger">{{ error }}</div>
          <div v-if="this.success" class="alert alert-success">
            {{ success }}
          </div>
          <div class="text-center">
            <input
              type="text"
              placeholder="Discount Code"
              class="code"
              v-model="code"
            />
            <button
              class="btn card-cta"
              v-if="!isLoading"
              @click="addDiscountCode()"
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-center">
          <button class="btn btn-link" @click="showForm()">
            Add Discount Code
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/util/Spinner';

export default {
  name: 'OrderDiscountCode',
  components: {
    Spinner,
  },
  data: function() {
    return {
      code: '',
      show: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'order/addDiscountCode/isLoading',
      error: 'order/addDiscountCode/error',
      success: 'order/addDiscountCode/success',
    }),
  },
  methods: {
    ...mapActions({
      add: 'order/addDiscountCode/add',
    }),
    addDiscountCode: function() {
      this.add(this.code);
    },
    showForm: function() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
.code {
  padding: 10px;
  height: 50px;
}
.card-cta {
  border: 1px solid #cacaca !important;
  display: inline-block;
  margin-top: -5px;
  font-size: 0.9rem;
  height: 50px;
}
</style>
