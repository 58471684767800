import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';
import router from '@/router/router';

const deleteUser = ({ commit }) => {
  commit(types.TOGGLE_LOADING);
  commit(types.SET_ERROR, null);

  let options = {
    method: 'POST',
    body: JSON.stringify([]),
  };
  fetch('user/delete', options)
    .then(response => {
      return response.json();
    })
    .then(() => {
      router.push({ name: 'Logout' });
      commit(types.TOGGLE_LOADING);
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

export { deleteUser };
