import list from './list/index';
import edit from './edit/index';

export default {
  namespaced: true,
  modules: {
    list,
    edit,
  },
};
