import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

const create = ({ commit }, ticket) => {
  commit(types.TOGGLE_LOADING);
  commit(types.SET_VIOLATIONS, []);
  commit(types.SET_ERROR, null);

  let options = {
    method: 'POST',
    body: JSON.stringify(ticket),
  };
  fetch('support/ticket/create', options)
    .then(response => {
      return response.json();
    })
    .then(() => {
      commit(types.TOGGLE_LOADING);
    })
    .catch(() => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, 'error');
    });
};

const clearError = ({ commit }) => {
  commit(types.SET_ERROR, false);
  commit(types.SET_VIOLATIONS, []);
};

export { create, clearError };
