<template>
  <div>
    <CourseTips
      v-bind:courseUuid="attendee.course.uuid"
      v-bind:courseVersion="attendee.courseVersion"
    ></CourseTips>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CourseTips from '@/components/course/CourseTips';

export default {
  components: {
    CourseTips,
  },
  data: function() {
    return {
      uuid: this.$route.params.uuid,
    };
  },
  computed: {
    ...mapGetters({
      listIsLoading: 'attendee/list/isLoading',
      listErrorCounter: 'attendee/list/errorCounter',
      attendee: 'attendee/get/data',
    }),
  },
  created() {
    this.getAttendee();
  },
  methods: {
    getAttendee: function() {
      this.$store.dispatch('attendee/get/getAttendee', [this.uuid, true]);
    },
  },
};
</script>
