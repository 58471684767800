import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

const getFeatures = ({ commit }) => {
  commit(types.TOGGLE_LOADING);

  fetch('features')
    .then(response => response.json())
    .then(data => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_DATA, data['hydra:member']);
      commit(types.SET_ERROR, '');
    })
    .catch(() => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, 'error');
    });
};

export { getFeatures };
