<template>
  <div>
    <DraggableDiv>
      <template slot="header">
        Notepad
        <span class="pull-right close" v-on:click="close()">
          <font-awesome-icon icon="times-circle" size="1x" />
        </span>
      </template>
      <template slot="main">
        <textarea
          style="resize: none; border-radius: 0"
          v-model="data"
          placeholder="This is your personal notepad to store for example information about questions."
        ></textarea>
      </template>
    </DraggableDiv>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DraggableDiv from '@/components/util/Draggable';

export default {
  name: 'Notepad',
  components: {
    DraggableDiv,
  },
  props: {
    attendee: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isLoading: 'attendee/notepad/isLoading',
      error: 'attendee/notepad/error',
    }),
  },
  data: function() {
    return {
      data: null,
      updatedValue: null,
    };
  },

  created: function() {
    this.data = this.attendee.notepad;
  },

  watch: {
    data(newValue) {
      if (newValue === null) {
        return;
      }

      let self = this;
      this.updatedValue = newValue;
      setTimeout(function() {
        if (self.updatedValue === newValue) {
          self.write([self.attendee['@id'], self.data]);
        }
      }, 500);
    },
  },

  methods: {
    ...mapActions({
      write: 'attendee/notepad/write',
    }),
    close: function() {
      this.$store.dispatch('attendee/run/notepadShowToggle');
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';

::v-deep .draggable-container {
  right: 100px;
  top: 100px;
}

::v-deep .draggable-container .draggable-header {
  border-top: 1px solid #1c7396;
  border-left: 1px solid #1c7396;
  border-right: 1px solid #1c7396;
}

::v-deep .draggable-container .draggable-header .close {
  cursor: pointer;
  margin-right: -10px;
}

::v-deep .draggable-container .draggable-main textarea {
  width: 100%;
  height: 100%;
  outline: none;
}
</style>
