<template>
  <div>
    <ul class="list-summarize" v-bind:class="className">
      <li>
        Simulated CKS environment with access to your own clusters
      </li>
      <li>
        <strong>Two CKS test sessions with identical content/questions</strong>
        (
        <router-link :to="{ name: 'Faq' }">why?</router-link>
        )
      </li>
      <li>Remote Desktop like the 06/2022 updated one in the real exams</li>
      <li>
        <strong>22 complex questions/scenarios</strong> to solve (
        <router-link
          v-if="courseUuid"
          :to="{ name: 'CoursePreview', params: { uuid: courseUuid } }"
          target="_blank"
        >
          see preview
        </router-link>
        <router-link v-if="!courseUuid" :to="{ name: 'Dashboard' }">
          see preview
        </router-link>
        )
      </li>
      <li>
        120 minutes countdown for testing your knowledge under pressure
      </li>
      <li>
        <strong>Environment is open for the next 36 hours</strong> after
        activation, time based and not usage based (
        <router-link :to="{ name: 'Faq' }">more</router-link>
        )
      </li>
      <li>
        Tips on how to prepare yourself and the Remote Desktop for the real exam
      </li>
      <li>
        <strong>
          45 pages material in total (questions, solutions, tips and tricks)
        </strong>
      </li>
      <li>
        Start your test sessions independently of each other during one year
      </li>
      <li>
        <strong>
          You'll get an automatically calculated score
        </strong>
      </li>
      <li>
        <font-awesome-icon :icon="['fab', 'slack']" size="1x" class="mr-3" />
        Access to private Slack community
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CksSimulatorContentList',
  props: {
    className: {
      type: String,
      default: 'px20',
    },
    courseUuid: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';

.list-summarize {
  list-style: none;
}

.px15 {
  li {
    padding-top: 15px;
  }
}

.px20 {
  li {
    padding-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
</style>
