<template>
  <div class="wrapper">
    <h1>Support</h1>

    <br /><br />

    <p>
      <font-awesome-icon icon="question" size="1x" class="mr-3" />

      You can find most common questions in our

      <router-link :to="{ name: 'Faq' }">
        <strong>FAQ</strong>
      </router-link>
    </p>

    <p v-if="isAuthenticated && isFullMember()">
      <font-awesome-icon :icon="['fab', 'slack']" size="1x" class="mr-3" />
      Join <a href="https://killer.sh/slack" target="_blank">Slack</a> for
      scenario and content discussions
    </p>

    <p>
      <font-awesome-icon icon="envelope" size="1x" class="mr-3" />
      For further questions and <strong>issues|support</strong> please create a
      ticket below
    </p>

    <div style="margin-top: 100px" v-if="!isLoading">
      <TicketCreate
        v-bind:user-passed="user"
        v-bind:show-slack-link="isAuthenticated && isFullMember()"
      ></TicketCreate>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TicketCreate from '@/components/support/TicketCreate';

export default {
  components: { TicketCreate },
  computed: mapGetters({
    isAuthenticated: 'authentication/status/isAuthenticated',
    user: 'user/list/data',
    isLoading: 'user/list/isLoading',
  }),
  methods: {
    userHasGoodProfile: function() {
      return this.user.providedAllDataToMakePurchase === true;
    },
    isFullMember: function() {
      return this.user && this.user.fullMember;
    },
  },
};
</script>
