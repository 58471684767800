import fetch from '../../../../utils/fetch';
import VueCookies from 'vue-cookies';
import router from '@/router/router';
import store from '@/store';

import * as definitions from './definitions';
import * as authenticationOauthMutationTypes from '../oauth/mutation_types';
import * as authenticationStatusMutationTypes from './mutation_types';
import * as userMutationTypes from '../../user/list/mutation_types';
import * as attendeeListMutationTypes from '../../attendee/list/mutation_types';

const logout = ({ commit, state }) => {
  let refreshJwtToken = state.jwtRefreshToken;
  if (refreshJwtToken) {
    let data = {
      // eslint-disable-next-line camelcase
      refresh_token: refreshJwtToken,
    };
    let options = {
      method: 'POST',
      body: JSON.stringify(data),
    };
    fetch('/security/token/revoke', options)
      .then(response => response.json())
      .then(() => {})
      .catch(() => {});
  }
  commit(authenticationStatusMutationTypes.RESET, null);
  commit(
    'authentication/oauth/' + authenticationOauthMutationTypes.RESET,
    null,
    { root: true }
  );
  commit('user/list/' + userMutationTypes.RESET, null, { root: true });
  commit('attendee/list/' + attendeeListMutationTypes.RESET, null, {
    root: true,
  });
  VueCookies.remove(definitions.JWT_REFRESH_TOKEN_COOKIE_NAME);
};

const setJwtTokens = ({ commit, state }, data) => {
  let jwtToken = data['token'];
  let jwtRefreshToken = data['refresh_token'];
  jwtToken = jwtToken === undefined ? null : jwtToken;
  jwtRefreshToken = jwtRefreshToken === undefined ? null : jwtRefreshToken;
  commit(authenticationStatusMutationTypes.SET_JWT_TOKEN, jwtToken);
  commit(
    authenticationStatusMutationTypes.SET_JWT_REFRESH_TOKEN,
    jwtRefreshToken
  );

  if (jwtToken) {
    if (!state.isAuthenticated) {
      commit(authenticationStatusMutationTypes.SET_IS_AUTHENTICATED, true);
    }
    commit(authenticationStatusMutationTypes.SET_IS_AUTHENTICATING, false);
    store.dispatch('user/list/getUserObject', {
      root: true,
    });
    refreshJwtTokenTimer();
  } else {
    commit(authenticationStatusMutationTypes.SET_IS_AUTHENTICATED, false);
    commit(authenticationStatusMutationTypes.SET_IS_AUTHENTICATING, false);
  }
};

/**
 * reads refreshJwtToken from cookies and writes into store
 * @param commit
 */
const readRefreshJwtTokenFromCookie = ({ commit }) => {
  let cookieRefreshJwtToken = VueCookies.get(
    definitions.JWT_REFRESH_TOKEN_COOKIE_NAME
  );
  if (cookieRefreshJwtToken) {
    commit(
      authenticationStatusMutationTypes.SET_JWT_REFRESH_TOKEN,
      cookieRefreshJwtToken
    );
  }
};

function refreshJwtTokenTimer() {
  let time = 10 * 60 * 1000;
  setTimeout(function() {
    // we want to refresh a jwt token periodically, every half hour
    store.dispatch('authentication/status/refreshJwtToken');
  }, time);
}

/**
 * uses refresh token from store to generate new jwt token
 * @param commit
 * @param state
 */
const refreshJwtToken = ({ commit, state }) => {
  // console.log('refreshJwtToken1');
  let maintenanceActive = store.getters['status/maintenance/active'];
  if (maintenanceActive) {
    // console.log('skip token refresh because of maintenance');
    refreshJwtTokenTimer();
    commit(authenticationStatusMutationTypes.SET_IS_AUTHENTICATING, false);
    return;
  }
  // console.log('refreshJwtToken2');
  let refreshJwtToken = state.jwtRefreshToken;
  if (refreshJwtToken) {
    let data = {
      refresh_token: refreshJwtToken,
    };
    let options = {
      method: 'POST',
      body: JSON.stringify(data),
    };
    fetch('/auth/token/refresh', options)
      .then(response => response.json())
      .then(data => {
        store.dispatch('authentication/status/setJwtTokens', data);
      })
      .catch(() => {
        commit(authenticationStatusMutationTypes.SET_IS_AUTHENTICATING, false);
        refreshJwtTokenTimer();
        // console.log('refreshJwtToken error during refresh');
        if (router.currentRoute.name !== 'AttendeeView') {
          // console.log('refreshJwtToken redirect to logout');
          router.push({ name: 'Logout' });
        }
      });
  } else {
    commit(authenticationStatusMutationTypes.SET_IS_AUTHENTICATING, false);
  }
};

export { logout, setJwtTokens, refreshJwtToken, readRefreshJwtTokenFromCookie };
