import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

const create = ({ commit }, data) => {
  commit(types.TOGGLE_LOADING);
  commit(types.SET_ERROR, false);
  commit(types.SET_SUCCESS, null);

  let options = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  fetch('access/request/create', options)
    .then(response => {
      return response.json();
    })
    .then(data => {
      commit(types.TOGGLE_LOADING);
      if (data.success !== true) {
        if (data.message) {
          commit(types.SET_ERROR, data.message);
        } else {
          commit(
            types.SET_ERROR,
            'Your access request was not accepted. Please try again or contact support on https://killer.sh/support'
          );
        }
      } else {
        commit(types.SET_SUCCESS, true);
      }
    })
    .catch(() => {
      commit(types.TOGGLE_LOADING);
      commit(
        types.SET_ERROR,
        'Your access request was not accepted. Please try again or contact support on https://killer.sh/support'
      );
    });
};

export { create };
