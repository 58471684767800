import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    inactivityLastActionAt: null,
    inactivityConfirmationVisible: false,
    inactivityConfirmationVisibleHideIframe: false,
    instructionShow: true,
    notepadShow: false,
    chatShow: false,
    attendeeTerminalAddresses: [],
    attendeeTerminalAddressPos: null,
    questionIndex: 1,
    questionSelectionEnabled: true,
  },
  actions,
  getters,
  mutations,
};
