<template>
  <span>
    <WebsocketLatencyTest
      v-bind:urlPassed="attendeeTerminalAddress"
      v-bind:attendeeTerminalAddress="attendeeTerminalAddress"
      v-bind:attendeePassed="attendee"
    ></WebsocketLatencyTest>
    <iframe
      v-if="attendeeTerminalAddressHealthChecked"
      :src="attendeeTerminalAddressHealthChecked"
    ></iframe>
    <div
      v-else
      class="iframe-placeholder"
      style="padding-bottom: 0 !important;"
    ></div>
    <TerminalActiveActions
      v-bind:attendeeTerminalLfctOperatorAddress="
        attendee.terminal.clusterTerminalProxyLfctOperatorAddress
      "
    ></TerminalActiveActions>
  </span>
</template>

<script>
import WebsocketLatencyTest from '@/components/websocket/LatencyTest';
import TerminalActiveActions from '@/components/attendee/run/lfct/TerminalActiveActions';
import { mapGetters } from 'vuex';

export default {
  name: 'TerminalActive',
  components: {
    WebsocketLatencyTest,
    TerminalActiveActions,
  },
  data: function() {
    return {
      attendeeTerminalAddressTryCount: 0,
      attendeeTerminalAddressHealthChecked: null,
    };
  },
  props: {
    attendee: {
      required: true,
    },
    attendeeTerminalAddressPassed: {
      required: true,
    },
  },
  mounted() {
    this.$store.dispatch('attendee/run/setQuestionSelectionEnabled', false);
    this.tryTerminalAddress();
  },
  computed: {
    ...mapGetters({
      attendeeQuestionIndex: 'attendee/run/questionIndex',
    }),
    attendeeTerminalAddress: function() {
      return this.attendeeTerminalAddressPassed.replace(
        '{{PORT}}',
        '100' + this.getQuestionIndexWithTwoDigits(this.attendeeQuestionIndex)
      );
    },
  },
  methods: {
    tryTerminalAddress: function() {
      let maxTry = 5;

      console.log(
        'tryTerminalAddress: try ' + this.attendeeTerminalAddressTryCount
      );

      const fetchTimeout = (url, ms, { signal, ...options } = {}) => {
        const controller = new AbortController();
        const promise = fetch(url, { signal: controller.signal, ...options });
        if (signal) signal.addEventListener('abort', () => controller.abort());
        const timeout = setTimeout(() => controller.abort(), ms);
        return promise.finally(() => clearTimeout(timeout));
      };

      const controller = new AbortController();
      let url = this.attendeeTerminalAddress;

      if (this.attendeeTerminalAddressTryCount < maxTry) {
        fetchTimeout(url, 5000, { signal: controller.signal })
          .then(response => {
            console.log('tryTerminalAddress: success');
            this.attendeeTerminalAddressHealthChecked = url;
            console.log(
              'tryTerminalAddress: set address ' +
                this.attendeeTerminalAddressHealthChecked
            );
            return response.json();
          })
          .catch(error => {
            console.log('tryTerminalAddress: error ' + error);
            if (error.name === 'AbortError' || error.name === 'TypeError') {
              this.attendeeTerminalAddressTryCount++;

              setTimeout(() => {
                console.log('tryTerminalAddress: new timeout to call');
                this.tryTerminalAddress();
              }, 3000);
            }
          });
      } else {
        console.log('tryTerminalAddress: max try reached');
        this.attendeeTerminalAddress = url;
      }
    },
    getQuestionIndexWithTwoDigits: function(index) {
      if (index < 10) {
        return '0' + index;
      } else {
        return index;
      }
    },
  },
  beforeDestroy: function() {
    this.$store.dispatch('attendee/run/setQuestionSelectionEnabled', true);
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';

iframe {
  border: 0;
  overflow-x: scroll;
  width: 100%;
  height: calc(100% - 49px);
  background-color: #2b2b2b !important;
  background: url(../../../../assets/spinner.gif) center center no-repeat;
  background-size: 40px 40px;
}

.iframe-placeholder {
  border: 0;
  width: 100%;
  height: calc(100% - 49px);
  background-color: #2b2b2b !important;
  background: url(../../../../assets/spinner.gif) center center no-repeat;
  background-size: 40px 40px;
}
</style>

<style lang="css" scoped>
.xterm .xterm-viewport {
  overflow-y: auto !important;
}
</style>
