import * as types from './mutation_types';

export default {
  [types.SET_INACTIVITY_LOG_ACTION](state, inactivityLastActionAt) {
    Object.assign(state, { inactivityLastActionAt });
  },

  [types.SET_INACTIVITY_CONFIRMATION_VISIBLE](
    state,
    inactivityConfirmationVisible
  ) {
    Object.assign(state, { inactivityConfirmationVisible });
  },

  [types.SET_INACTIVITY_CONFIRMATION_VISIBLE_HIDE_IFRAME](
    state,
    inactivityConfirmationVisibleHideIframe
  ) {
    Object.assign(state, { inactivityConfirmationVisibleHideIframe });
  },

  [types.SET_INSTRUCTION_SHOW](state, instructionShow) {
    Object.assign(state, { instructionShow });
  },

  [types.SET_NOTEPAD_SHOW](state, notepadShow) {
    Object.assign(state, { notepadShow });
  },

  [types.SET_CHAT_SHOW](state, chatShow) {
    Object.assign(state, { chatShow });
  },

  [types.SET_ATTENDEE_TERMINAL_ADDRESSES](state, attendeeTerminalAddresses) {
    Object.assign(state, { attendeeTerminalAddresses });
  },

  [types.SET_ATTENDEE_TERMINAL_ADDRESS_POS](state, attendeeTerminalAddressPos) {
    Object.assign(state, { attendeeTerminalAddressPos });
  },

  [types.SET_QUESTION_INDEX](state, questionIndex) {
    Object.assign(state, { questionIndex });
  },

  [types.SET_QUESTION_SELECTION_ENABLED](state, questionSelectionEnabled) {
    Object.assign(state, { questionSelectionEnabled });
  },
};
