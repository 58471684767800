<template>
  <div class="terminal-loading">
    <div class="vertical-center">
      <Spinner color="white"></Spinner>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/util/Spinner';
import { mapGetters } from 'vuex';

export default {
  name: 'TerminalLoading',
  components: {
    Spinner,
  },
  props: {
    attendee: {
      required: true,
    },
  },
  mounted() {
    this.$store.dispatch('attendee/run/setQuestionSelectionEnabled', false);
    this.getState();
  },
  computed: {
    ...mapGetters({
      stateIsLoading: 'attendee/lfctOperator/isLoading',
    }),
  },
  methods: {
    getState: function() {
      this.$store.dispatch('attendee/lfctOperator/getState', [
        this.attendee.terminal.clusterTerminalProxyLfctOperatorAddress,
      ]);
    },
  },
  watch: {
    stateIsLoading(newValue) {
      if (!newValue) {
        setTimeout(() => {
          this.getState();
        }, 1000);
      }
    },
  },
  beforeDestroy: function() {
    this.$store.dispatch('attendee/run/setQuestionSelectionEnabled', true);
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';

.action {
  text-transform: capitalize;
}

.terminal-loading {
  position: relative;
  height: 100%;
  text-align: center;
}

.vertical-center {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
