<template>
  <div class="wrapper">
    <p v-if="destination === false">
      Link not found.
    </p>
    <p v-else-if="destination !== null">
      <a v-bind:href="this.destination"
        >Click here if redirection doesn't work</a
      >
    </p>
    <p v-else>
      Please wait for redirection...
    </p>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      destination: null,
    };
  },
  created() {
    let dst = this.$route.query.d;
    switch (dst) {
      case 'slack':
        this.destination = 'https://killer.sh/slack';
        break;
      case 'cks-series':
        this.destination =
          'https://wuestkamp.medium.com/cks-exam-series-1-create-cluster-security-best-practices-50e35aaa67ae';
        break;
      case 'cks-course':
        this.destination = 'https://youtu.be/d9xfB5qaOfg';
        break;
      case 'cka-series':
        this.destination =
          'https://wuestkamp.medium.com/kubernetes-cka-example-questions-practical-challenge-86318d85b4d';
        break;
      case 'ckad-series':
        this.destination =
          'https://wuestkamp.medium.com/kubernetes-ckad-weekly-challenges-overview-and-tips-7282b36a2681?source=friends_link&sk=5a98f575b6b438d5c022f94df2cd9d9b';
        break;
      default:
        this.destination = false;
        return;
    }

    window.location = this.destination;
  },
};
</script>
