import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

const write = ({ commit }, dataPassed) => {
  commit(types.TOGGLE_LOADING);

  let data = {
    attendee: dataPassed[0],
    text: dataPassed[1],
  };
  let options = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  fetch('attendee/notepad/write', options)
    .then(response => {
      return response.json();
    })
    .then(() => {
      commit(types.TOGGLE_LOADING);
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

export { write };
