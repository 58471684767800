import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';
import store from '@/store';

const edit = ({ commit }, user) => {
  commit(types.TOGGLE_LOADING);
  commit(types.SET_VIOLATIONS, []);
  commit(types.SET_ERROR, null);

  let options = {
    method: 'PUT',
    body: JSON.stringify(user),
  };
  fetch('users/' + user.uuid, options)
    .then(response => {
      return response.json();
    })
    .then(() => {
      commit(types.TOGGLE_LOADING);
      store.dispatch('user/list/getUserObject');
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, 'An error occurred, form was not saved');

      if (typeof e === 'object' && 'errors' in e) {
        commit(types.SET_VIOLATIONS, e.errors.violations);
      }
    });
};

const clearError = ({ commit }) => {
  commit(types.SET_ERROR, false);
  commit(types.SET_VIOLATIONS, []);
};

export { edit, clearError };
