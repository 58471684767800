import * as types from './mutation_types';

export default {
  [types.RESET](state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      data: null,
      needsConsentSubmit: false,
    });
  },

  [types.SET_ERROR](state, error) {
    Object.assign(state, { error });
  },

  [types.SET_DATA](state, data) {
    Object.assign(state, { data });
  },

  [types.SET_NEEDS_CONSENT_SUBMIT](state, needsConsentSubmit) {
    Object.assign(state, { needsConsentSubmit });
  },

  [types.SET_NEEDS_TERMS_CONDITIONS_ACCEPT](
    state,
    needsTermsAndConditionsAccept
  ) {
    Object.assign(state, { needsTermsAndConditionsAccept });
  },

  [types.TOGGLE_LOADING](state) {
    Object.assign(state, { isLoading: !state.isLoading });
  },
};
