<template>
  <div class="container bg-white min-vh-100 pb-3">
    <span v-if="listError">
      An error occurred, please try again or contact support.
    </span>
    <span v-if="this.listIsLoading">
      <br /><br /><br />
      <Spinner></Spinner>
    </span>
    <span v-else>
      <p
        v-if="
          !publicAccessed &&
            (attendee.courseType === null ||
              (!attendee.courseType.includes('lfcs') &&
                !attendee.courseType.includes('lfct')))
        "
        style="text-align: center"
        class="pt-3 small"
      >
        You can access this page also inside the Remote Desktop by using the
        icons on the desktop
      </p>
      <span v-if="hasScore()">
        <div class="p-3">
          <br />
          <br />

          <h1 class="h3 text-center">
            Killer Shell {{ this.getScoreData().Description }} Score
          </h1>

          <div style="text-align: center">
            <small>{{ this.attendee.uuid }}</small
            ><br />
            <br />
            <p>
              <span
                v-if="
                  !this.attendee.terminal ||
                    this.attendee.terminal.status !== 'inactive'
                "
              >
                This score will be calculated every few minutes<br />
              </span>
              <small>
                <span>{{ this.getScore().scoreDateString }}</span>
                <span v-if="this.getScoreAge()"
                  >&nbsp;&nbsp;&nbsp;({{ this.getScoreAge() }} ago)</span
                >
              </small>
            </p>

            <p
              v-if="attendee.courseType.includes('cks')"
              style="margin: 0 20%;"
            >
              The <strong>simulator difficulty</strong> is comparable to the
              real exam, although there are probably fewer questions in the real
              exam.
            </p>
            <p v-else style="margin: 0 20%;">
              The <strong>simulator difficulty</strong> is higher compared to
              the real exam. Most of the simulator questions require good amount
              of work and can be considered hard. In the real exam you will also
              face these hard scenarios, just less often. There are probably
              also fewer questions in the real exam.
            </p>

            <br />
            <br />
          </div>
        </div>
        <section class="container shadow-lg card p-3 border-0">
          <div class="px-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="text-center">
                <span class="text-dark small">Solved subtasks:</span>
                <p
                  class="card sec-color text-white h3"
                  style="min-width: 150px"
                >
                  {{ this.getScoreData().StepsSuccessCount }} /
                  {{ this.getScoreData().StepsCount }}
                </p>
              </div>

              <div>
                <span class="text-dark small"></span><br />
                <span v-if="this.getScoreLevel() === 'low'">
                  <h2 class="px-3 py-2 h3 badge badge-danger">
                    Low Score
                  </h2>
                </span>
                <span v-if="this.getScoreLevel() === 'medium'">
                  <h2 class="px-3 py-2 h3 badge badge-warning">
                    Medium Score
                  </h2>
                </span>
                <span v-if="this.getScoreLevel() === 'high'">
                  <h2 class="px-3 py-2 h3 badge badge-success">High Score</h2>
                </span>
                <span v-if="this.getScoreLevel() === 'best'">
                  <h2 class="px-3 py-2 h3 badge badge-info">Best Score</h2>
                </span>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            class="container"
            v-for="(item, idx) in this.getScoreData().Checks"
            v-bind:key="idx"
          >
            <ul class="list-group mt-3 mb-3">
              <li
                class="list-group-item description-bg d-flex justify-content-between align-items-center text-body"
              >
                <span class="font-weight-bold">{{ item.Description }}</span>
                <span v-if="item.Outdated" class="outdated"
                  >Outdated result, cluster not reachable</span
                >
                <span class="">
                  <span class="h5">{{ item.StepsSuccess }} </span>
                  <span class="small text-dark"> of </span>
                  <span class="h5">{{
                    item.StepsSuccess + item.StepsFailed
                  }}</span>
                </span>
              </li>
              <div
                class="list-group-item"
                v-for="(step, idx) in item.Steps"
                v-bind:key="idx"
              >
                <li class="list d-flex justify-content-between">
                  <span> {{ step.Description }}</span>
                  <span>
                    <span v-if="step.Result === true">
                      <svg
                        class="bi bi-check text-success"
                        width="1.3em"
                        height="1.3em"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M13.854 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                    <span v-else>
                      <svg
                        class="bi bi-x text-danger"
                        width="1.3em"
                        height="1.3em"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
                          clip-rule="evenodd"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </span>
                </li>
              </div>
            </ul>
          </div>
        </section>
      </span>
      <span v-else>
        <div style="text-align: center">
          <br /><br /><br />
          <p>No score data available</p>
        </div>
      </span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '@/components/util/Spinner';

export default {
  components: {
    Spinner,
  },
  props: {
    attendee: {
      required: true,
    },
    publicAccessed: {
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      listIsLoading: 'attendee/list/isLoading',
      listError: 'attendee/list/error',
    }),
  },
  methods: {
    hasScore: function() {
      return this.getScore();
    },
    getScoreAge: function() {
      let score = this.getScore();
      if (score && score.ageMinutes < 32 * 60) {
        return score.ageString;
      }
      return null;
    },
    getScoreData: function() {
      let score = this.getScore();
      if (score) {
        return score.scoreDataUser.Result;
      }
      return null;
    },
    getScoreLevel: function() {
      let level = 'low';
      let scoreDataUser = this.getScoreData();
      if (scoreDataUser) {
        if (
          'StepsSuccessCount' in scoreDataUser &&
          'StepsCount' in scoreDataUser
        ) {
          let percentSteps =
            (scoreDataUser.StepsSuccessCount / scoreDataUser.StepsCount) * 100;
          if (percentSteps < 50) {
            level = 'low';
          } else if (percentSteps < 70) {
            level = 'medium';
          } else if (percentSteps < 100) {
            level = 'high';
          } else {
            level = 'best';
          }
        }
      }
      return level;
    },
    getScore: function() {
      if (
        this.attendee &&
        this.attendee.scores &&
        this.attendee.scores.length
      ) {
        let scoreAvailabilityMerged = this.getScoreByType(
          'type_availability_merged'
        );
        if (scoreAvailabilityMerged) {
          return scoreAvailabilityMerged;
        }

        let scoreLatest = this.getScoreByType('type_latest');
        if (scoreLatest) {
          return scoreLatest;
        }

        return null;
      }
      return null;
    },
    getScoreByType: function(type) {
      if (
        this.attendee &&
        this.attendee.scores &&
        this.attendee.scores.length
      ) {
        for (let i = 0; i < this.attendee.scores.length; i++) {
          if (this.attendee.scores[i]['type'] === type) {
            return this.attendee.scores[i];
          }
        }
        return null;
      }
      return null;
    },
  },
};
</script>

<style scoped>
.sec-color {
  background-color: var(--secondary-color);
}
.description-bg {
  background-color: #e3e3ef;
}
.list {
  list-style-type: none;
}
.badge {
  font-size: 120%;
  min-width: 150px;
}
.outdated {
  color: #464646;
}
</style>
