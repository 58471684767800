import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

const copy = ({ commit }, dataPassed) => {
  commit(types.TOGGLE_LOADING);

  let url = dataPassed[0];
  let body = dataPassed[1];
  let options = {
    method: 'POST',
    body: btoa(body),
  };
  fetch(url, options)
    .then(response => {
      return response.json();
    })
    .then(() => {
      commit(types.TOGGLE_LOADING);
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

export { copy };
