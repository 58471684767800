import * as types from './mutation_types';

export default {
  [types.RESET](state) {
    Object.assign(state, {
      active: false,
    });
  },

  [types.SET_ACTIVE](state, active) {
    Object.assign(state, { active });
  },

  [types.SET_CURRENT_PAGE_SHOULD_SHOW_MAINTENANCE](
    state,
    currentPageShouldShowMaintenance
  ) {
    Object.assign(state, { currentPageShouldShowMaintenance });
  },
};
