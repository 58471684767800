import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';
import router from '@/router/router';
import store from '@/store';

const create = ({ commit }, productId) => {
  commit(types.TOGGLE_LOADING);
  commit(types.SET_ERROR, false);
  let data = {
    product: productId,
  };
  let options = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  fetch('order/create', options)
    .then(response => {
      return response.json();
    })
    .then(response => {
      commit(types.TOGGLE_LOADING);
      if (response.success === true) {
        store.dispatch('order/selection/reset', null, {
          root: true,
        });
        store.dispatch('order/addDiscountCode/reset', null, {
          root: true,
        });
        router.push({ name: 'OrderCheckout' });
      } else {
        let err = 'An error occurred';
        if (response.error) {
          err = response.error;
        }
        commit(types.SET_ERROR, err);
      }
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

export { create };
