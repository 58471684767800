import * as types from './mutation_types';
import VueCookies from 'vue-cookies';
import * as definitions from './definitions';

export default {
  [types.RESET](state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      methods: [],
    });
  },

  [types.SET_DATA](state, data) {
    Object.assign(state, { data });

    VueCookies.set(
      definitions.COOKIE_NAME_AUTH_OAUTH_STATE,
      data['states'],
      '1d',
      null,
      null,
      null,
      'Strict'
    );
  },

  [types.SET_ERROR](state, error) {
    Object.assign(state, { error });
  },

  [types.TOGGLE_LOADING](state) {
    Object.assign(state, { isLoading: !state.isLoading });
  },
};
