<template>
  <div>
    <div
      v-for="consent in this.consents"
      :key="consent.text"
      style="margin-bottom: 50px"
    >
      <div class="row">
        <div class="col-1">
          <b-form-checkbox v-model="consent.choice" :id="consent.type" />
        </div>
        <div class="col-11">
          <label
            v-if="consent.type === 'type_terms_conditions'"
            :for="consent.type"
          >
            I agree with the
            <router-link :to="{ name: 'Terms' }" target="_blank">
              Terms and Conditions
            </router-link>
          </label>
          <label v-else :for="consent.type">{{ consent.text }}</label>
        </div>
        <div v-if="consent.choice === null" class="col-1"></div>
        <div v-if="consent.choice === null" class="col-11">
          <i>(no answer given yet)</i>
        </div>
      </div>
    </div>

    <div class="action-links">
      <spinner v-if="isLoading"></spinner>
      <span v-else>
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6">
            <button
              v-on:click="save()"
              class="mt-4 btn card-cta-primary pull-right"
            >
              Save
            </button>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Spinner from '@/components/util/Spinner';

export default {
  name: 'UserConsentEdit',
  components: { Spinner },
  data: function() {
    return {
      user: null,
    };
  },
  props: {
    consentsPassed: {
      required: true,
      type: Array,
    },
  },
  methods: {
    ...mapActions({
      edit: 'consent/edit/edit',
    }),
    save: function() {
      this.edit(this.consents);
    },
  },
  computed: mapGetters({
    error: 'consent/edit/error',
    isLoading: 'consent/edit/isLoading',
    violations: 'consent/edit/violations',
  }),
  created: function() {
    this.consents = this.consentsPassed;
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';
.row {
  margin-bottom: 14px;
}
.error {
  border: 1px solid red;
}
</style>
