import Vue from 'vue';
import App from './App.vue';
import VueCookies from 'vue-cookies';
import router from './router/router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faGitlab } from '@fortawesome/free-brands-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueFlashMessage from 'vue-flash-message';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
require('vue-flash-message/dist/vue-flash-message.min.css');
import vueCountryRegionSelect from 'vue-country-region-select';
Vue.use(vueCountryRegionSelect);

import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
Vue.use(VuejsDialog);

import VueSplit from 'vue-split-panel';
Vue.use(VueSplit);

library.add(
  faLongArrowAltRight,
  faGithub,
  faGoogle,
  faGitlab,
  faLinkedin,
  faMicrosoft,
  faTwitter,
  faClipboard,
  faEnvelopeSquare,
  faEnvelope,
  faSlack,
  faQuestion,
  faInfoCircle,
  faCheckCircle,
  faTimesCircle
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

Vue.use(VueFlashMessage, {
  messageOptions: { timeout: 5000 },
});

Vue.use(BootstrapVue);
Vue.use(VueCookies);

Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline);

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App),
});
