<template>
  <div class="wrapper">
    <div class="container">
      <div v-if="order && firstTimeLoaded">
        <div v-if="order.type === 'cks_simulator_2x'">
          <h1 style="font-size: 1.5em">
            Get access to the <span class="text-highlight">CKS</span> Simulator
            <p>
              <small
                ><small>Kubernetes {{ order.course.version }}</small></small
              >
            </p>
          </h1>
          <b-img
            src="@/assets/k8s_cks_logo_s.png"
            fluid
            width="200px"
            center
          ></b-img>
          <div v-if="!order.rebuyDiscount">
            <p class="text-center" style="margin-bottom: 30px"></p>
            <div class="text-middle">
              <p class="text-center">
                <strong>You’re just one step away from:</strong>
              </p>
              <CksSimulatorContentList
                className="px15"
                v-bind:courseUuid="order.course.uuid"
              ></CksSimulatorContentList>
            </div>

            <div class="alert alert-info" style="margin-top: 30px">
              Two simulator sessions come already included with every Linux
              Foundation Exam Purchase! More on very top of our
              <router-link :to="{ name: 'Faq' }" target="_blank"
                >FAQ.</router-link
              >
            </div>

            <div class="row">
              <div class="col-md-6 col-sm-12">
                <router-link
                  :to="{ name: 'CksSimulatorAbout' }"
                  class="btn card-cta"
                  target="_blank"
                >
                  More Details
                </router-link>
              </div>
              <div class="col-md-6 col-sm-12">
                <router-link
                  :to="{
                    name: 'CoursePreview',
                    params: { uuid: order.course.uuid },
                  }"
                  class="btn card-cta"
                  target="_blank"
                >
                  Preview Questions
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div v-if="order.type === 'lfcs_simulator_2x'">
          <h1 style="font-size: 1.5em">
            Get access to the <span class="text-highlight">LFCS</span> Simulator
            <p></p>
          </h1>
          <b-img
            src="@/assets/lfcs_logo.png"
            fluid
            style="max-width:150px; margin-top: 30px"
            center
          ></b-img>

          <div v-if="!order.rebuyDiscount">
            <p class="text-center" style="margin-bottom: 30px"></p>
            <div class="text-middle">
              <p class="text-center">
                <strong>You’re just one step away from:</strong>
              </p>
              <LfcsSimulatorContentList
                className="px15"
                v-bind:courseUuid="order.course.uuid"
              ></LfcsSimulatorContentList>

              <div class="alert alert-info" style="margin-top: 30px">
                Two simulator sessions come already included with every Linux
                Foundation Exam Purchase! More on very top of our
                <router-link :to="{ name: 'Faq' }" target="_blank"
                  >FAQ.</router-link
                >
              </div>
            </div>

            <div class="row">
              <div class="col-md-2 col-sm-12"></div>
              <div class="col-md-8 col-sm-12">
                <router-link
                  :to="{ name: 'LfcsSimulatorAbout' }"
                  class="btn card-cta"
                  target="_blank"
                >
                  More Details
                </router-link>
              </div>
              <div class="col-md-2 col-sm-12"></div>
            </div>
          </div>
        </div>

        <div v-if="order.type === 'lfct_simulator_2x'">
          <h1 style="font-size: 1.5em">
            Get access to the <span class="text-highlight">LFCT</span> Simulator
            <p></p>
          </h1>
          <b-img
            src="@/assets/lfct_logo.png"
            fluid
            style="max-width:150px; margin-top: 40px"
            center
          ></b-img>
          <div v-if="!order.rebuyDiscount">
            <p class="text-center" style="margin-bottom: 30px"></p>
            <div class="text-middle">
              <p class="text-center">
                <strong>You’re just one step away from:</strong>
              </p>
              <LfctSimulatorContentList
                className="px15"
                v-bind:courseUuid="order.course.uuid"
              ></LfctSimulatorContentList>

              <div class="alert alert-info" style="margin-top: 30px">
                Two simulator sessions come already included with every Linux
                Foundation Exam Purchase! More on very top of our
                <router-link :to="{ name: 'Faq' }" target="_blank"
                  >FAQ.</router-link
                >
              </div>
            </div>

            <div class="row">
              <div class="col-md-2 col-sm-12"></div>
              <div class="col-md-8 col-sm-12">
                <router-link
                  :to="{ name: 'LfctSimulatorAbout' }"
                  class="btn card-cta"
                  target="_blank"
                >
                  More Details
                </router-link>
              </div>
              <div class="col-md-2 col-sm-12"></div>
            </div>
          </div>
        </div>

        <div v-if="order.type === 'ckad_simulator_2x'">
          <h1 style="font-size: 1.5em">
            Get access to the <span class="text-highlight">CKAD</span> Simulator
            <p>
              <small
                ><small
                  >Kubernetes {{ removeLetters(order.course.version) }}</small
                ></small
              >
            </p>
          </h1>
          <b-img
            src="@/assets/k8s_ckad_logo_s.png"
            fluid
            width="200px"
            center
          ></b-img>
          <div v-if="!order.rebuyDiscount">
            <p class="text-center" style="margin-bottom: 30px"></p>
            <div class="text-middle">
              <p class="text-center">
                <strong>You’re just one step away from:</strong>
              </p>
              <CkadSimulatorContentList
                className="px15"
                v-bind:courseUuid="order.course.uuid"
              ></CkadSimulatorContentList>

              <div class="alert alert-info" style="margin-top: 30px">
                Two simulator sessions come already included with every Linux
                Foundation Exam Purchase! More on very top of our
                <router-link :to="{ name: 'Faq' }" target="_blank"
                  >FAQ.</router-link
                >
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-sm-12">
                <router-link
                  :to="{ name: 'CkadSimulatorAbout' }"
                  class="btn card-cta"
                  target="_blank"
                >
                  More Details
                </router-link>
              </div>
              <div class="col-md-6 col-sm-12">
                <router-link
                  :to="{
                    name: 'CoursePreview',
                    params: { uuid: order.course.uuid },
                  }"
                  class="btn card-cta"
                  target="_blank"
                >
                  Preview Questions
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="order.type === 'cka_simulator_2x'">
          <h1 style="font-size: 1.5em">
            Get access to the <span class="text-highlight">CKA</span> Simulator
            <p>
              <small
                ><small>Kubernetes {{ order.course.version }}</small></small
              >
            </p>
          </h1>
          <b-img
            src="@/assets/k8s_cka_logo_s.png"
            fluid
            width="200px"
            center
          ></b-img>
          <div v-if="!order.rebuyDiscount">
            <p class="text-center" style="margin-bottom: 30px"></p>
            <div class="text-middle">
              <p class="text-center">
                <strong>You’re just one step away from:</strong>
              </p>
              <CkaSimulatorContentList
                className="px15"
                v-bind:courseUuid="order.course.uuid"
              ></CkaSimulatorContentList>

              <div class="alert alert-info" style="margin-top: 30px">
                Two simulator sessions come already included with every Linux
                Foundation Exam Purchase! More on very top of our
                <router-link :to="{ name: 'Faq' }" target="_blank"
                  >FAQ.</router-link
                >
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-sm-12">
                <router-link
                  :to="{ name: 'CkaSimulatorAbout' }"
                  class="btn card-cta"
                  target="_blank"
                >
                  More Details
                </router-link>
              </div>
              <div class="col-md-6 col-sm-12">
                <router-link
                  :to="{
                    name: 'CoursePreview',
                    params: { uuid: order.course.uuid },
                  }"
                  class="btn card-cta"
                  target="_blank"
                >
                  Preview Questions
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div style="margin-top: 50px"></div>

        <p v-if="order.rebuyDiscount === true" class="alert">
          You're about to purchase <strong>one</strong> single simulator
          session.<br /><br />
          The cheaper Rebuy-Rate has been applied.<br /><br />
          Another purchase won't give you different questions. All simulator
          sessions of the same type (CKS|CKA|CKAD|LFCT|LFCS) have identical
          content.
        </p>
        <OrderDiscountCode v-else></OrderDiscountCode>

        <hr />
        <div class="row">
          <div class="col-6">
            <div style="margin-left: 40px">
              Invoice Data
              <small
                >(<router-link :to="{ name: 'UserProfile' }">change</router-link
                >)</small
              >
              <div class="mb-2"></div>
              <p v-if="this.user" class="small">
                {{ this.user.email }}<br />
                <template v-if="this.user.company">
                  {{ this.user.companyName }} ({{ this.user.companyId }})<br />
                </template>
                <template v-if="this.user.firstName || this.user.lastName">
                  {{ this.user.firstName }} {{ this.user.lastName }}<br />
                </template>
                <template v-if="this.user.address.address">
                  {{ this.user.address.address }}<br />
                </template>
                <template
                  v-if="this.user.address.zipcode || this.user.address.city"
                >
                  {{ this.user.address.zipcode }} {{ this.user.address.city
                  }}<br />
                </template>
                <template
                  v-if="
                    this.user.address.countryName || this.user.address.state
                  "
                >
                  {{ this.user.address.countryName }}
                  {{ this.user.address.state }}<br />
                </template>
              </p>
            </div>
          </div>
          <div class="col-6">
            <p class="text-center cta">
              <span v-if="order.taxPercent === 0">
                Amount <b>{{ order.amountUsd }} $</b>
              </span>
              <span v-else>
                <small>Amount: {{ order.amountUsd }} $</small><br />
                <small>Tax: {{ order.taxPercent }} %</small><br /><br />
                Total: <b>{{ order.amountUsd }} $</b>
              </span>
            </p>
          </div>
        </div>
        <hr style="margin-top: 1em " />

        <span class="boxes">
          <div class="row mt-5">
            <div class="col-1">
              <b-form-checkbox v-model="boxes.terms" id="terms" />
            </div>
            <div class="col-11">
              <label for="terms">
                I agree with the
                <router-link :to="{ name: 'Terms' }" target="_blank">
                  Terms and Conditions
                </router-link>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-1">
              <b-form-checkbox v-model="boxes.faq" id="faq" />
            </div>
            <div class="col-11">
              <label for="faq">
                I read the complete
                <router-link :to="{ name: 'Faq' }" target="_blank"
                  >FAQ</router-link
                >
              </label>
            </div>
          </div>
        </span>

        <div v-if="showPaymentIframe()" class="payment-gateway-iframe-wrapper">
          <p v-if="stripeError" class="alert alert-warning mt-2 mb-4">
            <strong>If a processing error occurs</strong> but you think the card
            data was correct: close all other order/checkout tabs and
            <router-link :to="{ name: 'OrderSelectCreate' }">
              create a new order
            </router-link>
          </p>
          <div id="dropin-container" style="width: 80%">
            <stripe-element-payment
              v-if="paymentHasClientToken"
              ref="paymentRef"
              :pk="paymentGetTokenPublicToken"
              :elements-options="elementsOptions()"
              :create-options="createOptions()"
              :confirm-params="confirmParams()"
              @error="stripeErrorEvent"
            />
          </div>
          <Spinner
            class="pull-left mb-2"
            v-if="!paymentHasClientToken"
          ></Spinner>
          <div style="clear: both"></div>
          <Spinner class="pull-left mb-2" v-if="paymentButtonLoading"></Spinner>
          <button
            v-else-if="paymentHasClientToken"
            class="btn card-cta btn-secondary"
            id="submit-button"
            style="min-width: 200px"
            @click="submit"
          >
            Purchase
          </button>
        </div>
        <div v-else>
          <p class="alert alert-info mt-5">
            Please read and accept all boxes on top.
          </p>
        </div>
      </div>
      <div v-else-if="orderGetIsLoading">
        <Spinner></Spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/util/Spinner';
import CkadSimulatorContentList from '@/components/static/CkadSimulatorContentList';
import CkaSimulatorContentList from '@/components/static/CkaSimulatorContentList';
import CksSimulatorContentList from '@/components/static/CksSimulatorContentList';
import LfctSimulatorContentList from '@/components/static/LfctSimulatorContentList';
import LfcsSimulatorContentList from '@/components/static/LfcsSimulatorContentList';
import OrderDiscountCode from '@/components/order/OrderDiscountCode';
import router from '@/router/router';
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import { ENTRYPOINT } from '../../config/entrypoint';
const entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');

export default {
  name: 'OrderCheckout',
  components: {
    Spinner,
    CkadSimulatorContentList,
    CkaSimulatorContentList,
    CksSimulatorContentList,
    LfctSimulatorContentList,
    LfcsSimulatorContentList,
    OrderDiscountCode,
    StripeElementPayment,
  },
  computed: {
    ...mapGetters({
      user: 'user/list/data',
      attendees: 'attendee/list/data',
      order: 'order/get/order',
      orderGetIsLoading: 'order/get/isLoading',
      paymentGetTokenError: 'order/getToken/error',
      paymentGetTokenToken: 'order/getToken/token',
      paymentGetTokenPublicToken: 'order/getToken/publicToken',
      paymentGetTokenIsLoading: 'order/getToken/isLoading',
      discountCodeIsLoading: 'order/addDiscountCode/isLoading',
    }),
  },
  data: function() {
    return {
      firstTimeLoaded: false,
      orderAmount: null,
      iframeCacheBuster: 0,
      faqNoticeShown: false,
      paymentHasClientToken: false,
      boxes: {
        terms: false,
        faq: false,
      },
      paymentButtonLoading: false,
      stripeError: false,
    };
  },
  methods: {
    ...mapActions({
      getOrders: 'order/get/getOrders',
    }),
    removeLetters: function(string) {
      string = string.replace('a', '');
      string = string.replace('b', '');
      return string.replace('c', '');
    },
    stripeErrorEvent: function() {
      this.stripeError = true;
    },
    submit: function() {
      this.paymentButtonLoading = true;
      let that = this;
      setTimeout(function() {
        that.paymentButtonLoading = false;
      }, 5000);
      this.$refs.paymentRef.submit();
    },
    createOptions: function() {
      return {
        fields: {
          billingDetails: {
            name: 'never',
            email: 'never',
            phone: 'never',
            address: 'never',
          },
        },
      };
    },
    elementsOptions: function() {
      return {
        clientSecret: this.paymentGetTokenToken,
        appearance: {},
      };
    },
    confirmParams: function() {
      let address = this.user.address;
      return {
        return_url: entryPoint + 'payment/result',
        payment_method_data: {
          billing_details: {
            name: this.user.fullName,
            email: this.user.email,
            address: {
              line1: address.address,
              line2: '',
              city: address.city,
              state: address.state,
              country: address.country,
              postal_code: address.zipcode,
            },
            phone: '',
          },
        },
      };
    },
    showFaqDialog: function() {
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        loader: false, // set to true if you want the dialog to show a loader after click on "proceed"
        reverse: false, // switch the button positions (left to right, and vise versa)
        okText: 'FAQ',
        cancelText: 'Later',
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        type: 'basic', // coming soon: 'soft', 'hard'
        verification: 'very much so', // for hard confirm, user will be prompted to type this to enable the proceed button
        verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
        clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
        backdropClose: true, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
        customClass: '', // Custom class to be injected into the parent node for the current dialog instance
      };

      let that = this;
      this.$dialog
        .confirm(
          'You get two sessions in the same simulator, both sessions will have the same questions. Please read our complete FAQ before making a purchase.',
          options
        )
        .then(function() {
          let routeData = that.$router.resolve({ name: 'Faq' });
          window.open(routeData.href, '_blank');
        })
        .catch(function() {});
    },
    showPaymentIframe: function() {
      return this.boxes.faq && this.boxes.terms;
    },
  },
  watch: {
    discountCodeIsLoading: function() {
      this.boxes.terms = false;
      this.boxes.faq = false;
    },
    paymentGetTokenToken: function(tokenNew) {
      if (tokenNew === null) {
        return;
      }
      if (!this.paymentHasClientToken) {
        this.paymentHasClientToken = true;
      }
    },
    orderGetIsLoading(newValue) {
      if (!newValue) {
        this.firstTimeLoaded = true;
        if (this.order) {
          this.$store.dispatch('order/getToken/get', this.order.uuid);
          if (!this.order.rebuyDiscount && !this.faqNoticeShown) {
            this.faqNoticeShown = true;
            this.showFaqDialog();
          }
        }
      }
    },
    order(newValue) {
      if (!newValue) {
        router.push({
          name: 'Dashboard',
        });
      } else if (
        this.orderAmount !== null &&
        this.orderAmount !== newValue.amount
      ) {
        this.iframeCacheBuster++;
      }
      if (this.order) {
        this.orderAmount = this.order.amount;
      }
    },
  },
  mounted() {
    this.getOrders();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
.spinner {
  margin: 0 auto;
  width: 60px;
}
.spring-spinner-rotator {
  border-right-color: #000000 !important;
  border-top-color: #000000 !important;
}
h1 {
  font-size: 1.5rem;
  color: var(--secondary-color);
  text-align: center;
  margin-bottom: 2rem;
}

hr {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.boxes {
  label {
    margin-left: -20px;
  }
}

.cta {
  font-size: 1.2rem;
}

.btn {
  padding: 12px 0 !important;
}

.payment-gateway-iframe-wrapper {
  margin: 30px auto 0 auto;

  .payment-gateway-iframe {
    border: 0;
    overflow-x: scroll;
    width: 100%;
    height: 600px;
  }
}

.container {
  width: 100%;
  padding: 0;
  margin: 0 auto;
}

.text-highlight {
  color: var(--ckad-color-blue);
}

@media screen and (max-width: 768px) {
  .payment-gateway-iframe-wrapper {
    width: 90%;
  }
  .list-summarize {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
</style>
