<template>
  <div class="">
    <b-container v-if="!isLoading">
      <b-row id="hero-section" class="align-items-center py-5 px-2">
        <b-col md="12" sm="12" class="mr-auto">
          <h1 class="display-4 font-weight-bold text-dark">Slack</h1>

          <br /><br />

          <p>
            Join our Slack channels <b>#cks</b>, <b>#cka</b>, <b>#ckad</b>,
            <b>#lfcs</b> or <b>#lfct</b>:
          </p>

          <br />

          <a :href="getSlackLink()" target="_blank" class="small">
            <font-awesome-icon
              style="color: #3f3d56"
              :icon="['fab', 'slack']"
              size="10x"
            />
          </a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: mapGetters({
    isLoading: 'feature/list/isLoading',
  }),
  mounted() {
    this.$store.dispatch('feature/list/getFeatures');
  },
  methods: {
    getSlackLink() {
      let func = this.$store.getters['feature/list/featureByName'];
      if (func('slack_invite_link')) {
        return func('slack_invite_link')['data'];
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
ul li {
  list-style-type: square;
}
</style>
