<template>
  <div>
    <div class="alert info">
      <span class="action pull-right" v-on:click="toggle()">
        <font-awesome-icon
          v-if="show"
          icon="times-circle"
          size="1x"
          class="mr-3"
        />
        <font-awesome-icon
          v-else
          icon="info-circle"
          class="mr-3"
          style="font-size: 1.5em"
        />
      </span>
      <ul class="info-list" v-if="show">
        <li>Treat the simulator like the real exam: come prepared</li>
        <li>
          <strong>
            You get two sessions in the same simulator, both have the same
            questions
          </strong>
        </li>
        <li>
          Try to solve as many questions by yourself as fast as possible before
          checking our solutions for the best learning effect
        </li>
        <li>
          The solutions and score will be available once the session countdown
          reaches 0 or if you stop it before
        </li>
        <li>
          Our simulators are harder than the real exam, more
          <span class="link-normal"
            ><a href="https://killer.sh/faq" target="_blank">here</a></span
          >.
        </li>
      </ul>
      <div v-else style="margin-bottom: 39px"></div>
    </div>
  </div>
</template>

<script>
import VueCookies from 'vue-cookies';
export const COOKIE_NAME = 'dashboard_panel_visibility';
export default {
  name: 'Info',
  data: function() {
    return {
      show: true,
    };
  },
  methods: {
    toggle: function() {
      this.show = !this.show;
      if (this.show) {
        VueCookies.remove(COOKIE_NAME);
      } else {
        VueCookies.set(COOKIE_NAME, 'false', '90d');
      }
    },
  },
  created: function() {
    let cookie = VueCookies.get(COOKIE_NAME);
    this.show = cookie !== 'false';
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
.action {
  cursor: pointer;
  padding-top: 5px;
}
.action:hover {
  color: darkgrey;
}
.info-list {
  font-size: 0.9rem;
  margin: 0;
  background-color: #f3f0ff;
  padding: 20px;
  color: #464646;
  li {
    margin-bottom: 5px;
  }
  li::marker {
    content: '$ ';
  }
}
.link-normal {
  a:link {
    color: #464646;
    background-color: transparent;
    text-decoration: underline;
  }

  a:visited {
    color: #464646;
    background-color: transparent;
    text-decoration: underline;
  }

  a:hover {
    color: #464646;
    background-color: transparent;
    text-decoration: underline;
  }

  a:active {
    color: #464646;
    background-color: transparent;
    text-decoration: underline;
  }
}
</style>
