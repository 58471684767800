import { render, staticRenderFns } from "./Poll.vue?vue&type=template&id=1951b66a&scoped=true"
import script from "./Poll.vue?vue&type=script&lang=js"
export * from "./Poll.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1951b66a",
  null
  
)

export default component.exports