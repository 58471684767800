import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

const get = ({ commit }, cluster) => {
  commit(types.TOGGLE_LOADING);
  commit(types.SET_ERROR, null);

  let data = {
    cluster: cluster,
  };
  let options = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  fetch('cluster/content-data/get', options)
    .then(response => {
      return response.json();
    })
    .then(data => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_DATA, data);
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      try {
        let json = JSON.parse(e.message);
        if (json.error) {
          commit(types.SET_ERROR, json.error);
        }
      } catch {
        commit(types.SET_ERROR, true);
      }
    });
};

export { get };
