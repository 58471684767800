import * as types from '@/store/modules/status/maintenance/mutation_types';

const checkCurrentPageShouldShowMessage = ({ commit }, currentRouteName) => {
  let routesNotShow = [
    'Home',
    'Logout',
    'CkadSimulatorAbout',
    'CkaSimulatorAbout',
    'CksSimulatorAbout',
    'Legal',
    'Faq',
    'Terms',
    'Contact',
    'Redirect',
    'AttendeeView',
    'AttendeeSolution',
    'AttendeeScore',
    'CoursePreview',
    'CourseTipsAttendee',
  ];

  let show = false;
  if (currentRouteName) {
    show = routesNotShow.indexOf(currentRouteName) === -1;
  }
  commit(types.SET_CURRENT_PAGE_SHOULD_SHOW_MAINTENANCE, show);
};

export { checkCurrentPageShouldShowMessage };
