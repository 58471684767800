import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

const send = ({ commit }, data) => {
  commit(types.TOGGLE_LOADING);
  commit(types.SET_ERROR, false);
  let options = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  fetch('attendee/latency', options)
    .then(response => {
      return response.json();
    })
    .then(() => {
      commit(types.TOGGLE_LOADING);
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

export { send };
