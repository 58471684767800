<template>
  <div class="root">
    <b-container>
      <div class="lfct-bg-img">
        <div class="card-inner">
          <b-row id="hero-section" style="margin: 0 15px;">
            <b-col md="8" sm="12" class="hero-text ">
              <small>About the</small><br />
              <span><span class="text-highlight">LFCT</span> Simulator </span>
              <h5 class="mt-3">
                Linux Foundation Certified Cloud Technician
                <small>Simulator</small>
              </h5>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-card no-body class="home-card">
        <b-row no-gutters class="card-inner align-items-center">
          <b-col md="6">
            <b-card-body>
              <b-card-text>
                You'll gain access to a LFCT simulator environment containing 20
                scenarios and their solutions. You'll have access to two
                identical test sessions (with the very same questions) which you
                can start independently over the period of one year.<br /><br />
                The idea is that you solve as many scenarios as possible in the
                shortest amount of time, just as you need in the real exam.<br /><br />
                <strong
                  >This is the perfect preparation for the LFCT exam!</strong
                >
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6" class="card-two text-center">
            <div style="margin: 50px 0">
              <a
                href="https://training.linuxfoundation.org/certification/linux-foundation-certified-cloud-technician"
                target="_blank"
              >
                <b-img
                  src="@/assets/lfct_logo.png"
                  style="max-width: 200px"
                  fluid
                ></b-img>
              </a>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card dark">
        <div class="background"></div>
        <b-row no-gutters class="card-inner align-items-center">
          <b-col md="6" class="card-two text-center">
            <div class="big-text">?</div>
          </b-col>
          <b-col md="6">
            <b-card-body title="Is this for you?">
              <b-card-text>
                Are you in preparation for the LFCT? Perfect, test your
                knowledge, get a feeling for what will be coming at you or learn
                more about Linux solutions very helpful in the cloud space.<br /><br />
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card content-listview">
        <b-row no-gutters class="card-inner">
          <b-col md="12">
            <b-card-body title="What you'll get">
              <b-card-text>
                <LfctSimulatorContentList></LfctSimulatorContentList>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card dark">
        <div class="background"></div>
        <b-row no-gutters class="card-inner align-items-center">
          <b-col md="6" class="card-two">
            <b-card-img
              src="@/assets/screenshots/lfct_question1.png"
              class="rounded-0"
              style="padding: 50px"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="20 unique scenarios and detailed solutions">
              <b-card-text>
                We provide you with a set of 20 unique questions and scenarios
                to solve. For some questions you have to start completely from
                scratch, in others you need to work with given resources or
                configuration and alter those. Just like in the real exam.<br /><br />
                There are detailed solutions to all scenarios. If you have
                questions or suggestions about these you can also contact us.
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card">
        <b-row no-gutters class="card-inner align-items-center">
          <b-col md="6">
            <b-card-body title="Score">
              <b-card-text>
                You'll get a calculated score of all questions solved. In
                addition you get a detailed list of the solved/unsolved
                sub-tasks for each question.
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6">
            <b-card-img
              src="@/assets/screenshots/lfct_score.png"
              class="rounded-0"
              style="padding: 50px"
            ></b-card-img>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card dark">
        <div class="background"></div>
        <b-row no-gutters class="card-inner align-items-center">
          <b-col md="6">
            <b-card-img
              src="@/assets/screenshots/lfct_terminal.png"
              class="rounded-0"
              style="padding: 50px"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="The LFCT Interface">
              <b-card-text>
                Get used to a very similar LFCT interface before the real exam
                so you won't waste any precious time. Time is rare during the
                exam and you have to be fast.
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card">
        <b-row no-gutters class="card-inner align-items-center">
          <b-col md="6">
            <b-card-body title="36 hours access to the environment">
              <b-card-text>
                Once activated a session is open for a total of 36 after you
                started it. The 36 hours are time based and not usage based.<br /><br />
                When the session ends after 120 minutes you'll get access to the
                solutions. You'll still have 34 more hours (36 hours total)
                environment access to learn and study.<br /><br />
                We decided to do this so you have enough time to play around
                with the environment and to study the scenarios/solutions
                properly.
                <br /><br /><br /><br />
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6" class="text-center">
            <div class="big-text" style="font-size: 5em; padding-top: 30px;">
              36h
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card dark">
        <div class="background"></div>
        <b-row no-gutters class="card-inner">
          <b-col md="6"></b-col>
          <b-col md="6">
            <b-card-body title="Why taking the LFCT">
              <b-card-text>
                The amazing thing about the LFCT is that it's "hands-on" and
                practical. So you actually proof that you can solve issues in
                the real world!<br /><br />
                During the exam you have no internet access, only man pages and
                installed docs are available.<br /><br />
                You’ll be provided with various scenarios to solve for which you
                have 120 minutes time to solve.
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card">
        <b-row no-gutters class="card-inner">
          <b-col md="6" class="text-center">
            <p style="margin-top: 50px; text-align: left">
              <strong class="price-text">29.99</strong
              ><strong class="price-text">$</strong> <br />
            </p>
            <br /><br />
          </b-col>
          <b-col md="6">
            <b-card-body title="How much does this simulator cost?">
              <b-card-text class="text-center">
                <div>
                  <router-link
                    :to="{ name: 'Dashboard' }"
                    class="btn card-cta fit-content d-inline-block"
                    style="margin-top: 80px"
                  >
                    Enroll Now
                  </router-link>
                  <br /><br />
                </div>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="home-card dark">
        <div class="background"></div>
        <b-row no-gutters class="card-inner">
          <b-col md="6">
            <b-card-body title="More questions?">
              <b-card-text>
                Feel free to contact us at
                <router-link :to="{ name: 'Support' }">support</router-link>
                <br />
                <router-link
                  :to="{ name: 'Faq' }"
                  class="btn card-cta fit-content d-inline-block"
                  style="margin-top: 80px"
                >
                  FAQ
                </router-link>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6" class="text-center">
            <div class="big-text">?</div>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LfctSimulatorContentList from '@/components/static/LfctSimulatorContentList';

export default {
  name: 'LfctSimulatorAbout',
  components: {
    LfctSimulatorContentList,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'authentication/status/isAuthenticated',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
.root {
  background-color: white;
  padding-bottom: 50px;
}

.list-summarize li {
  display: none;
  padding-top: 20px;
  margin-top: 20px;
}
.big-text {
  color: var(--secondary-color);
  font-size: 10rem;
  font-weight: bold;
  padding: 0;
}
.price-text {
  color: var(--secondary-color);
  font-size: 4rem;
  font-weight: bold;
  padding: 0;
}
.hero-text {
  font-size: 4rem;
}

.home-container {
  background-color: #fff;
  padding-bottom: 50px;
}

.text-highlight {
  color: var(--ckad-color-blue);
}

#hero-section {
  padding: 80px 0;
}

.lfct-why {
  width: 70%;
}

.container {
  max-width: 100% !important;
  padding: 0 !important;
}

.lfct-bg-img {
  background-image: url('~@/assets/ks_lfct_bg.jpg');
  background-position: 2% 65%;
  background-size: cover;
}
.card {
  border: 0 !important;
  padding: 100px 15px;
  background-color: transparent;
}

.home-card {
  margin: 100px 0;
  position: relative;
  .background {
    background-color: rgba(95, 156, 199, 0.13);
    transform: skewY(-3deg);
    top: 50px;
    left: 0;
    right: 0;
    bottom: 50px;
    position: absolute;
  }
  &.dark {
    padding: 200px 0;
  }
}

.card-inner {
  margin: 0 auto;
  max-width: 1140px;
  min-width: 1024px;
}

@media screen and (max-width: 1024px) {
  .card-inner {
    min-width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .btn {
    width: 90%;
  }
  .cta-preview .btn {
    width: 100%;
  }
  .lfct-why {
    width: 100%;
  }
  .card-inner {
    min-width: auto;
  }
}
</style>
