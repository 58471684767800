export const error = state => state.error;
export const isLoading = state => state.isLoading;
export const data = state => state.data;
export const order = state => {
  if (state.data && state.data.length >= 1) {
    for (let i = 0; i < state.data.length; i++) {
      let item = state.data[i];
      if (item.usedAt === null) {
        return item;
      }
    }
  }
};
