import list from './list/index';
import del from './delete/index';
import notificationOptIn from './notificationOptIn/index';
import edit from './edit/index';

export default {
  namespaced: true,
  modules: {
    list,
    delete: del,
    notificationOptIn,
    edit,
  },
};
