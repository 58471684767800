<template>
  <div v-if="!this.userHasNecessaryConsents()">
    <UserConsent v-bind:termsAcceptNecessary="true"></UserConsent>
  </div>
  <div v-else class="wrapper">
    <div v-if="!featureIsLoading">
      <div v-if="!userHasGoodProfile()">
        <p class="alert alert-info">
          Please check and save your account information in order to make a
          purchase.
        </p>
        <UserProfileEdit v-bind:userPassed="user"></UserProfileEdit>
      </div>
      <div v-else>
        <div v-if="error" class="alert alert-warning">{{ error }}</div>
        <span v-else>
          Preparing order...
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserProfileEdit from '@/components/user/AccountEditForm';
import UserConsent from '@/components/user/UserConsent';

export default {
  components: {
    UserProfileEdit,
    UserConsent,
  },
  computed: {
    ...mapGetters({
      isLoading: 'order/create/isLoading',
      error: 'order/create/error',
      product: 'order/selection/data',
      user: 'user/list/data',
      featureIsLoading: 'feature/list/isLoading',
      needsTermsAndConditionsAccept:
        'consent/list/needsTermsAndConditionsAccept',
    }),
  },
  methods: {
    ...mapActions({
      create: 'order/create/create',
      getFeatures: 'feature/list/getFeatures',
    }),
    userHasGoodProfile: function() {
      return this.user.providedAllDataToMakePurchase === true;
    },
    userHasNecessaryConsents: function() {
      return !this.needsTermsAndConditionsAccept;
    },
    readyToCreateOrder: function() {
      return this.userHasGoodProfile() && this.userHasNecessaryConsents();
    },
    createOrder: function() {
      this.create(this.product['@id']);
    },
  },
  created() {
    if (this.readyToCreateOrder()) {
      this.createOrder();
    }
    this.getFeatures();
  },
  watch: {
    user() {
      if (this.readyToCreateOrder()) {
        this.createOrder();
      }
    },
    needsTermsAndConditionsAccept() {
      if (this.readyToCreateOrder()) {
        this.createOrder();
      }
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';
ul {
  padding: 0;
  list-style: none;
  li {
    display: inline;
  }
}
.item {
  cursor: pointer;
}
</style>
