<template>
  <div class="wrapper">
    <h1>Pricing</h1>

    <br />

    <h2>
      Included simulators with your Linux Foundation exam
    </h2>
    <p>
      If you purchased the CKS|CKA|CKAD|LFCS|LFCT exam, two simulator sessions
      will be already included. You'll find these in your Linux Foundation Exam
      Preparation Checklist. For more information you can check
      <a
        href="https://training.linuxfoundation.org/announcements/linux-foundation-kubernetes-certifications-now-include-exam-simulator"
        target="_blank"
        >The Simulator Announcement</a
      >
      ,
      <a
        href="https://docs.linuxfoundation.org/tc-docs/certification/faq-cka-ckad-cks#is-there-training-to-prepare-for-the-certification-exam"
        target="_blank"
        >The CKA CKAD CKS Faq</a
      >
      and
      <a
        href="https://docs.linuxfoundation.org/tc-docs/certification/frequently-asked-questions-lfct#where-can-i-find-practice-questions-for-lfct"
        target="_blank"
        >The LFCT Faq</a
      >. For questions you can also contact the
      <a href="https://trainingsupport.linuxfoundation.org" target="_blank">
        Linux Foundation Support</a
      >.
    </p>
    <b-img
      src="@/assets/lf_exam_prep_list_sisimulators.png"
      width="400px"
      fluid
      style="border: 1px solid lightgrey"
    ></b-img>

    <br /><br />
    <hr />
    <h2>How much does this cost?</h2>
    <p>29.99$ for two CKS sessions.</p>
    <p>29.99$ for two CKA sessions.</p>
    <p>29.99$ for two CKAD sessions.</p>
    <p>29.99$ for two LFCS sessions.</p>
    <p>29.99$ for two LFCT sessions.</p>

    <hr />
    <h2>Can I pay in another currency than USD?</h2>
    <p>
      We only offer payments in USD. But the bank of your credit card will
      perform automatic currency conversion. Please contact your bank for info
      about additional costs.
    </p>

    <hr />
    <h2 class="font-weight-bold h5">
      Do you offer bulk or voucher purchases?
    </h2>
    <p>
      Yes, we offer companies to purchase vouchers in bulk. Any user using a
      voucher will gain simulator access without payment necessary. Please
      contact us on
      <router-link :to="{ name: 'Support' }">support</router-link>.<br />
    </p>
  </div>
</template>

<script>
export default {};
</script>
