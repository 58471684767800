import * as types from './mutation_types';

export default {
  [types.RESET](state) {
    Object.assign(state, {
      name: '',
    });
  },

  [types.SET_NAME](state, name) {
    Object.assign(state, { name });
  },
};
