<template>
  <div class="home-container">
    <b-container>
      <b-row id="hero-section">
        <b-col sm="12" class="hero-text">
          <span class="text-highlight">CKS CKA CKAD LFCS LFCT</span><br />
          Simulators
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col sm="12" md="6">
          Killer Shell provides simulators for Linux Foundation Exams. Verify
          your knowledge, learn from detailed solutions and study in very
          similar environments
        </b-col>
        <b-col sm="12" md="6">
          <b-card-img
            src="@/assets/happylearning.png"
            class="rounded-0 happylearning"
          ></b-card-img>
        </b-col>
      </b-row>

      <b-card no-body class="overflow-hidden home-card" style="border: 0">
        <b-row no-gutters>
          <b-col md="6">
            <b-card-img
              src="@/assets/k8s_cks_logo_s.png"
              class="rounded-0"
              v-on:click="toAboutCksClick"
              style="padding: 0 20px; max-width: 230px; float: left"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="CKS Simulator">
              <b-card-text>
                Our CKS simulator will provide you with an environment
                containing 22 scenarios and their solutions. Verify your K8s
                security skills and prepare for the CKS exam.
                <router-link
                  :to="{ name: 'CksSimulatorAbout' }"
                  class="btn card-cta fit-content"
                >
                  Learn More
                </router-link>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="overflow-hidden home-card" style="border: 0">
        <b-row no-gutters>
          <b-col md="6">
            <b-card-img
              src="@/assets/k8s_cka_logo_s.png"
              class="rounded-0"
              v-on:click="toAboutCkaClick"
              style="padding: 0 20px; max-width: 250px; float: left"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="CKA Simulator">
              <b-card-text>
                We provide you with access to a test environment which comes
                close to the real one. Our CKA Simulator comes with 25 complex
                scenarios to solve in 120 minutes plus additional 5 extra ones.
                After the test session you will get access to detailed
                solutions.
                <router-link
                  :to="{ name: 'CkaSimulatorAbout' }"
                  class="btn card-cta fit-content"
                >
                  Learn More
                </router-link>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="overflow-hidden home-card" style="border: 0">
        <b-row no-gutters>
          <b-col md="6">
            <b-card-img
              src="@/assets/k8s_ckad_logo_s.png"
              class="rounded-0"
              v-on:click="toAboutCkadClick"
              style="padding: 0 20px; max-width: 250px"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="CKAD Simulator">
              <b-card-text>
                We provide you with access to a test environment which comes
                really close to the original one. Our CKAD Simulator has 20
                complex questions/scenarios to solve in 120 minutes. After the
                session you will get access to detailed solutions.
                <router-link
                  :to="{ name: 'CkadSimulatorAbout' }"
                  class="btn card-cta fit-content"
                >
                  Learn More
                </router-link>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="overflow-hidden home-card" style="border: 0">
        <b-row no-gutters>
          <b-col md="6">
            <b-card-img
              src="@/assets/lfcs_logo.png"
              class="rounded-0"
              v-on:click="toAboutLfcsClick"
              style="padding: 0 20px; max-width: 200px; float: left; margin: 30px 0 0 30px"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="LFCS Simulator">
              <b-card-text>
                Our brand new LFCS simulator will provide you with a practice
                environment containing 20 scenarios and solutions. Verify your
                Linux SysAdmin skills and prepare for the exam.
                <router-link
                  :to="{ name: 'LfcsSimulatorAbout' }"
                  class="btn card-cta fit-content"
                >
                  Learn More
                </router-link>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="overflow-hidden home-card" style="border: 0">
        <b-row no-gutters>
          <b-col md="6">
            <b-card-img
              src="@/assets/lfct_logo.png"
              class="rounded-0"
              v-on:click="toAboutLfctClick"
              style="padding: 0 20px; max-width: 200px; float: left; margin: 30px 0 0 30px"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body title="LFCT Simulator">
              <b-card-text>
                Our new LFCT simulator will provide you with an environment
                containing 20 scenarios and their solutions. Verify your Linux
                cloud skills and prepare for the LFCT exam.
                <router-link
                  :to="{ name: 'LfctSimulatorAbout' }"
                  class="btn card-cta fit-content"
                >
                  Learn More
                </router-link>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col
          md="12"
          sm="12"
          class="text-center"
          style="margin: 50px 0 150px 0"
        >
          <div class="center-button">
            <div v-if="isAuthenticated">
              <router-link
                :to="{ name: 'Dashboard' }"
                class="btn hero-cta fit-content pull-right"
              >
                Preview Questions
              </router-link>
            </div>
            <div v-else>
              <router-link
                :to="{ name: 'Dashboard' }"
                class="btn hero-cta fit-content pull-right"
              >
                Login to preview scenarios
              </router-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/_global.scss';

.happylearning {
  padding: 0 20px 0 0;
  margin-top: -50px;
  max-width: 300px;
  float: right;
}

.home-card {
  img {
    cursor: pointer;
  }
}

.center-button {
  width: 300px;
  margin: 0 auto;
}

.k8s-logo {
  margin: 20px 20px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
}

.flash-card-wrapper {
  justify-content: space-between;

  .flash-arrow {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    align-items: center;
    color: var(--primary-color);
    font-weight: bold;
  }

  .flash-card {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  .flash-card:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .big-text {
    color: var(--secondary-color);
    font-size: 3.2rem;
    font-weight: bold;
  }

  .big2-text {
    color: var(--secondary-color);
    font-size: 2.5rem;
    font-weight: bold;
    padding-bottom: 20px;
  }
}

.hero-text {
  font-size: 4rem;
}

.card-widget {
  margin: 30px auto 0 auto;
}

.home-card {
  margin: 100px 0;
  padding: 50px 0;
}

.home-container {
  background-color: #fff;
  padding-bottom: 50px;
}

.text-highlight {
  color: var(--ckad-color-blue);
}

#hero-section {
  padding: 170px 0 0 0;
}

@media screen and (max-width: 768px) {
  .happylearning {
    margin-top: 50px;
    float: left;
  }
  .btn {
    width: 100%;
  }
  .hero-cta {
    font-size: 0.9rem;
    margin: 1.1rem auto;
  }

  .hero-text {
    font-size: 2.5rem;
  }

  #hero-section {
    padding: 30px 0;
  }

  .flash-card {
    .card {
      max-width: 100% !important;
    }
  }
  .k8s-logo {
    width: 25%;
  }
  .center-button {
    width: 100%;
    margin: 0 auto;
  }
}
</style>

<script>
import router from '@/router/router';
import { mapGetters } from 'vuex';

export default {
  methods: {
    toAboutLfctClick: function() {
      router.push({ name: 'LfctSimulatorAbout' });
    },
    toAboutLfcsClick: function() {
      router.push({ name: 'LfcsSimulatorAbout' });
    },
    toAboutCksClick: function() {
      router.push({ name: 'CksSimulatorAbout' });
    },
    toAboutCkaClick: function() {
      router.push({ name: 'CkaSimulatorAbout' });
    },
    toAboutCkadClick: function() {
      router.push({ name: 'CkadSimulatorAbout' });
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'authentication/status/isAuthenticated',
    }),
  },
};
</script>
