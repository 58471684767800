<template>
  <!-- ################### also check ViewCourseAnswers.vue when making changes ################### -->
  <div class="root">
    <div v-if="error" style="padding: 100px">
      <p v-if="error === 'wrong_state'" class="alert alert-info">
        This page can only be accessed while the environment is active. Log into
        your killer.sh profile to see the solutions.
      </p>
      <p v-else-if="error === 'session_running'" class="alert alert-info">
        The solutions will be available once the countdown reaches 0 or if you
        end the session before.
      </p>
      <p v-else class="alert alert-danger">Not Found</p>
    </div>
    <div v-else-if="!showList || isLoading" style="padding-top: 50px">
      <Spinner></Spinner>
    </div>
    <div v-else>
      <SolutionModule
        v-bind:showScoreLink="false"
        v-bind:attendee="attendee"
        v-bind:course="course"
        v-bind:courseVersion="attendee.courseVersion"
        v-bind:courseUuid="null"
      ></SolutionModule>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '@/components/util/Spinner';
import SolutionModule from '@/components/attendee/solution/SolutionModule';
import * as appTemplateMutationTypes from '@/store/modules/app/template/mutation_types';

export default {
  components: {
    SolutionModule,
    Spinner,
  },
  data: function() {
    return {
      uuid: this.$route.params.uuid,
      showList: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'attendee/clusterData/isLoading',
      error: 'attendee/clusterData/error',
      attendee: 'attendee/clusterData/attendee',
      course: 'attendee/clusterData/course',
    }),
  },
  created() {
    this.$store.commit(
      'app/template/' + appTemplateMutationTypes.SET_NAME,
      'template-no-header-no-footer'
    );
    this.$store.dispatch('attendee/clusterData/get', '/clusters/' + this.uuid);
    setTimeout(() => {
      this.showList = true;
    }, 3000);
  },
  beforeDestroy: function() {
    this.$store.commit('app/template/' + appTemplateMutationTypes.SET_NAME, '');
  },
};
</script>

<style lang="scss" scoped>
.root {
  background-color: white;
  min-height: 100vh;
}
.content {
  max-width: 860px;
  margin: 0 auto;
  padding-top: 50px;
}
</style>
