<template>
  <!-- ################### also check Score.vue when making changes ################### -->
  <div>
    <div v-if="error" style="margin: 100px">
      <p v-if="error === 'wrong_state'" class="alert alert-info">
        This page can only be accessed while the environment is active. Log into
        your killer.sh profile to see your last recorded score.
      </p>
      <p v-else-if="error === 'session_running'" class="alert alert-info">
        The score will be available once the countdown reaches 0 or if you end
        the session before.
      </p>
      <p v-else class="alert alert-danger">Not Found</p>
    </div>
    <div v-else-if="isLoading" style="margin-top: 50px">
      <Spinner></Spinner>
    </div>
    <ScoreCheckmarks
      v-else-if="attendee"
      v-bind:attendee="this.attendee"
      v-bind:publicAccessed="true"
    ></ScoreCheckmarks>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ScoreCheckmarks from '@/components/attendee/score/ScoreCheckmarks';
import Spinner from '@/components/util/Spinner';
import * as appTemplateMutationTypes from '@/store/modules/app/template/mutation_types';

export default {
  components: {
    ScoreCheckmarks,
    Spinner,
  },
  data: function() {
    return {
      uuid: this.$route.params.uuid,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'attendee/clusterData/isLoading',
      error: 'attendee/clusterData/error',
      attendee: 'attendee/clusterData/attendee',
    }),
  },
  created() {
    this.$store.commit(
      'app/template/' + appTemplateMutationTypes.SET_NAME,
      'template-no-header-no-footer'
    );
    this.$store.dispatch('attendee/clusterData/get', '/clusters/' + this.uuid);
  },
  beforeDestroy: function() {
    this.$store.commit('app/template/' + appTemplateMutationTypes.SET_NAME, '');
  },
};
</script>

<style scoped></style>
