import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';

const sendEmail = ({ commit }, email) => {
  commit(types.TOGGLE_LOADING);

  let options = {
    method: 'GET',
  };
  fetch('/auth/email/generate/' + email, options)
    .then(response => {
      return response.json();
    })
    .then(() => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_FINISHED, true);
    })
    .catch(() => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, true);
    });
};

const resetAction = ({ commit }) => {
  commit(types.RESET);
};

export { sendEmail, resetAction };
