<template>
  <span class="inactive"></span>
</template>

<script>
import router from '@/router/router';
import { mapGetters } from 'vuex';

export default {
  name: 'Inactive',
  data: function() {
    return {
      intervalInactivity: null,
    };
  },
  computed: {
    ...mapGetters({
      inactivityLastActionAt: 'attendee/run/inactivityLastActionAt',
    }),
    inactivityConfirmationVisible: {
      get() {
        return this.$store.state.attendee.run.inactivityConfirmationVisible;
      },
      set(newValue) {
        return this.$store.dispatch(
          'attendee/run/inactivityConfirmationVisible',
          newValue
        );
      },
    },
    inactivityConfirmationVisibleHideIframe: {
      get() {
        return this.$store.state.attendee.run
          .inactivityConfirmationVisibleHideIframe;
      },
      set(newValue) {
        return this.$store.dispatch(
          'attendee/run/inactivityConfirmationVisibleHideIframe',
          newValue
        );
      },
    },
  },
  mounted: function() {
    this.intervalInactivity = setInterval(
      function() {
        this.checkInactivity();
      }.bind(this),
      1000
    );
    this.$store.dispatch('attendee/run/inactivityLogActionAction');
    this.$store.dispatch('attendee/run/inactivityConfirmationVisible', false);
    this.$store.dispatch(
      'attendee/run/inactivityConfirmationVisibleHideIframe',
      false
    );
    window.addEventListener('blur', this.windowPause);
    window.addEventListener('focus', this.windowPlay);
  },
  props: {
    attendee: {
      required: true,
    },
  },
  methods: {
    logAction: function() {
      this.$store.dispatch('attendee/run/inactivityLogActionAction');
    },
    windowPause: function() {
      this.logAction();
    },
    windowPlay: function() {
      this.logAction();
    },
    terminalActive: function() {
      return this.attendee.terminal.status === 'active';
    },
    checkInactivity: function() {
      let current = new Date().getTime();
      let ms = current - this.inactivityLastActionAt;
      if (
        this.terminalActive &&
        // ms > 1 * 10 * 1000 &&
        ms > 15 * 60 * 1000 &&
        this.inactivityConfirmationVisible === false
      ) {
        this.showInactivityConfirmation();
      }
    },
    showInactivityConfirmation: function() {
      let that = this;
      setTimeout(function() {
        if (that.inactivityConfirmationVisible) {
          that.inactivityConfirmationVisibleHideIframe = true;
        }
        // }, 1 * 10 * 1000);
      }, 15 * 60 * 1000);

      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        loader: false, // set to true if you want the dialog to show a loader after click on "proceed"
        reverse: false, // switch the button positions (left to right, and vise versa)
        okText: 'Yes',
        cancelText: 'No',
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        type: 'basic', // coming soon: 'soft', 'hard'
        verification: 'very much so', // for hard confirm, user will be prompted to type this to enable the proceed button
        verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
        clicksCount: 1, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
        backdropClose: false, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
        customClass: '', // Custom class to be injected into the parent node for the current dialog instance
      };

      this.inactivityConfirmationVisible = true;
      this.$dialog
        .confirm('Session is inactive. Would you like to continue?', options)
        .then(function() {
          that.logAction();
          that.inactivityConfirmationVisible = false;
          if (that.inactivityConfirmationVisibleHideIframe) {
            that.inactivityConfirmationVisibleHideIframe = false;
          }
          that.refreshAttendee();
        })
        .catch(function() {
          clearInterval(that.intervalInactivity);
          router.push({
            name: 'Dashboard',
          });
        });
    },
    refreshAttendee: function() {
      if (!this.inactivityConfirmationVisible) {
        this.$store.dispatch('attendee/get/getAttendee', [
          this.attendee.uuid,
          true,
        ]);
      }
    },
  },
  beforeDestroy: function() {
    clearInterval(this.intervalInactivity);
  },
};
</script>

<style lang="scss" scoped></style>
