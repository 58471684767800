import list from './list';
import get from './get';
import activate from './activate';
import restart from './restart';
import sessionStart from './sessionStart';
import sessionStop from './sessionStop';
import notepad from './notepad';
import latency from './latency';
import run from './run';
import clusterData from './clusterData';
import clipboard from './clipboard';
import lfctOperator from './lfctOperator';

export default {
  namespaced: true,
  modules: {
    list,
    get,
    activate,
    restart,
    sessionStart,
    sessionStop,
    notepad,
    latency,
    run,
    clusterData,
    clipboard,
    lfctOperator,
  },
};
