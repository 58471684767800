<template>
  <div></div>
</template>

<script>
import $ from '@/../node_modules/jquery/dist/jquery.slim.js';
import tippy from '@/../node_modules/tippy.js/dist/tippy-bundle.umd.js';
import '@/../node_modules/tippy.js/dist/tippy.css';

export default {
  name: 'CodeCopy',
  created: function() {
    $(document).ready(function() {
      let svg =
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">\n' +
        '  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>\n' +
        '  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>\n' +
        '</svg>';

      tippy.delegate('body', {
        content: svg + ' copy',
        delay: 100,
        placement: 'right',
        allowHTML: true,
        target: '.copy-on-click',
        arrow: false,
      });

      let config = {
        childList: true,
        subtree: true,
      };
      let codeToCopyAndClick = function() {
        $('code:not([class])')
          .filter(function() {
            return $(this).parent('.hljs').length === 0;
          })
          .addClass('copy-on-click');
      };
      let observer = new MutationObserver(codeToCopyAndClick);
      observer.observe(document.body, config);
      codeToCopyAndClick();

      $('body').on('click', '.copy-on-click', function() {
        let $temp = $('<input>');
        $('body').append($temp);
        $temp.val($(this).text()).select();
        document.execCommand('copy');
        $temp.remove();
      });
    });
  },
};
</script>

<style lang="scss">
.tippy-box {
  transform: scale(0.8);
}
.code {
  color: black;
}
.copy-on-click {
  color: #396582;
  cursor: pointer;
  &:hover {
    background-color: #0f3366;
    color: white !important;
  }
}
</style>
