import * as types from './mutation_types';

export default {
  [types.RESET](state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      data: null,
    });
  },

  [types.SET_ERROR](state, error) {
    Object.assign(state, { error });
  },

  [types.TOGGLE_LOADING](state) {
    Object.assign(state, { isLoading: !state.isLoading });
  },

  [types.SET_QUESTION_STATE](state, questionState) {
    Object.assign(state, { questionState });
  },

  [types.SET_QUESTION_STATE_FOR_QUESTION](state, dataPassed) {
    let questionIndex = dataPassed[0];
    let questionStateForQuestion = dataPassed[1];
    let questionStateNew = Object.assign([], state.questionState);
    questionStateNew[questionIndex - 1] = questionStateForQuestion;
    state.questionState = questionStateNew;
  },
};
