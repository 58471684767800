export const SET_INACTIVITY_LOG_ACTION =
  'ATTENDEE_RUN_SET_INACTIVITY_LOG_ACTION';
export const SET_INACTIVITY_CONFIRMATION_VISIBLE =
  'ATTENDEE_RUN_SET_INACTIVITY_CONFIRMATION_VISIBLE';
export const SET_INACTIVITY_CONFIRMATION_VISIBLE_HIDE_IFRAME =
  'ATTENDEE_RUN_SET_INACTIVITY_CONFIRMATION_VISIBLE_HIDE_IFRAME';
export const SET_INSTRUCTION_SHOW = 'ATTENDEE_RUN_SET_INSTRUCTION_SHOW';
export const SET_NOTEPAD_SHOW = 'ATTENDEE_RUN_SET_NOTEPAD_SHOW';
export const SET_CHAT_SHOW = 'ATTENDEE_RUN_SET_CHAT_SHOW';
export const SET_ATTENDEE_TERMINAL_ADDRESSES =
  'ATTENDEE_RUN_SET_ATTENDEE_TERMINAL_ADDRESSES';
export const SET_ATTENDEE_TERMINAL_ADDRESS_POS =
  'ATTENDEE_RUN_SET_ATTENDEE_TERMINAL_ADDRESS_POS';
export const SET_QUESTION_INDEX = 'ATTENDEE_RUN_SET_QUESTION_INDEX';
export const SET_QUESTION_SELECTION_ENABLED =
  'ATTENDEE_RUN_SET_QUESTION_SELECTION_ENABLED';
