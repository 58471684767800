<template>
  <div class="wrapper">
    <h1>Maintenance</h1>

    <br /><br />
    <p>
      We're undergoing a short maintenance. Please check back again in a few
      minutes and reload the page.<br /><br />
      Sorry for the interruption!
    </p>
  </div>
</template>

<script>
export default {};
</script>
