import * as types from './mutation_types';
import VueCookies from 'vue-cookies';
import * as definitions from './definitions';

export default {
  [types.RESET](state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      isFinished: false,
    });
  },

  [types.SET_ERROR](state, error) {
    Object.assign(state, { error });
  },

  [types.SET_FINISHED](state, isFinished) {
    Object.assign(state, { isFinished });

    VueCookies.set(
      definitions.COOKIE_NAME_AUTH_EMAIL_STATE,
      Date.now(),
      '1h',
      null,
      null,
      null,
      'Strict'
    );
  },

  [types.TOGGLE_LOADING](state) {
    Object.assign(state, { isLoading: !state.isLoading });
  },
};
