import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';
import store from '@/store';

const edit = ({ commit }, consents) => {
  commit(types.TOGGLE_LOADING);
  commit(types.SET_VIOLATIONS, []);
  commit(types.SET_ERROR, null);
  setTimeout(function() {
    store.dispatch('consent/list/getConsents');
    commit(types.TOGGLE_LOADING);
  }, 1500);

  for (var i = 0; i < consents.length; i++) {
    var consent = consents[i];

    let options = {
      method: 'PUT',
      body: JSON.stringify(consent),
    };
    fetch('consents/' + consent.uuid, options)
      .then(response => {
        return response.json();
      })
      .then(() => {
        store.dispatch('user/list/getUserObject');
      })
      .catch(e => {
        commit(types.SET_ERROR, e.message);

        if (typeof e === 'object' && 'errors' in e) {
          commit(types.SET_VIOLATIONS, e.errors.violations);
        }
      });
  }
};

export { edit };
