<template>
  <div class="wrapper">
    <div>
      <b-img
        src="@/assets/linux-foundation-logo.png"
        fluid
        style="width: 50%"
        center
      ></b-img>
      <h1 style="margin-top: 80px">Request Linux Foundation Access</h1>
      <div>
        <span v-if="this.error">
          <div v-if="error" class="alert alert-danger">
            {{ error }}
          </div>
          <br /><br />
        </span>
        <span v-else>
          <p>
            Please wait while we authenticate you
            <font-awesome-icon icon="check-circle" size="1x" class="ml-3" />
          </p>
          <p>
            Please wait while we validate your access request
            <font-awesome-icon
              v-if="showCheck()"
              icon="check-circle"
              size="1x"
              class="ml-3"
            />
          </p>
          <br /><br />
          <Spinner></Spinner>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '@/components/util/Spinner';
import router from '@/router/router';

export default {
  name: 'AccessRequestLinuxFoundationCreate',
  components: {
    Spinner,
  },
  data: function() {
    return {
      code: '',
      type: '',
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'accessRequest/create/isLoading',
      error: 'accessRequest/create/error',
      success: 'accessRequest/create/success',
    }),
  },
  methods: {
    accessRequest: function() {
      let data = {
        code: this.code,
        type: this.type,
      };
      this.$store.dispatch('accessRequest/create/create', data);
    },
    showCheck: function() {
      return !this.isLoading && !this.error;
    },
  },
  created() {
    this.code = this.$route.query.code;
    this.type = this.$route.query.type;
    this.accessRequest();
  },
  watch: {
    success(newValue) {
      if (newValue) {
        setTimeout(function() {
          router.push({ name: 'Dashboard' });
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
.code {
  padding: 10px;
  height: 50px;
  width: 400px;
}
.info-list {
  font-size: 0.9rem;
  margin: 0;
  list-style: none;
  background-color: #f3f0ff;
  padding: 10px;
  color: #464646;
  li {
    margin-bottom: 5px;
  }
}
</style>
