<template>
  <span>
    <TerminalEntry
      v-if="
        currentQuestionState === 'open' || currentQuestionState === 'submitted'
      "
      v-bind:attendee="attendee"
      v-bind:attendeeTerminalAddressPassed="attendeeTerminalAddressPassed"
      v-bind:questionIndex="attendeeQuestionIndex"
      v-bind:questionState="currentQuestionState"
    ></TerminalEntry>
    <TerminalActive
      v-else-if="currentQuestionState === 'active'"
      v-bind:attendee="attendee"
      v-bind:attendeeTerminalAddressPassed="attendeeTerminalAddressPassed"
    ></TerminalActive>
    <TerminalLoading
      v-else-if="
        currentQuestionState === 'submitting' ||
          currentQuestionState === 'discarding' ||
          currentQuestionState === 'loading'
      "
      v-bind:attendee="attendee"
    ></TerminalLoading>
    <TerminalRestart
      v-else-if="
        currentQuestionState === 'restarting' ||
          currentQuestionState === 'submitted-restarting' ||
          currentQuestionState === 'discarded-restarting'
      "
      v-bind:attendee="attendee"
      v-bind:currentQuestionState="currentQuestionState"
    ></TerminalRestart>
  </span>
</template>

<script>
import TerminalEntry from '@/components/attendee/run/lfct/TerminalEntry';
import TerminalActive from '@/components/attendee/run/lfct/TerminalActive';
import TerminalLoading from '@/components/attendee/run/lfct/TerminalLoading';
import TerminalRestart from '@/components/attendee/run/lfct/TerminalRestart';
import { mapGetters } from 'vuex';

export default {
  name: 'Terminal',
  components: {
    TerminalEntry,
    TerminalActive,
    TerminalLoading,
    TerminalRestart,
  },
  props: {
    attendee: {
      required: true,
    },
    attendeeTerminalAddressPassed: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      attendeeQuestionIndex: 'attendee/run/questionIndex',
    }),
    currentQuestionState: function() {
      return this.$store.getters[
        'attendee/lfctOperator/questionStateForQuestion'
      ](this.attendeeQuestionIndex);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/_global.scss';
.terminal.lfct {
  padding: 30px;
  background-clip: content-box;
  background-color: #103367 !important;

  button {
    background-color: #2c99cc;
    border: 0 #2c99cc;
    color: white;
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
</style>
