<template>
  <!-- ################### also check ViewCourseAnswersPublicViaClusterUuid.vue when making changes ################### -->
  <div class="root">
    <div v-if="listError || attendeeError" class="alert alert-warning">
      An error occurred, please try again or contact support.
    </div>
    <div v-else>
      <div v-bind:style="getMainStyle()">
        <SolutionModule
          v-if="attendee"
          v-bind:showScoreLink="true"
          v-bind:attendee="attendee"
          v-bind:course="null"
          v-bind:courseVersion="attendee.courseVersion"
          v-bind:courseUuid="attendee.course.uuid"
        ></SolutionModule>
      </div>
      <div v-if="!showList" style="padding-top: 50px">
        <Spinner></Spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '@/components/util/Spinner';
import SolutionModule from '@/components/attendee/solution/SolutionModule';

export default {
  components: {
    SolutionModule,
    Spinner,
  },
  data: function() {
    return {
      uuid: this.$route.params.uuid,
      showList: false,
    };
  },
  computed: {
    ...mapGetters({
      listIsLoading: 'attendee/list/isLoading',
      listError: 'attendee/list/error',
      attendee: 'attendee/get/data',
      attendeeError: 'attendee/get/error',
    }),
  },
  created() {
    this.getAttendee();
    setTimeout(() => {
      this.showList = true;
    }, 3000);
  },
  methods: {
    getAttendee: function() {
      this.$store.dispatch('attendee/get/getAttendee', [this.uuid, true]);
    },
    getMainStyle() {
      if (this.showList) {
        return 'display: block !important;';
      } else {
        return 'display: none !important;';
      }
    },
    getLoadingStyle() {
      if (!this.showList) {
        return 'display: block !important;';
      } else {
        return 'display: none !important;';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.root {
  background-color: white;
  min-height: 100vh;
}
.content {
  max-width: 860px;
  margin: 0 auto;
  padding-top: 50px;
}
</style>
