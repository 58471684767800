import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';
import Vue from 'vue';
import store from '@/store';

const setQuestionStateActive = ({ commit }, questionIndex) => {
  commit(types.SET_QUESTION_STATE_FOR_QUESTION, [questionIndex, 'active']);
};

const setQuestionStateLoading = ({ commit }, questionIndex) => {
  commit(types.SET_QUESTION_STATE_FOR_QUESTION, [questionIndex, 'loading']);
};

const getState = ({ commit }, dataPassed) => {
  commit(types.TOGGLE_LOADING);

  let url = dataPassed[0];
  url = url + '/state';
  let options = {
    method: 'GET',
  };
  fetch(url, options)
    .then(response => {
      return response.json();
    })
    .then(response => {
      commit(types.TOGGLE_LOADING);
      if (response['Success'] === true) {
        commit(types.SET_QUESTION_STATE, response['State']['QuestionState']);
        // switch to the question that has a running operation atm
        // this could be necessary after browser tab reload during a restarting/submitting operation
        let questionIndexActiveOperation =
          store.getters[
            'attendee/lfctOperator/getQuestionIndexWithRunningOperation'
          ];
        if (questionIndexActiveOperation !== null) {
          store.dispatch(
            'attendee/run/setQuestionIndex',
            questionIndexActiveOperation + 1
          );
        }
      } else {
        Vue.prototype.$flashStorage.flash('Error during action', 'error', {
          timeout: 5000,
        });
      }
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
      Vue.prototype.$flashStorage.flash('Error during action', 'error', {
        timeout: 5000,
      });
    });
};

const submitResult = ({ commit }, dataPassed) => {
  commit(types.TOGGLE_LOADING);

  let url = dataPassed[0];
  let questionIndex = dataPassed[1];
  url = url + '/submit-result?vm=' + questionIndex;
  let options = {
    method: 'GET',
  };
  fetch(url, options)
    .then(response => {
      return response.json();
    })
    .then(response => {
      commit(types.TOGGLE_LOADING);
      if (response['Success'] === true) {
        commit(types.SET_QUESTION_STATE, response['State']['QuestionState']);
      } else {
        Vue.prototype.$flashStorage.flash('Error during action', 'error', {
          timeout: 5000,
        });
      }
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
      Vue.prototype.$flashStorage.flash('Error during action', 'error', {
        timeout: 5000,
      });
    });
};

const discardResult = ({ commit }, dataPassed) => {
  commit(types.TOGGLE_LOADING);

  let url = dataPassed[0];
  let questionIndex = dataPassed[1];
  url = url + '/discard-result?vm=' + questionIndex;
  let options = {
    method: 'GET',
  };
  fetch(url, options)
    .then(response => {
      return response.json();
    })
    .then(response => {
      commit(types.TOGGLE_LOADING);
      if (response['Success'] === true) {
        commit(types.SET_QUESTION_STATE, response['State']['QuestionState']);
      } else {
        Vue.prototype.$flashStorage.flash('Error during action', 'error', {
          timeout: 5000,
        });
      }
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
      Vue.prototype.$flashStorage.flash('Error during action', 'error', {
        timeout: 5000,
      });
    });
};

export {
  setQuestionStateActive,
  setQuestionStateLoading,
  getState,
  submitResult,
  discardResult,
};
