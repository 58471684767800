<template>
  <div>
    <span v-if="!hasSelectedProducts">
      <OrderSelection></OrderSelection>
    </span>
    <span v-else>
      <OrderCreate></OrderCreate>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderSelection from '@/components/order/OrderSelection';
import OrderCreate from '@/components/order/OrderCreate';

export default {
  components: {
    OrderSelection,
    OrderCreate,
  },
  computed: {
    ...mapGetters({
      selectedProducts: 'order/selection/data',
    }),
    hasSelectedProducts: function() {
      return this.selectedProducts;
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';
</style>
