<template>
  <div class="questions">
    <Clipboard v-if="attendee" :attendee="attendee"></Clipboard>
    <span v-if="sessionHasStarted || terminalInactive">
      <span v-if="sessionMinutesLeftInPercent !== null">
        <b-progress
          :max="100"
          variant="success"
          show-value
          style="height: 15px; margin-top: 2px; border-radius: 0"
        >
          <b-progress-bar
            :value="sessionMinutesLeftInPercent"
            :label-html="`${sessionMinutesLeft} minutes`"
            style="color: black;"
          ></b-progress-bar>
        </b-progress>
      </span>

      <span v-if="showAnswerLink" class="pull-right menuTop mt-2">
        <router-link :to="{ name: 'AttendeeSolution' }" target="_blank">
          Solutions
        </router-link>
        <span v-if="showScoreLink">
          <router-link :to="{ name: 'AttendeeScore' }" target="_blank">
            Score
          </router-link>
        </span>
        <br />
      </span>
      <div v-if="showAnswerLink" class="mt-5"></div>
      <CourseQuestions
        :courseUuid="attendee.course.uuid"
        :courseVersion="attendee.courseVersion"
        :showPreview="sessionMinutesLeft <= 0"
      ></CourseQuestions>
      <div style="margin-bottom: 100px"></div>
    </span>
    <span v-else>
      <h5 class="mt-3 mb-3">
        Please wait till your environment is ready, then start the countdown
      </h5>

      <p>
        The countdown is just an indication for yourself. When the countdown
        reaches 0 you can access the solutions and your score. You’ll still have
        access to the environment till expiration.
      </p>

      <span v-if="attendee.sessionRestarted === false">
        <p>
          You have access to your environment during the next following 36
          hours. The 36 hours are time based and not usage based. The 36 hours
          start once your environment is ready no matter if you start the
          countdown.
        </p>

        <p>
          Most questions are answered in our
          <router-link :to="{ name: 'Faq' }" target="_blank"> FAQ</router-link>.
        </p>
      </span>

      <p>Good luck!</p>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CourseQuestions from '@/components/course/CourseQuestions';
import Clipboard from '@/components/attendee/run/Clipboard';

export default {
  name: 'Questions',
  components: { CourseQuestions, Clipboard },
  data: function() {
    return {
      menuShow: false,
    };
  },
  props: {
    attendee: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      sessionStartIsLoading: 'attendee/sessionStart/isLoading',
      sessionStopIsLoading: 'attendee/sessionStop/isLoading',
      sessionRestartIsLoading: 'attendee/restart/isLoading',
    }),
    featureAttendeeRestartEnabled() {
      let func = this.$store.getters['feature/list/featureByName'];
      if (func('attendee_restart')) {
        return func('attendee_restart')['enabled'];
      }
      return false;
    },
    terminalActive: function() {
      return this.attendee.terminal.status === 'active';
    },
    terminalInactive: function() {
      return this.attendee.terminal.status === 'inactive';
    },
    sessionMinutesLeft: function() {
      return this.attendee.sessionMinutesLeft;
    },
    sessionMinutesLeftInPercent: function() {
      return this.attendee.sessionMinutesLeftInPercent;
    },
    sessionHasStarted: function() {
      return this.attendee.sessionStarted;
    },
    showAnswerLink: function() {
      return (
        ((this.sessionHasStarted && this.terminalActive) ||
          this.terminalInactive) &&
        this.sessionMinutesLeft <= 0
      );
    },
    showScoreLink: function() {
      return (
        this.sessionMinutesLeft <= 0 &&
        this.sessionHasStarted &&
        this.attendee &&
        this.attendee.scores &&
        this.attendee.scores.length &&
        this.attendee.terminal.status === 'active'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';

.questions {
  height: 100%;
  padding: 5px;
  overflow-x: hidden;
  overflow-y: auto;
}

.menuTop {
  a,
  a:link,
  a:visited,
  a:hover,
  a:active,
  button {
    color: #555;
    text-decoration: none;
  }
  a:hover,
  button:hover {
    color: gray;
  }
}
</style>
