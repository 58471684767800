<template>
  <div class="terminal-entry">
    <div class="vertical-center" style="padding: 0 10%">
      <div v-if="questionState === 'open'">
        <button class="btn btn-secondary" v-on:click="setQuestionStateActive()">
          Create Solution
        </button>
      </div>
      <div v-else-if="questionState === 'submitted'">
        <p>
          <strong>You already submitted a solution for this question</strong>
        </p>
        <p>
          You can create a new one by clicking on the button below. Your already
          submitted solution will only be updated if you click on the button
          below AND if you then click "Submit" again.
        </p>
        <br />
        <button
          class="btn btn-secondary"
          v-on:click="setQuestionStateActive()"
          style="width: 200px"
        >
          <b-img
            src="@/assets/icons/reset-update-icon.svg"
            style="max-width: 13px; margin-top: -2px"
          ></b-img>
          Reattempt Task
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TerminalEntry',
  components: {},
  props: {
    attendee: {
      required: true,
    },
    attendeeTerminalAddressPassed: {
      required: true,
    },
    questionIndex: {
      required: true,
    },
    questionState: {
      required: true,
    },
  },
  methods: {
    setQuestionStateActive: function() {
      this.$store.dispatch(
        'attendee/lfctOperator/setQuestionStateActive',
        this.questionIndex
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';

.terminal-entry {
  position: relative;
  height: 100%;
  text-align: center;
}

.vertical-center {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
